import { FC, useEffect, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { Iconly } from 'react-iconly';
import { Trans, useTranslation } from 'react-i18next';
import { humanFileSize } from '../../utils/math';
import { compressFiles } from '../../utils/images';
import { theme } from '../../theme';
import { Text } from '../Layout';

const DEFAULT_MAX_SIZE = 10 * 1000 * 1000; // 10 Mo
const DEFAULT_MAX_FILES = 20;
const COMPRESSION_OPTIONS = {
  quality: 0.7,
  maxWidth: 1600,
};

type FileItemProps = {
  file: File;
  errors?: Array<{ message: string; code: string }>;
  onRemove: (file: File) => void;
};

const FileItem: FC<FileItemProps> = ({ file, errors, onRemove }) => {
  const fileSize = humanFileSize(file.size);
  const hasErrors = errors && errors.length > 0;

  return (
    <div className="tw-mb-2">
      <div
        className={`tw-flex tw-items-center tw-p-2 tw-rounded-lg ${
          hasErrors ? 'tw-bg-red-500' : 'tw-bg-primary'
        }`}
      >
        <span className="tw-flex-1 tw-font-bold tw-text-sm tw-text-white">
          {file.name}
        </span>
        <span className="tw-text-sm tw-text-white tw-mr-2">{fileSize}</span>
        <button
          onClick={(e) => {
            e.stopPropagation();
            onRemove(file);
          }}
          className="tw-p-1 tw-text-white hover:tw-bg-black/20 tw-rounded"
        >
          <Iconly name="Delete" size="small" />
        </button>
      </div>
      {hasErrors && (
        <div className="tw-mt-1 tw-pl-2">
          {errors.map((error, index) => (
            <p key={index} className="tw-text-sm tw-text-red-500">
              {error.message}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

const GlobalError: FC<{ message: string }> = ({ message }) => (
  <div className="tw-p-3 tw-mb-4 tw-bg-red-500 tw-rounded-lg tw-text-white">
    {message}
  </div>
);

const Dropzone: FC<{ hide: boolean }> = ({ hide }) => {
  if (hide) return null;

  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-full">
      <Iconly name="Paper" size={58} />
      <Text
        style={{ margin: theme.spacing.space24 }}
        textAlign={'center'}
        fontStyle={'body2'}
      >
        <Trans i18nKey="files.uploader.hint" />
      </Text>
    </div>
  );
};

type FileDropProps = {
  multiple?: boolean;
  maxFiles?: number;
  maxSize?: number;
  disabled?: boolean;
  onDrop: (files: File[], rejections: FileRejection[]) => void;
  compressImages?: boolean;
  compressionOptions?: {
    quality?: number;
    maxWidth?: number;
    maxHeight?: number;
  };
};

const FileDrop: FC<FileDropProps> = ({
  multiple = false,
  maxFiles = DEFAULT_MAX_FILES,
  maxSize = DEFAULT_MAX_SIZE,
  onDrop,
  disabled = false,
  compressImages = true,
  compressionOptions = COMPRESSION_OPTIONS,
}) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState<
    Array<{ file: File; errors?: Array<{ message: string; code: string }> }>
  >([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [globalError, setGlobalError] = useState<string | null>(null);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: { 'image/png': [], 'image/jpeg': [], 'application/pdf': [] },
    multiple,
    disabled,
    onDrop: async (acceptedFiles, rejectedFiles) => {
      setIsProcessing(true);
      let processedFiles: File[] = acceptedFiles;

      if (compressImages && acceptedFiles.length > 0) {
        try {
          const compressed = await compressFiles(
            acceptedFiles,
            compressionOptions,
          );
          processedFiles = compressed as File[];
        } catch (error) {
          console.error('Error compressing files:', error);
        }
      }

      const existingFiles = files.map((f) => f.file);
      const allFiles = [...existingFiles, ...processedFiles];

      validateAndSetFiles(allFiles, rejectedFiles);
      setIsProcessing(false);
    },
  });

  const validateAndSetFiles = (
    newFiles: File[],
    rejectedFiles: FileRejection[],
  ) => {
    setGlobalError(null);

    if (newFiles.length > maxFiles) {
      setGlobalError(t('files.too-many'));
      setFiles(newFiles.map((file) => ({ file })));
      onDrop(
        [],
        newFiles.map((file) => ({
          file,
          errors: [{ message: t('files.too-many'), code: 'too-many' }],
        })),
      );
      return;
    }

    const totalSize = newFiles.reduce((sum, file) => sum + file.size, 0);
    if (totalSize > maxSize) {
      setGlobalError(t('files.total-size-too-large'));
      setFiles(newFiles.map((file) => ({ file })));
      onDrop(
        [],
        newFiles.map((file) => ({
          file,
          errors: [
            {
              message: t('files.total-size-too-large'),
              code: 'total-size-too-large',
            },
          ],
        })),
      );
      return;
    }

    const processedFiles = newFiles.map((file) => ({
      file,
      errors:
        file.size > maxSize
          ? [{ message: t('files.file-too-large'), code: 'file-too-large' }]
          : undefined,
    }));

    setFiles(processedFiles);

    const validFiles = processedFiles.filter((f) => !f.errors);
    const invalidFiles = [
      ...processedFiles.filter((f) => f.errors),
      ...rejectedFiles,
    ];
    onDrop(
      validFiles.map((f) => f.file),
      invalidFiles.map((f) => ({
        file: f.file,
        errors: f.errors || [],
      })),
    );
  };

  const removeFile = (fileToRemove: File) => {
    const remainingFiles = files
      .filter((f) => f.file !== fileToRemove)
      .map((f) => f.file);
    if (remainingFiles.length === 0) {
      setFiles([]);
      setGlobalError(null);
      onDrop([], []);
    } else {
      validateAndSetFiles(remainingFiles, []);
    }
  };

  useEffect(() => {
    setFiles([]);
    setGlobalError(null);
  }, []);

  return (
    <div
      {...getRootProps()}
      className={`tw-flex tw-flex-col tw-h-full tw-p-4 tw-border-2 tw-border-dashed tw-rounded 
        tw-bg-gray-50 tw-transition-colors tw-overflow-auto hover:tw-border-blue-500
        ${isDragActive ? 'tw-border-blue-500' : 'tw-border-gray-300'}
        ${isDragAccept ? 'tw-border-green-500' : ''}
        ${isDragReject ? 'tw-border-red-500' : ''}
      `}
    >
      <input {...getInputProps()} />
      <Dropzone hide={files.length > 0} />

      {isProcessing && (
        <p className="tw-text-center tw-text-blue-500">
          {t('files.processing')}
        </p>
      )}

      {files.length > 0 && (
        <div className="tw-mt-4">
          {globalError && <GlobalError message={globalError} />}

          <div className="tw-flex tw-items-center tw-gap-2 tw-font-semibold tw-mb-2">
            <span>{t('files.total_files', { number: files.length })}</span>
            <span className="tw-text-gray-500">
              (
              {humanFileSize(
                files.reduce((sum, { file }) => sum + file.size, 0),
              )}
              )
            </span>
          </div>

          {files.map(({ file, errors }, index) => (
            <FileItem
              key={index}
              file={file}
              errors={globalError ? undefined : errors}
              onRemove={removeFile}
            />
          ))}

          <Text
            style={{ margin: theme.spacing.space24 }}
            textAlign={'center'}
            fontStyle={'body2'}
          >
            <Trans i18nKey="files.uploader.hint" />
          </Text>
        </div>
      )}
    </div>
  );
};

export default FileDrop;
