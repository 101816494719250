import { ChangeEvent, FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { downloadFileNew, generateFileName } from 'utils/downloadFile';

import { DocumentCommentType, DocumentTemplateId } from 'types/resources';

import { theme } from 'theme';
import { Flex, Line, Text } from 'components/Layout';
import { Button, IconButton } from 'components/Buttons';
import { Modal, ModalTip } from 'components/Modal';
import { Preview } from 'components/Viewer';
import { CommentInput } from 'components/Forms';

import { DocumentFull, Procedure } from '../../types/resources';
import { useApi } from '../../hooks/useApi';
import FileDrop from '../FileDrop/FileDrop';
import { mergeFilesToPdf } from '../../utils/pdf';

import { formatDocumentTitle } from '../../utils/format';
import toast from 'react-hot-toast';

interface FileModalProps {
  opened: boolean;
  onClose: () => void;
  refetchDocuments: () => void;
  document: DocumentFull;
  editable?: boolean;
  changeable?: boolean; // can re-upload the document file
  hideTip?: boolean;
  procedure: Procedure;
}

const CustomModal = styled(Modal)`
  padding: 0;
`;

const Wrapper = styled.div<{ hideTip?: boolean }>`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

const PickerWrapper = styled.div<{ hideTip?: boolean }>`
  width: ${(props) => (props.hideTip ? '100%' : '780px')};
  height: calc(90vh - 200px);
  align-self: start;
`;

const Header = styled(Flex)``;

const CommentTypeButton = styled.button<{ selected?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  border-radius: 10px;
  padding: 2px 15px;
  background: ${(props) => (props.selected ? theme.colors.red3 : '')};

  &:hover {
    background: ${(props) => theme.colors.salmon3};
  }
`;

const RelativeWrapper = styled.div`
  position: relative;
`;

const FixedWrapper = styled.div`
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${theme.colors.white};
`;

const FileModal: FC<FileModalProps> = ({
  document,
  opened,
  onClose,
  refetchDocuments,
  editable,
  changeable,
  hideTip,
  procedure,
}) => {
  const { t } = useTranslation();
  const [commentType, setCommentType] = useState<DocumentCommentType>();
  const [comment, setComment] = useState<string>('');
  // Display re-upload document file
  const [changing, setChanging] = useState<boolean>(false);

  const onModalClose = () => {
    onClose();
    setCommentType(undefined);
    setComment('');
  };

  const { execute: validateDocument } = useApi(
    `/procedures/${document.procedure_id}/documents/${document.id}/validate`,
    {
      method: 'POST',
      onSuccess: () => {
        refetchDocuments();
        onModalClose();
      },
    },
  );

  const { execute: commentDocument } = useApi(
    `/procedures/${document.procedure_id}/documents/${document.id}/comment`,
    {
      method: 'POST',
      onSuccess: () => {
        refetchDocuments();
        onModalClose();
      },
    },
  );

  useEffect(() => {
    const firstComment = document.documents_comments[0];
    if (firstComment) {
      firstComment.type && setCommentType(firstComment.type);
      setComment(firstComment.comment.content);
    }
  }, [document]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setComment(e.target.value);
  };

  const onCommentTypeChange = (type: DocumentCommentType) => {
    if (commentType === type) {
      setCommentType(undefined);
    } else {
      setCommentType(type);
    }
  };

  const { execute: uploadDocument, state: uploadDocumentState } = useApi(
    `/procedures/${procedure.id}/documents/${document.id}/upload`,
    {
      method: 'POST',
      onSuccess: () => {
        toast.success('Le document a été modifié avec succès');
        onModalClose();
      },
    },
  );
  const uploadLoading = uploadDocumentState.loading;

  const onDropFiles = (files: File[]) => {
    if (files.length) {
      mergeFilesToPdf(files).then((value) => {
        const filename = generateFileName(
          formatDocumentTitle(document),
          procedure.reference.toString(),
        );
        const file = new File([value], filename, {
          type: 'application/pdf',
        });
        const body = new FormData();
        body.append('file', file, filename);

        uploadDocument({ body });
      });
    }
  };

  const { execute: downloadDocument } = useApi<{ url: string }>(
    `/procedures/${procedure.id}/documents/${document.id}/download`,
  );

  const handleDownloadClick = () => {
    if (document.file) {
      downloadDocument({
        endpoint: `/procedures/${procedure.id}/documents/${document.id}/download`,
        onSuccess: async (result) => {
          try {
            await downloadFileNew(
              result.value.url,
              formatDocumentTitle(document),
              procedure.reference.toString(),
            );
          } catch (e) {
            console.error(e);
          }
        },
      });
    }
  };

  const valid = !commentType && !comment;

  const onSubmit = async () => {
    if (valid) {
      validateDocument();
    } else {
      commentDocument({
        body: { type: commentType, content: comment },
      });
    }
  };

  const documentTemplateCommentTypeMapper: {
    [key in DocumentTemplateId]?: DocumentCommentType[];
  } & {
    OTHER: DocumentCommentType[];
  } = {
    [DocumentTemplateId.ID_SPOUSE]: [
      DocumentCommentType.CROPPED_EDGES,
      DocumentCommentType.WRONG_DOCUMENT_TYPE,
      DocumentCommentType.MISSING_BACKSIDE,
      DocumentCommentType.EXCESSIVE_BACKGROUND,
      DocumentCommentType.GRAYSCALE_NOT_ACCEPTED,
      DocumentCommentType.MISSING_SIGNATURE_PAGE,
    ],
    [DocumentTemplateId.FAMILY_RECORD_BOOK]: [
      DocumentCommentType.INCOMPLETE_BOOKLET,
    ],
    [DocumentTemplateId.BIRTH_CERTIFICATE_SPOUSE]: [
      DocumentCommentType.EXTRACT_NOT_FULL_COPY,
      DocumentCommentType.TRANSLATION_REQUIRED,
      DocumentCommentType.MISSING_OFFICIAL_STAMP,
      DocumentCommentType.EXPIRED_DOCUMENT,
      DocumentCommentType.MISSING_TRANSLATION,
      DocumentCommentType.WRONG_CERTIFICATE_TYPE,
      DocumentCommentType.DOCUMENT_EXPIRED,
      DocumentCommentType.COMEDEC_NOT_ACCEPTED,
    ],
    [DocumentTemplateId.BIRTH_CERTIFICATE_CHILD]: [
      DocumentCommentType.EXTRACT_NOT_FULL_COPY,
      DocumentCommentType.TRANSLATION_REQUIRED,
      DocumentCommentType.MISSING_OFFICIAL_STAMP,
      DocumentCommentType.EXPIRED_DOCUMENT,
      DocumentCommentType.MISSING_TRANSLATION,
      DocumentCommentType.WRONG_CERTIFICATE_TYPE,
      DocumentCommentType.DOCUMENT_EXPIRED,
      DocumentCommentType.COMEDEC_NOT_ACCEPTED,
    ],
    [DocumentTemplateId.WEDDING_CERTIFICATE]: [
      DocumentCommentType.EXTRACT_NOT_FULL_COPY,
      DocumentCommentType.TRANSLATION_REQUIRED,
      DocumentCommentType.MISSING_OFFICIAL_STAMP,
      DocumentCommentType.EXPIRED_DOCUMENT,
      DocumentCommentType.MISSING_TRANSLATION,
      DocumentCommentType.WRONG_CERTIFICATE_TYPE,
      DocumentCommentType.DOCUMENT_EXPIRED,
      DocumentCommentType.COMEDEC_NOT_ACCEPTED,
    ],

    [DocumentTemplateId.PROOF_OF_INCOME]: [
      DocumentCommentType.PAYSLIP_REQUIRED,
      DocumentCommentType.SWORN_STATEMENT_NEEDED,
      DocumentCommentType.PROVISIONAL_NOT_FINAL,
    ],
    [DocumentTemplateId.PROOF_OF_INCOME_OTHER]: [
      DocumentCommentType.PAYSLIP_REQUIRED,
      DocumentCommentType.SWORN_STATEMENT_NEEDED,
      DocumentCommentType.PROVISIONAL_NOT_FINAL,
    ],
    [DocumentTemplateId.WEALTH_DECLARATION]: [
      DocumentCommentType.FORM_INCORRECTLY_FILLED,
      DocumentCommentType.MISSING_CAPITAL_AMOUNT,
    ],
    [DocumentTemplateId.COMPENSATORY_ALLOWANCE]: [
      DocumentCommentType.FORM_INCORRECTLY_FILLED,
      DocumentCommentType.MISSING_CAPITAL_AMOUNT,
    ],
    [DocumentTemplateId.MATRIMONIAL_SETTLEMENT_FINAL_NOTARY]: [
      DocumentCommentType.SETTLEMENT_STATEMENT_REQUIRED,
    ],
    [DocumentTemplateId.JOINT_OWNERSHIP_AGREEMENT_FINAL_NOTARY]: [
      DocumentCommentType.SETTLEMENT_STATEMENT_REQUIRED,
    ],
    [DocumentTemplateId.DISCERNMENT_FORM]: [
      DocumentCommentType.HANDWRITTEN_SIGNATURE_NEEDED,
    ],
    [DocumentTemplateId.PROPERTY_TITLE]: [
      DocumentCommentType.FULL_TITLE_DEED_REQUIRED,
    ],
    [DocumentTemplateId.PROPERTY_TITLE_INDIVISIBLE]: [
      DocumentCommentType.FULL_TITLE_DEED_REQUIRED,
    ],
    OTHER: [
      DocumentCommentType.INVALID,
      DocumentCommentType.READABILITY,
      DocumentCommentType.UNCONFIRM_FORM,
      DocumentCommentType.WRONG,
      DocumentCommentType.DOCUMENT_UNOPENABLE,
      DocumentCommentType.POOR_FRAMING,
      DocumentCommentType.BACKGROUND_VISIBLE,
    ],
  };

  return (
    <CustomModal opened={opened} onClose={onModalClose}>
      <RelativeWrapper>
        <Header
          alignItems="center"
          justify="between"
          style={{ columnGap: theme.spacing.space24 }}
          paddingTop={{ xs: 'space24' }}
          paddingRight={{ xs: 'space16' }}
          paddingBottom={{ xs: 'space24' }}
          paddingLeft={{ xs: 'space16' }}
        >
          <Flex alignItems="center">
            <IconButton
              iconName="Document"
              color={theme.colors.salmon2}
              backgroundColor={theme.colors.salmon3}
              rounded
            />
            <Text
              fontStyle="heading5"
              content={formatDocumentTitle(document)}
              marginLeft={{ xs: 'space16' }}
            />
          </Flex>

          <Flex style={{ columnGap: theme.spacing.space16 }}>
            <Button
              content={t('upload.download')}
              iconLeft={{ name: 'Download' }}
              onClick={handleDownloadClick}
              primary
            />
            {changeable && changing && (
              <Button
                content={t('upload.view')}
                onClick={() => setChanging(false)}
              />
            )}
            {changeable && !changing && (
              <Button
                content={t('upload.modify')}
                onClick={() => setChanging(true)}
              />
            )}
          </Flex>
        </Header>
        <Line />
        <Wrapper hideTip={hideTip}>
          <Flex
            direction={{ xs: 'column' }}
            justify="between"
            alignItems="center"
            style={{ width: '100%' }}
            flex={1}
          >
            {document.file && (
              <PickerWrapper hideTip={hideTip}>
                {changing ? (
                  <FileDrop
                    onDrop={onDropFiles}
                    multiple={true}
                    maxFiles={5}
                    disabled={uploadLoading}
                    compressImages
                  />
                ) : (
                  <Preview procedure={procedure} document={document} />
                )}
              </PickerWrapper>
            )}
          </Flex>
          {!hideTip && (
            <ModalTip tipOnly>
              {editable && (
                <>
                  {documentTemplateCommentTypeMapper[
                    document.document_template_id
                  ] && (
                    <>
                      <div className="tw-flex tw-flex-col tw-gap-2">
                        <Text
                          fontStyle="heading4"
                          marginBottom={{ xs: 'space4' }}
                        >
                          Invalidités spécifiques
                        </Text>
                        {documentTemplateCommentTypeMapper[
                          document.document_template_id
                        ]?.map((value) => (
                          <CommentTypeButton
                            key={value}
                            onClick={() =>
                              onCommentTypeChange(value as DocumentCommentType)
                            }
                            selected={value === commentType}
                          >
                            <Text
                              fontStyle="body1"
                              weight="medium"
                              content={t(`enums:${value}`)}
                            />
                          </CommentTypeButton>
                        ))}
                      </div>
                      <hr className="mb-0 mt-4" />
                    </>
                  )}
                  <div className="tw-my-4 tw-flex tw-flex-col tw-gap-2">
                    <Text fontStyle="heading5" marginBottom={{ xs: 'space4' }}>
                      Invalidités générales
                    </Text>
                    {documentTemplateCommentTypeMapper.OTHER.map((value) => (
                      <CommentTypeButton
                        key={value}
                        onClick={() =>
                          onCommentTypeChange(value as DocumentCommentType)
                        }
                        selected={value === commentType}
                      >
                        <Text
                          fontStyle="body1"
                          weight="medium"
                          content={t(`enums:${value}`)}
                        />
                      </CommentTypeButton>
                    ))}
                  </div>
                  <Flex direction={{ xs: 'column' }}>
                    <Text
                      fontStyle="body1"
                      weight="bold"
                      content={t('documents.general_comment')}
                      marginBottom={{ xs: 'space16' }}
                    />
                    <CommentInput
                      onChange={onChange}
                      placeholder={t('documents.comment_example')}
                      value={comment}
                    />
                  </Flex>
                </>
              )}
            </ModalTip>
          )}
        </Wrapper>
        <FixedWrapper>
          <Line />
          <Flex
            direction={{ xs: 'column', md: 'row' }}
            justify="between"
            paddingRight={{ xs: 'space40' }}
            paddingTop={{ xs: 'space16' }}
            paddingLeft={{ xs: 'space40' }}
            paddingBottom={{ xs: 'space16' }}
          >
            <Button
              content={t('upload.back')}
              marginRight={{ xs: 'none', md: 'space16' }}
              marginBottom={{ xs: 'space16', md: 'none' }}
              onClick={onModalClose}
              iconLeft={{ name: 'ArrowLeftSquare' }}
            />
            {editable && (
              <Button
                content={
                  valid
                    ? t('documents.validate_document')
                    : t('documents.validate_comment')
                }
                primary
                disabled={!valid && (!commentType || !comment)}
                onClick={onSubmit}
              />
            )}
          </Flex>
        </FixedWrapper>
      </RelativeWrapper>
    </CustomModal>
  );
};

export default FileModal;
