import { IconButton } from 'components/Buttons';
import { Flex, Text } from 'components/Layout';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { theme } from 'theme';
import { useViewport } from '../../../hooks/useViewport';
import { UserGender } from 'types/resources';

const Wrapper = styled.div<{ active?: boolean; isMobile: boolean }>`
  background-color: ${({ active }) =>
    active ? theme.colors.salmonPastel : theme.colors.white};
  border: 1px solid;
  border-color: ${({ active }) =>
    active ? theme.colors.salmon1 : theme.colors.gray3};
  border-radius: 24px;
  padding: 20px;
  cursor: ${({ active }) => (active ? 'default' : 'pointer')};
  width: 100%;
`;

const AvatarWrapper = styled.div<{ shift?: boolean }>`
  margin-left: ${({ shift }) => (shift ? '-12px' : '0')};
`;

const StyledIconButton = styled(IconButton)<{ shift?: boolean }>`
  border: 1px solid;
  border-color: ${({ shift }) => (shift ? theme.colors.white : 'transparent')};
`;

interface ConversationListItemProps {
  recipients: {
    full_name?: string;
    first_name?: string;
    last_name?: string;
    gender?: UserGender;
  }[];
  lastMessage?: string;
  onClick: () => void;
  active?: boolean;
}

const ConversationListItem: FC<ConversationListItemProps> = ({
  recipients,
  lastMessage,
  onClick,
  active,
}) => {
  const { t } = useTranslation();
  const { isMobile } = useViewport();

  const recipientNamesText = recipients
    .map((recipient) =>
      !isMobile
        ? recipient.full_name ?? recipient.first_name
        : recipient.first_name,
    )
    .join(', ');

  return (
    <Wrapper active={active} onClick={onClick} isMobile={isMobile}>
      <Flex alignItems="center" direction={{ xs: 'column', md: 'row' }}>
        <Flex>
          {recipients.map((_, index) => (
            <AvatarWrapper
              key={`conversation-list-recipient-avatar-${index}`}
              shift={index > 0}
            >
              <StyledIconButton
                iconName="User"
                color={theme.colors.green1}
                backgroundColor={theme.colors.green2}
                stroke="regular"
                size="small"
                shift={index > 0}
                rounded
              />
            </AvatarWrapper>
          ))}
        </Flex>

        <Text
          content={recipientNamesText}
          fontStyle="body2"
          marginLeft={{ xs: 'space8' }}
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
        />
      </Flex>
      {!isMobile && (
        <Text
          content={lastMessage || t('opportunity.conversation.no_message')}
          fontStyle="body2"
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          }}
          marginTop={{ xs: 'space12' }}
        />
      )}
    </Wrapper>
  );
};

export default ConversationListItem;
