import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex } from 'components/Layout';
import { List, ListItem } from 'components/List';
import { IconlyIcon } from 'types/react-iconly';
import { formatDate } from 'utils/formatDate';
import { DeleteModal } from 'components/Modal';
import { downloadFileNew } from 'utils/downloadFile';
import { theme } from 'theme';
import {
  DocumentFull,
  DocumentTemplateId,
  Procedure,
} from '../../../types/resources';
import { useApi } from '../../../hooks/useApi';
import { formatDocumentTitle } from '../../../utils/format';
import UpdateTitleDocument from '../../../container/admin/documents/UpdateTitleDocument';
import toast from 'react-hot-toast';
import { Printer } from 'lucide-react';

interface AdministrativeDocumentListProps {
  title: string;
  count: string;
  data: DocumentFull[];
  procedure: Procedure;
  refetch: () => void;
}

const AdministrativeDocumentList: FC<AdministrativeDocumentListProps> = ({
  title,
  count,
  data,
  procedure,
  refetch,
}) => {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [documentToDeleteId, setDocumentToDeleteId] = useState<string>('');
  const [modalUpdateTitle, toogleModalUpdateTitle] = useState<{
    id: string;
    name: string;
  } | null>(null);
  const onModalClose = () => {
    toogleModalUpdateTitle(null);
  };

  const { execute: deleteDocument, state: deleteDocumentState } = useApi(
    `/procedures/${procedure.id}/documents`,
    {
      method: 'DELETE',
    },
  );

  const handleOpenModal = (id: string) => {
    setDocumentToDeleteId(id);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setDocumentToDeleteId('');
    setIsModalOpen(false);
  };

  const deleteDocumentCb = useCallback(
    (callback: () => void) => {
      if (documentToDeleteId) {
        deleteDocument({
          endpoint: `/procedures/${procedure.id}/documents/${documentToDeleteId}`,
          onSuccess: () => {
            toast.success(t('administrative_documents.modal.success'));
            refetch();
            callback();
          },
        });
      }
    },
    [documentToDeleteId],
  );

  const { execute: downloadDocument } = useApi<{ url: string }>(``);

  const onDownloadDocument = (document: DocumentFull) => {
    if (document.file_id) {
      downloadDocument({
        endpoint: `/procedures/${procedure.id}/documents/${document.id}/download`,
        onSuccess: async (result) => {
          try {
            await downloadFileNew(
              result.value.url,
              formatDocumentTitle(document),
              procedure.reference.toString(),
            );
          } catch (e) {
            console.error(e);
          }
        },
      });
    }
  };

  const onPrintDocument = (document: DocumentFull) => {
    if (document.file_id) {
      downloadDocument({
        endpoint: `/procedures/${procedure.id}/documents/${document.id}/download`,
        onSuccess: async (result) => {
          try {
            const pdfUrl = result.value.url;

            const printWindow = window.open(pdfUrl, '_blank');

            if (printWindow) {
              printWindow.focus();
            } else {
              toast.error(t('administrative_documents.print.popup_blocked'));
            }
          } catch (e) {
            console.error(e);
            toast.error(t('administrative_documents.print.error'));
          }
        },
      });
    }
  };

  return (
    <>
      <List title={title} count={count}>
        {data.map((document) => {
          const fileButtons = [
            ...([
              DocumentTemplateId.SPOUSE_SENDING_LETTER,
              DocumentTemplateId.TRANSCRIPTION_REQUEST,
            ].includes(document.document_template_id)
              ? [
                  {
                    content: t('administrative_documents.buttons.print'),
                    primary: false,
                    onClick: () => onPrintDocument(document),
                    iconLeft: <Printer size={20} />,
                  },
                ]
              : []),
            {
              content: t('administrative_documents.buttons.download'),
              primary: false,
              onClick: () => onDownloadDocument(document),
              iconLeft: {
                name: 'Download' as keyof typeof IconlyIcon,
              },
            },
            {
              content: t('administrative_documents.buttons.delete'),
              primary: false,
              onClick: () => handleOpenModal(document.id),
              iconLeft: {
                name: 'Delete' as keyof typeof IconlyIcon,
              },
            },
          ];
          const dates = document.updated_at
            ? [formatDate(new Date(document.updated_at))]
            : [];
          const primary =
            document.document_template_id &&
            [
              DocumentTemplateId.NOTARY_LETTER,
              DocumentTemplateId.MARITAL_STATUS,
              DocumentTemplateId.AGREEMENT_SIGNED,
            ].includes(document.document_template_id);

          return (
            <Flex
              key={document.id}
              width="100%"
              marginBottom={{ xs: 'space16' }}
            >
              <ListItem
                title={formatDocumentTitle(document)}
                iconName="Paper"
                iconColor={primary ? theme.colors.salmon1 : undefined}
                buttons={fileButtons}
                dates={dates}
                editTitle={() =>
                  toogleModalUpdateTitle({
                    id: document.id,
                    name: document.title,
                  })
                }
              />
            </Flex>
          );
        })}
      </List>

      <DeleteModal
        onClose={handleCloseModal}
        onSubmit={deleteDocumentCb}
        opened={isModalOpen}
        title={t('administrative_documents.modal.title')}
        content={t('administrative_documents.modal.content')}
        loading={deleteDocumentState.loading}
      />

      {!!modalUpdateTitle && (
        <UpdateTitleDocument
          opened={!!modalUpdateTitle}
          refetchDocuments={refetch}
          onClose={onModalClose}
          documentId={modalUpdateTitle?.id}
          documentName={modalUpdateTitle?.name}
          opportunityId={procedure.id}
        />
      )}
    </>
  );
};

export default AdministrativeDocumentList;
