import React, { FC, ReactElement } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import styled from 'styled-components';

// Components
import { AdminMenu } from 'container/admin';
import {
  AdminHome,
  CreateManualOpportunity,
  Customers,
  CustomersAdd,
  CustomersDetails,
  Document,
  Estimates,
  EstimatesAdd,
  Form,
  Notifications,
  Opportunities,
  Opportunity,
  OpportunityMessaging,
  Payments,
  PaymentsAdd,
  PaymentsDetails,
  Products,
  ProductsAdd,
  ProductsUpdate,
  Prospects,
  ProspectsAdd,
  UserAdd,
  Users,
  UserUpdate,
} from 'pages/admin';
import Router from 'types/router.types';

// Schema
import { Account, Form as ClientForm, Page404 } from 'pages/common';
import { Container } from 'components/Layout';
import { theme } from 'theme';
import { md } from 'theme/styles/mediaQueries';
import { QuoteResumePage } from 'pages/public';
import ProspectsDetails from './pages/admin/ProspectsDetails';
import { EstimatesDetails } from './pages/admin';
import { PublicAppRoutes } from './PublicApp';
import { ClientAppRoutes } from './ClientApp';
import SentryWrapper from './config/sentry-route';
import MessageTemplatesList from './container/admin/lists/MessageTemplatesList';
import MessageTemplateCreate from './container/admin/MessageTemplate/MessageTemplateCreate';
import MessageTemplateEdit from './container/admin/MessageTemplate/MessageTemplateEdit';
import PartnersListPage from './container/admin/lists/PartnersList';
import PartnerCreate from './container/admin/Partner/PartnerCreate';
import PartnerEdit from './container/admin/Partner/PartnerEdit';
import PageEdit from './container/admin/Page/PageEdit';
import PartnerPreview from './container/admin/Partner/PartnerPreview';
import ManagerDashboard from 'container/admin/dahsboard/ManagerDashboard';

const Wrapper = styled.div<{ hideMenu?: boolean }>`
  display: grid;
  gap: 0;

  ${({ hideMenu }) =>
    !hideMenu &&
    md(`
    grid-template-columns: 176px ${theme.spacing.space40} 1fr ${theme.spacing.space56};
  `)}
`;

const CustomContainer = styled(Container)`
  padding-top: 100px;
  padding-bottom: ${theme.spacing.space40};
  ${md(`
    padding-top: ${theme.spacing.space56};
    padding-bottom: ${theme.spacing.space40};
  `)}
`;

export enum AdminAppRoutes {
  HOME = '/',
  USERS = '/utilisateurs',
  CUSTOMERS = '/clients',
  CUSTOMERS_DETAILS = '/clients/:id',
  CUSTOMERS_ADD = '/clients/ajout',
  PROSPECTS = '/prospects',
  PROSPECTS_DETAILS = '/prospects/:id',
  PROSPECTS_ADD = '/prospects/ajout',
  ESTIMATE = '/devis',
  ESTIMATES_ADD = '/devis/ajout',
  ESTIMATES_DETAILS = '/devis/:id',
  NOTIFICATIONS = '/notifications',
  PRODUCT = '/produits',
  PRODUCTS_ADD = '/produits/ajout',
  PRODUCTS_DETAILS = '/produits/:id',
  PAYMENTS = '/paiements',
  PAYMENTS_ADD = '/paiements/ajout',
  PAYMENTS_DETAILS = '/paiements/:id',
  USER_UPDATE_ID = '/utilisateurs/edition/:id',
  USER_ADD = '/utilisateurs/ajout',
  CASES = '/dossiers',
  ACCOUNT = '/mon-compte',
  CASES_DETAILS = '/dossiers/:id',
  OPPORTUNITY_MESSAGING = '/dossiers/:id/messagerie',
  FORM = '/admin/formulaire/:opportunityId/:formId',
  DOCUMENT = '/document/:opportunityId/:documentId',
  CLIENT_FORM = '/modification-formulaire/:id/:opportunityId',
  CREATE_MANUAL_OPPORTUNITY = '/affaires/creation-dossier/:dealId',
  QUOTE_RESUME = '/affaires/recapitulatif/:id',
  SIGN_IN = '/connexion',
  MESSAGE_TEMPLATES = '/modeles',
  MESSAGE_TEMPLATES_CREATE = '/modeles/ajout',
  MESSAGE_TEMPLATES_EDIT = '/modeles/edition/:templateId',
  PARTNERS = '/partenaires',
  PARTNER_CREATE = '/partenaires/ajout',
  PARTNER_EDIT = '/partenaires/edition/:partnerId',
  PARTNER_PREVIEW = '/partenaires/preview',
  PAGE_EDIT = '/pages/edition/:pageName',
  ACTIVITY = '/suivi',
}

const routes: Record<AdminAppRoutes, Router> = {
  [AdminAppRoutes.HOME]: {
    routeProps: {
      path: AdminAppRoutes.HOME,
      element: <AdminHome />,
    },
  },
  [AdminAppRoutes.CLIENT_FORM]: {
    routeProps: {
      path: AdminAppRoutes.CLIENT_FORM,
      element: <ClientForm />,
    },
  },
  [AdminAppRoutes.ACCOUNT]: {
    routeProps: {
      path: AdminAppRoutes.ACCOUNT,
      element: <Account />,
    },
  },
  [AdminAppRoutes.USERS]: {
    routeProps: {
      path: AdminAppRoutes.USERS,
      element: <Users />,
    },
  },
  [AdminAppRoutes.ESTIMATE]: {
    routeProps: {
      path: AdminAppRoutes.ESTIMATE,
      element: <Estimates />,
    },
  },
  [AdminAppRoutes.ESTIMATES_ADD]: {
    routeProps: {
      path: AdminAppRoutes.ESTIMATES_ADD,
      element: <EstimatesAdd />,
    },
  },
  [AdminAppRoutes.ESTIMATES_DETAILS]: {
    routeProps: {
      path: AdminAppRoutes.ESTIMATES_DETAILS,
      element: <EstimatesDetails />,
    },
  },
  [AdminAppRoutes.NOTIFICATIONS]: {
    routeProps: {
      path: AdminAppRoutes.NOTIFICATIONS,
      element: <Notifications />,
    },
  },
  [AdminAppRoutes.PROSPECTS]: {
    routeProps: {
      path: AdminAppRoutes.PROSPECTS,
      element: <Prospects />,
    },
  },
  [AdminAppRoutes.PROSPECTS_ADD]: {
    routeProps: {
      path: AdminAppRoutes.PROSPECTS_ADD,
      element: <ProspectsAdd />,
    },
  },
  [AdminAppRoutes.PROSPECTS_DETAILS]: {
    routeProps: {
      path: AdminAppRoutes.PROSPECTS_DETAILS,
      element: <ProspectsDetails />,
    },
  },
  [AdminAppRoutes.CUSTOMERS]: {
    routeProps: {
      path: AdminAppRoutes.CUSTOMERS,
      element: <Customers />,
    },
  },
  [AdminAppRoutes.CUSTOMERS_ADD]: {
    routeProps: {
      path: AdminAppRoutes.CUSTOMERS_ADD,
      element: <CustomersAdd />,
    },
  },
  [AdminAppRoutes.CUSTOMERS_DETAILS]: {
    routeProps: {
      path: AdminAppRoutes.CUSTOMERS_DETAILS,
      element: <CustomersDetails />,
    },
  },
  [AdminAppRoutes.PRODUCT]: {
    routeProps: {
      path: AdminAppRoutes.PRODUCT,
      element: <Products />,
    },
  },
  [AdminAppRoutes.PRODUCTS_ADD]: {
    routeProps: {
      path: AdminAppRoutes.PRODUCTS_ADD,
      element: <ProductsAdd />,
    },
  },
  [AdminAppRoutes.PRODUCTS_DETAILS]: {
    routeProps: {
      path: AdminAppRoutes.PRODUCTS_DETAILS,
      element: <ProductsUpdate />,
    },
  },
  [AdminAppRoutes.PAYMENTS]: {
    routeProps: {
      path: AdminAppRoutes.PAYMENTS,
      element: <Payments />,
    },
  },
  [AdminAppRoutes.PAYMENTS_ADD]: {
    routeProps: {
      path: AdminAppRoutes.PAYMENTS_ADD,
      element: <PaymentsAdd />,
    },
  },
  [AdminAppRoutes.PAYMENTS_DETAILS]: {
    routeProps: {
      path: AdminAppRoutes.PAYMENTS_DETAILS,
      element: <PaymentsDetails />,
    },
  },
  [AdminAppRoutes.USER_ADD]: {
    routeProps: {
      path: AdminAppRoutes.USER_ADD,
      element: <UserAdd />,
    },
  },
  [AdminAppRoutes.USER_UPDATE_ID]: {
    routeProps: {
      path: AdminAppRoutes.USER_UPDATE_ID,
      element: <UserUpdate />,
    },
  },
  [AdminAppRoutes.CASES]: {
    routeProps: {
      path: AdminAppRoutes.CASES,
      element: <Opportunities />,
    },
  },
  [AdminAppRoutes.CASES_DETAILS]: {
    routeProps: {
      path: AdminAppRoutes.CASES_DETAILS,
      element: <Opportunity />,
    },
  },
  [AdminAppRoutes.OPPORTUNITY_MESSAGING]: {
    routeProps: {
      path: AdminAppRoutes.OPPORTUNITY_MESSAGING,
      element: <OpportunityMessaging />,
    },
  },
  [AdminAppRoutes.FORM]: {
    routeProps: {
      path: AdminAppRoutes.FORM,
      element: <Form />,
    },
  },
  [AdminAppRoutes.DOCUMENT]: {
    routeProps: {
      path: AdminAppRoutes.DOCUMENT,
      element: <Document />,
    },
  },
  [AdminAppRoutes.CREATE_MANUAL_OPPORTUNITY]: {
    routeProps: {
      path: AdminAppRoutes.CREATE_MANUAL_OPPORTUNITY,
      element: <CreateManualOpportunity />,
    },
  },
  [AdminAppRoutes.QUOTE_RESUME]: {
    routeProps: {
      path: AdminAppRoutes.QUOTE_RESUME,
      element: <QuoteResumePage />,
    },
    isMobile: true,
  },
  [AdminAppRoutes.MESSAGE_TEMPLATES]: {
    routeProps: {
      path: AdminAppRoutes.MESSAGE_TEMPLATES,
      element: <MessageTemplatesList />,
    },
  },
  [AdminAppRoutes.MESSAGE_TEMPLATES_CREATE]: {
    routeProps: {
      path: AdminAppRoutes.MESSAGE_TEMPLATES_CREATE,
      element: <MessageTemplateCreate />,
    },
  },
  [AdminAppRoutes.MESSAGE_TEMPLATES_EDIT]: {
    routeProps: {
      path: AdminAppRoutes.MESSAGE_TEMPLATES_EDIT,
      element: <MessageTemplateEdit />,
    },
  },
  [AdminAppRoutes.PARTNERS]: {
    routeProps: {
      path: AdminAppRoutes.PARTNERS,
      element: <PartnersListPage />,
    },
  },
  [AdminAppRoutes.PARTNER_CREATE]: {
    routeProps: {
      path: AdminAppRoutes.PARTNER_CREATE,
      element: <PartnerCreate />,
    },
  },
  [AdminAppRoutes.PARTNER_EDIT]: {
    routeProps: {
      path: AdminAppRoutes.PARTNER_EDIT,
      element: <PartnerEdit />,
    },
  },
  [AdminAppRoutes.PARTNER_PREVIEW]: {
    routeProps: {
      path: AdminAppRoutes.PARTNER_PREVIEW,
      element: <PartnerPreview />,
    },
  },
  [AdminAppRoutes.PAGE_EDIT]: {
    routeProps: {
      path: AdminAppRoutes.PAGE_EDIT,
      element: <PageEdit />,
    },
  },

  [AdminAppRoutes.ACTIVITY]: {
    routeProps: {
      path: AdminAppRoutes.ACTIVITY,
      element: <ManagerDashboard />,
    },
  },
  [PublicAppRoutes.SIGN_IN]: {
    routeProps: {
      path: PublicAppRoutes.SIGN_IN,
      element: <Navigate to={ClientAppRoutes.HOME} />,
    },
  },
};

const AdminApp: FC = () => {
  const location = useLocation();

  return (
    <Wrapper hideMenu={location.pathname === AdminAppRoutes.PARTNER_PREVIEW}>
      <AdminMenu />
      <div></div>
      <CustomContainer fluid="xl">
        <Routes>
          {Object.values(routes).map((route) => (
            <Route
              key={`adminRoute-${route.routeProps.path}`}
              path={route.routeProps.path}
              element={
                <SentryWrapper
                  element={route.routeProps.element as ReactElement}
                />
              }
            />
          ))}
          <Route
            path="*"
            element={<SentryWrapper element={<Page404 isAdmin={true} />} />}
          />{' '}
        </Routes>
      </CustomContainer>
    </Wrapper>
  );
};

export default AdminApp;
