import { Button, IconButton } from 'components/Buttons';
import { DesktopOnly, Flex, MobileOnly, Requires } from 'components/Layout';
import { FC, useEffect, useMemo } from 'react';
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { Alignments } from 'theme/styles/flex';
// Routes
import { ClientAppRoutes } from 'ClientApp';
import { FormTitleHeader } from './FormTitleHeader';
import { PublicAppRoutes } from 'PublicApp';
import { Text } from 'components/Layout/Text';
import logoBlack from 'assets/logo/logo-black.svg';
// Assets
import logoWhite from 'assets/logo/logo-white.svg';
import { lg, md } from 'theme/styles/mediaQueries';
import styled from 'styled-components';
// Components
import { theme } from 'theme';
import { useTranslation } from 'react-i18next';
import { useViewport } from 'hooks/useViewport';
import { AdminAppRoutes } from 'AdminApp';
import { useCustomer } from '../../../hooks/useCustomer';
import { useAuth } from '../../../hooks/useAuth';
import { useApi } from '../../../hooks/useApi';
import { Role } from '../../../types/resources';

export const HEADER_HEIGHT = 70;

const HeaderContainer = styled.header<HeaderContainerProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) =>
    props.isWhite ? theme.colors.white : theme.colors.green1};
  border-bottom: ${(props) =>
    props.isWhite && `1px solid ${theme.colors.gray3}`};
  padding: 0 ${theme.spacing.space16};
  position: fixed;
  top: 0;
  width: 100vw;
  height: ${HEADER_HEIGHT}px;
  z-index: 6;

  ${md(`
    padding: 0 ${theme.spacing.space16};
    z-index: 5;
  `)}

  ${lg(`
    padding: 0 ${theme.spacing.space72};
    z-index: 5;
  `)}
`;

const Image = styled.img`
  max-width: 120px;
`;

interface HeaderContainerProps {
  isWhite?: boolean;
}

interface HeaderProps {
  admin?: boolean;
}

export const Header: FC<HeaderProps> = ({ admin }) => {
  const { t } = useTranslation();
  const { isMobile } = useViewport();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = useLocation();
  const greenList: string[] = [
    ClientAppRoutes.HOME,
    ClientAppRoutes.ACCOUNT,
    ClientAppRoutes.MESSAGING,
    ClientAppRoutes.PAYMENT_RESUME.split('/:')[0],
    ClientAppRoutes.SUCCESSFUL_PAYMENT,
    ClientAppRoutes.ERROR_PAYMENT,
    ClientAppRoutes.PARTNERS,
    ...Object.values(PublicAppRoutes).map((url) => url.split('/:')[0]),
  ];
  const isWhite = !greenList.some((url) => {
    if (location.pathname === '/') return true;
    return url !== '/' && location.pathname.startsWith(url);
  });
  const quitList: string[] = [ClientAppRoutes.FORM, ClientAppRoutes.MESSAGING];

  const hasQuitButton = quitList.some((route) => route === location.pathname);

  const { user } = useAuth();
  const { customer } = useCustomer();

  const { id, opportunityId } = useParams<{
    id: string;
    opportunityId: string;
  }>();

  const {
    execute: getUnreadConversations,
    state: getUnreadConversationsState,
  } = useApi<{ count: number }>(`/conversations/unread`);
  const unread = getUnreadConversationsState.data?.value.count ?? 0;
  const showNotification =
    unread > 0 && location.pathname !== ClientAppRoutes.MESSAGING;

  useEffect(() => {
    if (
      user?.role &&
      [Role.ADMIN, Role.MANAGER, Role.CUSTOMER].includes(user.role)
    ) {
      getUnreadConversations();
    }
  }, [location]);

  const onBackToHome = () => {
    const match = matchPath(ClientAppRoutes.FORM, pathname);

    if (match !== null) {
      navigate(ClientAppRoutes.FORMS);
      return;
    }

    navigate(ClientAppRoutes.HOME);
  };

  const flexProps = !isMobile
    ? {
        style: { gap: theme.spacing.space32 },
      }
    : {
        justify: 'between' as Alignments,
        expand: true,
      };

  const disableBackButton = useMemo(() => {
    const routes = [
      ClientAppRoutes.PAYMENT_RESUME,
      ClientAppRoutes.SUCCESSFUL_PAYMENT,
      ClientAppRoutes.ERROR_PAYMENT,
      ...Object.values(PublicAppRoutes),
    ];
    return routes.includes(pathname as ClientAppRoutes | PublicAppRoutes);
  }, [pathname]);

  return (
    <HeaderContainer isWhite={isWhite}>
      <Flex {...flexProps}>
        <Flex
          onClick={onBackToHome}
          style={{ cursor: 'pointer' }}
          marginLeft={{ xs: 0, md: 'space16', lg: 'space32' }}
        >
          <Image src={isWhite ? logoBlack : logoWhite} alt="WeDivorce" />
        </Flex>
        {pathname !== '/' && !disableBackButton && (
          <>
            <DesktopOnly>
              <Button
                content={t('back')}
                iconLeft={{ name: 'ArrowLeft' }}
                backgroundColor={
                  isWhite ? theme.colors.white : theme.colors.green1
                }
                textColor={isWhite ? theme.colors.black : theme.colors.white}
                borderColor={isWhite ? theme.colors.gray3 : theme.colors.white}
                size="small"
                onClick={onBackToHome}
              />
            </DesktopOnly>
            <Requires value={hasQuitButton}>
              <MobileOnly>
                <Button
                  content={t('back')}
                  backgroundColor={
                    isWhite ? theme.colors.white : theme.colors.green1
                  }
                  textColor={isWhite ? theme.colors.black : theme.colors.white}
                  borderColor={
                    isWhite ? theme.colors.gray3 : theme.colors.white
                  }
                  size="small"
                  onClick={() => navigate(-1)}
                />
              </MobileOnly>
            </Requires>
          </>
        )}
      </Flex>
      {location.pathname !== ClientAppRoutes.MESSAGING && (
        <>
          {location.pathname === ClientAppRoutes.FORM ? (
            <FormTitleHeader id={id!} procedureId={opportunityId!} />
          ) : (
            <>
              <Flex alignItems="center" gap={{ xs: 'space16', md: 'space24' }}>
                {customer?.procedure_id && (
                  <Flex
                    alignItems="center"
                    onClick={() => {
                      navigate(ClientAppRoutes.SUPPORT_CALL);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <IconButton
                      iconName="headphones"
                      color={
                        isWhite ? theme.colors.salmon1 : theme.colors.green1
                      }
                      backgroundColor={
                        isWhite ? theme.colors.beige : theme.colors.beige
                      }
                      rounded
                    />
                    <DesktopOnly>
                      <Text
                        content={t('support.navbar')}
                        fontStyle="body1"
                        color={
                          isWhite ? theme.colors.green1 : theme.colors.white
                        }
                        weight="medium"
                        marginLeft={{ xs: 'space8' }}
                      />
                    </DesktopOnly>
                  </Flex>
                )}
                <Flex
                  alignItems="center"
                  onClick={() => {
                    navigate(ClientAppRoutes.PARTNERS);
                  }}
                  style={{ cursor: 'pointer', position: 'relative' }}
                >
                  <span className="tw-absolute -tw-bottom-3.5 tw-left-0 tw-right-0 tw-w-fit mx-auto md:tw-left-[unset] md:tw-bottom-[unset] md:mx-[unset] md:tw-right-0 md:-tw-top-2 tw-text-[10px] tw-bg-primary tw-text-white tw-rounded-md tw-py-0.5 tw-px-1">
                    NEW
                  </span>
                  <IconButton
                    filled
                    iconName="Star"
                    color={isWhite ? theme.colors.salmon1 : theme.colors.white}
                    backgroundColor={
                      isWhite ? theme.colors.beige : theme.colors.salmon1
                    }
                    rounded
                  />
                  <DesktopOnly>
                    <Text
                      content={t('partners.title')}
                      fontStyle="body1"
                      color={isWhite ? theme.colors.black : theme.colors.white}
                      weight="medium"
                      marginLeft={{ xs: 'space8' }}
                    />
                  </DesktopOnly>
                </Flex>
                {customer?.procedure_id && (
                  <Flex
                    alignItems="center"
                    onClick={() => {
                      navigate(ClientAppRoutes.MESSAGING);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <IconButton
                      iconName="Chat"
                      color={
                        showNotification
                          ? theme.colors.white
                          : isWhite
                          ? theme.colors.salmon1
                          : theme.colors.green1
                      }
                      backgroundColor={
                        showNotification
                          ? theme.colors.salmon1
                          : isWhite
                          ? theme.colors.beige
                          : theme.colors.beige
                      }
                      notification={showNotification ? 'md' : undefined}
                      rounded
                    />
                    <DesktopOnly>
                      <Text
                        content={t('opportunity.conversation.title')}
                        fontStyle="body1"
                        color={
                          isWhite ? theme.colors.black : theme.colors.white
                        }
                        weight="medium"
                        marginLeft={{ xs: 'space8' }}
                      />
                    </DesktopOnly>
                  </Flex>
                )}
                <Flex
                  alignItems="center"
                  onClick={() =>
                    navigate(
                      admin ? AdminAppRoutes.ACCOUNT : ClientAppRoutes.ACCOUNT,
                    )
                  }
                  style={{ cursor: 'pointer' }}
                >
                  <IconButton
                    iconName="User"
                    color={isWhite ? theme.colors.salmon1 : theme.colors.green1}
                    backgroundColor={
                      isWhite ? theme.colors.beige : theme.colors.beige
                    }
                    rounded
                  />
                  <DesktopOnly>
                    <Text
                      content={user?.full_name}
                      fontStyle="body1"
                      color={isWhite ? theme.colors.black : theme.colors.white}
                      weight="medium"
                      marginLeft={{ xs: 'space8' }}
                    />
                  </DesktopOnly>
                </Flex>
              </Flex>
            </>
          )}
        </>
      )}
    </HeaderContainer>
  );
};
