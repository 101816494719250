import { Account, Form, Page404 } from 'pages/common';
// Pages
import {
  ClientAdministrativeDocuments,
  ClientAppointment,
  ClientConvention,
  ClientConversationPage,
  ClientDocuments,
  ClientFinal,
  ClientFormalityCivilStatus,
  ClientFormalityFinalSending,
  ClientFormalityNotary,
  ClientForms,
  ClientHome,
  ClientLawyerFees,
  ClientRegisteredLetters,
} from 'pages/client';
import { Navigate, Route, Routes } from 'react-router-dom';

import React, { FC, ReactElement } from 'react';
import { MoustacheAIProvider } from 'container/client';
import { Header } from 'container/common/Header/Header';
import Router from 'types/router.types';
import {
  ErrorPayment,
  PaymentResume,
  Quote,
  QuoteResumePage,
  SuccessfulPayment,
} from 'pages/public';
import styled from 'styled-components';
import { useViewport } from 'hooks/useViewport';
import { CustomerProvider } from './context';
import { PublicAppRoutes } from './PublicApp';
import SentryWrapper from './config/sentry-route';
import ClientPartners from './pages/client/ClientPartners';
import ClientSupportCall from './pages/client/ClientSupportCall';

// Component

const Container = styled.div`
  padding-top: 70px;
`;

export enum ClientAppRoutes {
  HOME = '/',
  FORMS = '/formulaires',
  FORM = '/formulaire/:id/:opportunityId',
  ACCOUNT = '/mon-compte',
  LAWYER_FEES = '/convention-honoraires',
  DOCUMENTS = '/documents',
  QUOTE = '/devis',
  QUOTE_RESUME = '/recapitulatif-devis/:id',
  PAYMENT_RESUME = '/recapitulatif-paiement/:id',
  ERROR_PAYMENT = '/attente-paiement',
  ADMINISTRATIVE_DOCUMENTS = '/administrative-documents',
  CONVENTION = '/convention',
  REGISTERED_LETTERS = '/lettres-recommandees',
  APPOINTMENT = '/rendez-vous',
  FORMALITY_NOTARY = '/formalites/notaire',
  FORMALITY_MARITAL_STATUS = '/formalites/etat-civil',
  FORMALITY_FINAL_SENDING = '/formalites/envoi-final',
  FINAL = '/final',
  SUCCESSFUL_PAYMENT = '/confirmation-paiement',
  MESSAGING = '/messagerie',
  SIGN_IN = '/connexion',
  PARTNERS = '/partenaires',
  SUPPORT_CALL = '/rendez-vous-support',
}

const clientRoutes: Record<ClientAppRoutes, Router> = {
  [ClientAppRoutes.SUCCESSFUL_PAYMENT]: {
    routeProps: {
      path: ClientAppRoutes.SUCCESSFUL_PAYMENT,
      element: <SuccessfulPayment />,
    },
    isMobile: true,
  },
  [ClientAppRoutes.ERROR_PAYMENT]: {
    routeProps: {
      path: ClientAppRoutes.ERROR_PAYMENT,
      element: <ErrorPayment />,
    },
    isMobile: true,
  },
  [ClientAppRoutes.HOME]: {
    routeProps: {
      path: ClientAppRoutes.HOME,
      element: <ClientHome />,
    },
    isMobile: true,
  },
  [ClientAppRoutes.QUOTE]: {
    routeProps: {
      path: ClientAppRoutes.QUOTE,
      element: <Quote />,
    },
    isMobile: true,
  },
  [ClientAppRoutes.QUOTE_RESUME]: {
    routeProps: {
      path: ClientAppRoutes.QUOTE_RESUME,
      element: <QuoteResumePage />,
    },
    isMobile: true,
  },
  [ClientAppRoutes.PAYMENT_RESUME]: {
    routeProps: {
      path: ClientAppRoutes.PAYMENT_RESUME,
      element: <PaymentResume />,
    },
    isMobile: true,
  },
  [ClientAppRoutes.FORMS]: {
    routeProps: {
      path: ClientAppRoutes.FORMS,
      element: <ClientForms />,
    },
    isMobile: true,
  },
  [ClientAppRoutes.FORM]: {
    routeProps: {
      path: ClientAppRoutes.FORM,
      element: <Form />,
    },
    isMobile: true,
  },
  [ClientAppRoutes.ACCOUNT]: {
    routeProps: {
      path: ClientAppRoutes.ACCOUNT,
      element: <Account />,
    },
    isMobile: true,
  },
  [ClientAppRoutes.DOCUMENTS]: {
    routeProps: {
      path: ClientAppRoutes.DOCUMENTS,
      element: <ClientDocuments />,
    },
    isMobile: true,
  },
  [ClientAppRoutes.ADMINISTRATIVE_DOCUMENTS]: {
    routeProps: {
      path: ClientAppRoutes.ADMINISTRATIVE_DOCUMENTS,
      element: <ClientAdministrativeDocuments />,
    },
    isMobile: true,
  },
  [ClientAppRoutes.LAWYER_FEES]: {
    routeProps: {
      path: ClientAppRoutes.LAWYER_FEES,
      element: <ClientLawyerFees />,
    },
    isMobile: true,
  },
  [ClientAppRoutes.CONVENTION]: {
    routeProps: {
      path: ClientAppRoutes.CONVENTION,
      element: <ClientConvention />,
    },
  },
  [ClientAppRoutes.REGISTERED_LETTERS]: {
    routeProps: {
      path: ClientAppRoutes.REGISTERED_LETTERS,
      element: <ClientRegisteredLetters />,
    },
  },
  [ClientAppRoutes.APPOINTMENT]: {
    routeProps: {
      path: ClientAppRoutes.APPOINTMENT,
      element: <ClientAppointment />,
    },
  },
  [ClientAppRoutes.FORMALITY_NOTARY]: {
    routeProps: {
      path: ClientAppRoutes.FORMALITY_NOTARY,
      element: <ClientFormalityNotary />,
    },
  },
  [ClientAppRoutes.FORMALITY_MARITAL_STATUS]: {
    routeProps: {
      path: ClientAppRoutes.FORMALITY_MARITAL_STATUS,
      element: <ClientFormalityCivilStatus />,
    },
  },
  [ClientAppRoutes.FORMALITY_FINAL_SENDING]: {
    routeProps: {
      path: ClientAppRoutes.FORMALITY_FINAL_SENDING,
      element: <ClientFormalityFinalSending />,
    },
  },
  [ClientAppRoutes.FINAL]: {
    routeProps: {
      path: ClientAppRoutes.FINAL,
      element: <ClientFinal />,
    },
  },
  [ClientAppRoutes.MESSAGING]: {
    routeProps: {
      path: ClientAppRoutes.MESSAGING,
      element: <ClientConversationPage />,
    },
    isMobile: true,
  },
  [ClientAppRoutes.PARTNERS]: {
    routeProps: {
      path: ClientAppRoutes.PARTNERS,
      element: <ClientPartners />,
    },
    isMobile: true,
  },
  [ClientAppRoutes.SUPPORT_CALL]: {
    routeProps: {
      path: ClientAppRoutes.SUPPORT_CALL,
      element: <ClientSupportCall />,
    },
    isMobile: true,
  },
  [PublicAppRoutes.SIGN_IN]: {
    routeProps: {
      path: PublicAppRoutes.SIGN_IN,
      element: () => <Navigate to={ClientAppRoutes.HOME} />,
    },
  },
};

const ClientApp: FC = () => {
  const { isMobile } = useViewport();

  return (
    <CustomerProvider>
      <MoustacheAIProvider>
        <Header />
        <Container>
          <Routes>
            {Object.values(clientRoutes).map((route) => {
              if (!isMobile || route.isMobile) {
                return (
                  <Route
                    key={`clientRoute-${route.routeProps.path}`}
                    path={route.routeProps.path}
                    element={
                      <SentryWrapper
                        element={route.routeProps.element as ReactElement}
                      />
                    }
                  />
                );
              }

              return null;
            })}

            <Route
              path="*"
              element={isMobile ? <Navigate to={ClientAppRoutes.HOME} /> : null}
            />

            <Route
              path="*"
              element={<SentryWrapper element={<Page404 isAdmin={false} />} />}
            />
          </Routes>
        </Container>
      </MoustacheAIProvider>
    </CustomerProvider>
  );
};

export default ClientApp;
