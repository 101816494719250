import { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Modal, ModalTip } from '.';
import { Flex, Text } from 'components/Layout';
import { Button, IconButton } from 'components/Buttons';
import { TipCard } from 'components/Cards';
import { theme } from 'theme';
import {
  DocumentCommentFull,
  DocumentTemplateId,
  Role,
} from '../../types/resources';
import FileDrop from '../FileDrop/FileDrop';
import { mergeFilesToPdf } from '../../utils/pdf';
import { useViewport } from '../../hooks/useViewport';
import { useAuth } from '../../hooks/useAuth';

interface UploadModalProps {
  opened: boolean;
  onClose: () => void;
  onFileAdd?: (documentId: string, filesId: string[]) => void;
  onConfirm?: (blob: Blob) => void;
  loading?: boolean;
  template?: DocumentTemplateId;
  comments?: DocumentCommentFull[];
  tip?: boolean;
  documentName: string;
  type?: DocumentTemplateId;
  documentFilename?: string | null;
}

interface CustomModalProps {
  large?: boolean;
}

interface WrapperProps {
  extended?: boolean;
  isMobile?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;

  ${(props) =>
    props.extended && props.isMobile
      ? `grid-template-columns: 1fr;`
      : `grid-template-columns: 1fr 1fr;`}
`;

const CustomModal = styled(Modal)<CustomModalProps>`
  height: 600px;
  padding: ${theme.spacing.space24};

  ${(props) =>
    props.large &&
    `
    height: auto;
  `}
`;

const PickerWrapper = styled.div<{ isMobile?: boolean }>`
  height: 400px;
  width: ${(props) => (props.isMobile ? '100%' : '600px')};
`;

const UploadModal: FC<UploadModalProps> = ({
  template,
  comments,
  documentName,
  opened,
  onClose,
  loading,
  onConfirm,
  tip = true,
}) => {
  const { user } = useAuth();
  const isAdmin = user?.role === Role.ADMIN;
  const { t } = useTranslation();
  const { isMobile } = useViewport();
  const [files, setFiles] = useState<File[]>([]);

  const onDropFiles = (files: File[]) => {
    setFiles(files);
  };

  const confirm = useCallback(() => {
    if (files.length) {
      mergeFilesToPdf(files).then((value) => {
        onConfirm?.(new Blob([value], { type: 'application/pdf' }));
      });
    }
  }, [files]);

  useEffect(() => {
    if (opened) {
      setFiles([]);
    }
  }, [opened]);

  return (
    <CustomModal
      opened={opened}
      large={comments && comments?.length > 0}
      onClose={onClose}
    >
      <Flex alignItems="center" paddingBottom={{ xs: 'space24' }}>
        <Flex expand={true} alignItems="center">
          <IconButton
            iconName="Document"
            color={theme.colors.salmon2}
            backgroundColor={theme.colors.salmon3}
            rounded
          />
          <Text
            fontStyle="heading5"
            content={documentName}
            marginLeft={{ xs: 'space16' }}
          />
        </Flex>
        {!isMobile && (
          <>
            <Button
              marginRight={{ xs: 'space16' }}
              content={'Confirmer'}
              primary
              onClick={confirm}
              $loading={loading}
              disabled={loading || files.length === 0}
            />
            <Button
              content={t('upload.close')}
              iconLeft={{ name: 'CloseSquare' }}
              onClick={onClose}
            />
          </>
        )}
      </Flex>

      {comments?.map((comment) => (
        <Flex marginBottom={{ xs: 'space16' }} key={comment.comment_id}>
          <TipCard
            title={t(`enums:${comment.type}`, {
              defaultValue: 'Commentaire',
            })}
            type="warning"
            content={comment?.comment.content}
            icon="Danger"
          />
        </Flex>
      ))}

      {tip && isMobile && (
        <>
          <ModalTip type={template} maxFiles={5} />
        </>
      )}
      <Wrapper extended={tip} isMobile={isMobile}>
        <Flex direction={{ xs: 'column' }} justify="between">
          <PickerWrapper isMobile={isMobile}>
            <FileDrop
              onDrop={onDropFiles}
              multiple={true}
              maxFiles={
                template === DocumentTemplateId.FAMILY_RECORD_BOOK ? 15 : 5
              }
              disabled={loading}
              compressImages
              maxSize={isAdmin ? 100 * 1000 * 1000 : undefined}
            />
          </PickerWrapper>
        </Flex>
        {tip && !isMobile && (
          <>
            <ModalTip
              type={template}
              maxFiles={
                template === DocumentTemplateId.FAMILY_RECORD_BOOK ? 15 : 5
              }
            />
          </>
        )}
      </Wrapper>

      {isMobile && (
        <Flex
          justify={'between'}
          marginTop={{ xs: 'space16' }}
          direction={{ xs: 'row' }}
        >
          <Button
            content={t('upload.close')}
            iconLeft={{ name: 'CloseSquare' }}
            onClick={onClose}
          />
          <Button
            marginRight={{ xs: 'space16' }}
            content={'Confirmer'}
            primary
            onClick={confirm}
            $loading={loading}
            disabled={loading || files.length === 0}
          />
        </Flex>
      )}
    </CustomModal>
  );
};

export default UploadModal;
