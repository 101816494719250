import React, { FC, useState } from 'react';
import * as Popover from '@radix-ui/react-popover';
import * as Checkbox from '@radix-ui/react-checkbox';
import { Check, Filter, X } from 'lucide-react';
import {
  ActiveFilter,
  FilterOption,
  FilterSystemProps,
} from '../../../types/filters';
import { DateRange, DayPicker } from 'react-day-picker';
import { fr } from 'react-day-picker/locale';
import { differenceInDays, setHours, setMinutes, setSeconds } from 'date-fns';
import { Button } from '../../../components/Buttons';
import format from 'date-fns/format';
import { useSearchParamsContext } from '../../../context';
import { CasesFilters } from '../../../pages/admin/Cases';

export const FilterSystem: FC<FilterSystemProps> = ({
  filters,
  className = '',
}) => {
  const {
    filters: currentFilters,
    updateFilters,
    removeFilter,
  } = useSearchParamsContext<CasesFilters>();

  const [selectedLabel, setSelectedLabel] = useState<string | null>(null);

  const activeFilters: ActiveFilter[] = React.useMemo(() => {
    const active = [];

    const { appointment_to, appointment_from, ...restFilters } = currentFilters;

    for (const [key, value] of Object.entries(restFilters)) {
      let found = false;
      for (const filter of filters) {
        if (filter.multi && value === true) {
          const option = filter.options?.find((opt) => opt.value === key);
          if (option) {
            active.push({
              groupId: key,
              label: `${filter.label}: <strong>${option.label}</strong>`,
              value: key,
              filter: filter,
            });
            found = true;
            break;
          }
        }
      }

      if (!found) {
        const filter = filters.find((f) => f.id === key);
        if (filter && !filter.multi) {
          const option = filter.options?.find((opt) => opt.value === value);
          if (option) {
            active.push({
              groupId: key,
              label: `${filter.label}: <strong>${option.label}</strong>`,
              value,
              filter: filter,
            });
          }
        }
      }
    }

    if (appointment_from && appointment_to) {
      const filter = filters.find((f) => f.id === 'appointment');

      if (filter) {
        active.push({
          groupId: ['appointment_from', 'appointment_to'],
          label: `Rendez-vous: de <strong>${appointment_from}</strong> à <strong>${appointment_to}</strong>`,
          value: undefined,
          filter: filter,
        });
      }
    }

    return active;
  }, [currentFilters, filters]);

  return (
    <div className={`tw-flex tw-flex-row tw-gap-2 ${className}`}>
      <Popover.Root
        onOpenChange={(open) => {
          if (!open) {
            setSelectedLabel(null);
          }
        }}
      >
        <Popover.Trigger asChild>
          <button className="tw-flex tw-items-center tw-gap-2 tw-p-3 tw-bg-white tw-rounded-xl tw-shadow-sm tw-border tw-border-gray-200 hover:tw-bg-gray-50 tw-transition-colors">
            <Filter className="tw-w-6 tw-h-6" />
          </button>
        </Popover.Trigger>

        <Popover.Portal>
          <Popover.Content
            className="tw-bg-white tw-rounded-lg tw-shadow-lg tw-border tw-border-gray-200 tw-z-50 tw-text-sm tw-font-open"
            sideOffset={5}
            align="start"
          >
            <div className="tw-flex tw-flex-col">
              <div className="tw-px-3 tw-py-2 tw-border-b">
                <span className="tw-font-bold">Filtres</span>
              </div>
              <div className="tw-flex tw-flex-col tw-px-1 tw-py-2 tw-gap-2">
                {filters.map((filter) => (
                  <div key={filter.id} className="tw-relative">
                    <button
                      className="tw-w-full tw-flex tw-flex-row tw-items-center tw-gap-2 tw-text-left tw-px-2 tw-py-2 tw-rounded hover:tw-bg-gray-100 tw-transition-colors"
                      onClick={() =>
                        setSelectedLabel(
                          selectedLabel === filter.id ? null : filter.id,
                        )
                      }
                    >
                      {filter.icon}
                      <span>{filter.label}</span>
                    </button>

                    {selectedLabel === filter.id && (
                      <div
                        className="tw-fixed tw-top-0 tw-bg-white tw-rounded-lg tw-shadow-lg tw-border tw-border-gray-200 tw-z-50"
                        style={{
                          left: 'calc(100% + 8px)',
                        }}
                      >
                        <div className="tw-flex tw-flex-col">
                          <div className="tw-px-3 tw-py-2 tw-border-b">
                            <span className="tw-font-bold">
                              {
                                filters.find(
                                  (filter) => filter.id === selectedLabel,
                                )?.label
                              }
                            </span>
                          </div>
                          <div className="tw-flex tw-flex-col tw-px-1 tw-py-2 tw-gap-0.5">
                            {(() => {
                              switch (filter.type) {
                                case 'checkbox':
                                  return filter.options?.map((option) => (
                                    <CheckboxFilter
                                      key={option.value}
                                      option={option}
                                      id={filter.id}
                                      isMulti={filter.multi}
                                    />
                                  ));
                                case 'date':
                                  return <DateFilter id={filter.id} />;
                                default:
                                  return null;
                              }
                            })()}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
      <div className="tw-flex tw-flex-wrap tw-gap-2">
        {activeFilters.map((filter) => (
          <ActiveFilterBadge
            key={
              Array.isArray(filter.groupId)
                ? filter.groupId.join('-')
                : filter.groupId
            }
            activeFilter={filter}
            onRemove={() =>
              Array.isArray(filter.groupId)
                ? filter.groupId.forEach((id) => removeFilter(id))
                : removeFilter(filter.groupId)
            }
          />
        ))}
      </div>
    </div>
  );
};

const ActiveFilterBadge: FC<{
  activeFilter: ActiveFilter;
  onRemove: () => void;
}> = ({ activeFilter, onRemove }) => {
  if (activeFilter.filter.type === 'date') {
    return (
      <div className="tw-border tw-bg-white tw-px-3 tw-py-4 tw-rounded-xl tw-flex tw-items-center tw-gap-2 tw-relative">
        <span className="tw-rounded-full tw-absolute -tw-left-1 -tw-top-1 tw-border tw-p-1 tw-bg-white">
          <Filter size={11} />
        </span>
        <span
          className="tw-text-sm"
          dangerouslySetInnerHTML={{ __html: activeFilter.label }}
        ></span>
        <button onClick={onRemove} className="tw-ml-4" type="button">
          <X size={20} />
        </button>
      </div>
    );
  }

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <div className="tw-border tw-bg-white tw-px-3 tw-py-4 tw-rounded-xl tw-flex tw-items-center tw-gap-2 tw-relative">
          <span className="tw-rounded-full tw-absolute -tw-left-1 -tw-top-1 tw-border tw-p-1 tw-bg-white">
            <Filter size={11} />
          </span>
          <span
            className="tw-text-sm"
            dangerouslySetInnerHTML={{ __html: activeFilter.label }}
          ></span>
          <button onClick={onRemove} className="tw-ml-4" type="button">
            <X size={20} />
          </button>
        </div>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          className="tw-bg-white tw-rounded-lg tw-shadow-lg tw-border tw-border-gray-200 tw-z-50 tw-text-sm tw-font-open"
          sideOffset={5}
          align="start"
        >
          <div className="tw-flex tw-flex-col tw-px-1 tw-py-2 tw-gap-0.5">
            {activeFilter.filter.options?.map((option) => (
              <CheckboxFilter
                key={option.value}
                option={option}
                id={activeFilter.filter.id}
                isMulti={activeFilter.filter.multi}
              />
            ))}
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

const CheckboxFilter: FC<{
  id: string;
  option: FilterOption;
  isMulti?: boolean;
}> = ({ id, option, isMulti }) => {
  const {
    filters: currentFilters,
    updateFilters,
    removeFilter,
    getFilter,
  } = useSearchParamsContext<CasesFilters>();

  const handleChange = (checked: boolean) => {
    if (checked) {
      updateFilters({
        [isMulti ? option.value : id]: isMulti ? true : option.value,
      });
    } else {
      removeFilter(isMulti ? option.value : id);
    }
  };

  const isChecked = () => {
    if (isMulti) {
      return getFilter(option.value) as boolean;
    } else {
      return getFilter(id) === option.value;
    }
  };

  return (
    <div className="tw-px-2">
      <label className="tw-flex tw-items-center tw-gap-2 tw-cursor-pointer">
        <Checkbox.Root
          checked={isChecked()}
          onCheckedChange={handleChange}
          className="tw-w-5 tw-h-5 tw-border tw-border-gray-500 tw-rounded-md tw-bg-white data-[state=checked]:tw-bg-primary tw-text-white tw-flex tw-items-center tw-justify-center"
        >
          <Checkbox.Indicator>
            <Check size={14} />
          </Checkbox.Indicator>
        </Checkbox.Root>
        <span className="tw-text-sm tw-text-gray-700 tw-whitespace-nowrap">
          {option.label}
        </span>
      </label>
    </div>
  );
};

const DateFilter: React.FC<{
  id?: string;
}> = ({ id }) => {
  const {
    filters: currentFilters,
    updateFilters,
    removeFilter,
    getFilter,
  } = useSearchParamsContext<CasesFilters>();

  const [selected, setSelected] = useState<DateRange | undefined>(
    getFilter(`${id}_from`) && getFilter(`${id}_to`)
      ? {
          from: new Date(getFilter(`${id}_from`) as string),
          to: new Date(getFilter(`${id}_to`) as string),
        }
      : undefined,
  );

  function dayRange(date1: Date, date2: Date) {
    return Math.abs(differenceInDays(date2, date1));
  }

  const handleChange = () => {
    if (!selected?.from || !selected?.to) {
      return;
    }

    updateFilters({
      [`${id}_from`]: format(selected.from, 'yyyy-MM-dd'),
      [`${id}_to`]: format(selected.to, 'yyyy-MM-dd'),
    });
  };

  return (
    <div className="tw-w-[680px]">
      <div className="tw-px-4">
        <DayPicker
          captionLayout="dropdown"
          locale={fr}
          numberOfMonths={2}
          mode="range"
          onSelect={(date) => {
            let dateEnd = date?.to;
            dateEnd = setHours(dateEnd!, 23);
            dateEnd = setMinutes(dateEnd, 59);
            dateEnd = setSeconds(dateEnd, 59);

            setSelected({ from: date?.from, to: dateEnd });
          }}
          selected={selected}
        />
      </div>
      <div className="tw-border-t tw-px-4 tw-pt-2 tw-flex tw-items-center tw-justify-between">
        {selected?.from && selected.to && (
          <span>{dayRange(selected.from, selected.to) + 1} jours</span>
        )}
        <Button
          primary
          disabled={!selected}
          className="tw-ml-auto"
          onClick={handleChange}
        >
          Valider
        </Button>
      </div>
    </div>
  );
};

export default FilterSystem;
