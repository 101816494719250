import { JSONSchema7 } from 'json-schema';

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export type Department = {
  code: string;
  name: string;
  full_name: string;
  idf: boolean; // (internal)
};

export enum ProductType {
  PLAN = 'PLAN',
  OPTION = 'OPTION',
  PRODUCT = 'PRODUCT',
}

export enum EstimateStatus {
  OPEN = 'OPEN',
  WON = 'WON',
}

export enum ProductCode {
  // Products
  BienImmobilier = 'BI',
  Province = 'PROV',
  IleDeFrance = 'IDF',
  EnfantsMineurs = 'ENFMIN',
  EnfantsMajeurs = 'ENFMAJ',
  FraisPostauxObligatoires = 'ENREG',
  GestionnaireDedie = 'GESTD',
  AvocatsIndependants = 'AVOC',

  // Options
  Express = 'EXP',
  Premium = 'PREM',

  // Plans
  F1 = 'F1',
  F2 = 'F2',
  F3 = 'F3',
}

export type Product = {
  id: string;
  created_at: string; // (internal)
  updated_at: string; // (internal)
  name: string;
  code: ProductCode;
  price: number;
  lawyer_consultations: number; // (internal)
  enabled: boolean; // (internal)
  type: ProductType;
  short_name: string | null;
  label: string | null;
  description: string | null;
};

export type Plan = Product & {
  type: ProductType.PLAN;
  recommended: boolean;
};

export type Option = Product & {
  type: ProductType.OPTION;
};

export enum Role {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  LAWYER = 'LAWYER',
  CUSTOMER = 'CUSTOMER',
  SALES = 'SALES',
}

export enum UserGender {
  Male = 'M',
  Female = 'F',
}

export type User = {
  id: string;
  created_at: string; // (internal)
  updated_at: string; // (internal)
  email: string;
  first_name: string;
  last_name: string;
  full_name: string;
  phone: string;
  department_code: Department['code'] | null; // (internal)
  role: Role;
  activated: boolean; // (internal)
  gender: UserGender | null;
};

export type Prospect = {
  id: string;
  created_at: string; // (internal)
  updated_at: string;
  email: string;
  phone: string | null;
  pipedrive_person_id: number | null; // (internal)
  pipedrive_sync_date: Date | null; // (internal)
};

export type Customer = User & {
  role: Role.CUSTOMER;

  accept_terms_date: string;
  accept_data_business_usage: string;
  accept_marketing_contact: boolean;
  pipedrive_person_id: number | null; // (internal)
  pipedrive_sync_date: Date | null; // (internal)

  procedure_id: Procedure['id'] | null;
  address_id: Address['id'] | null;
  current_estimate_id: Estimate['id'] | null;
};

export type Address = {
  id: string;
  created_at: Date;
  updated_at: Date;

  street: string;
  post_code: string;
  city: string;
  country: string;
  additional_address: string;

  customer_id: Customer['id'];
};

export type CustomerFull = Customer & {
  address: Address | null;
};

export type Admin = User & {
  role: Role.ADMIN;
  department_code: Department['code'];
};

export type Manager = User & {
  role: Role.MANAGER;
  department_code: Department['code'];
  support_days: string | null;
  support_hours: string | null;
  support_booking_url: string | null;
};

export type SalesPerson = User & {
  role: Role.SALES;
  department_code: Department['code'];
};

export type HtmlTemplate = {
  id: string;
  created_at: Date;
  updated_at: Date | null;
  name: string;
};

export type HtmlTemplateFull = HtmlTemplate & {
  content: string;
};

export type Lawyer = User & {
  role: Role.LAWYER;
  department_code: Department['code'];
  fees_file_id: File['id'];
  letter_template_id: HtmlTemplate['id'] | null;
  yo_process_value: string;
  signature_email: string;
};

export type LawyerFull = Lawyer & {
  fees_file: File; // (internal)
};

export type InternalUser = Admin | Manager | SalesPerson | Lawyer;

export type Estimate = {
  id: string;
  created_at: string; // (internal)
  updated_at: string; // (internal)
  name: string;
  split_payments: boolean | null;
  has_real_estate: boolean;
  adult_children: number;
  minor_children: number;
  department_code: Department['code'];
  customer1_id: Customer['id'] | null;
  customer2_id: Customer['id'] | null;
  plan_id: Plan['id'];
  options_ids: Option['id'][];
  procedure_id: Procedure['id'] | null; // (internal)
  procedure_reference: Procedure['reference'] | null; // (internal)
  price: number;
  amount_paid: number;
  status: EstimateStatus;
  pipedrive_deal_id: number | null; // (internal)
  pipedrive_sync_date: Date | null; // (internal)
};

export type EstimateProduct = {
  estimate_id: Estimate['id'];
  product_id: Product['id'];
  quantity: number;
};

export type EstimateFull = Estimate & {
  plan: Plan;
  options: Option[];
  products: EstimateProduct[];
  customer1: Customer | null;
  customer2: Customer | null;
  prospects: Prospect[];
};

export type EstimateUpdate = Pick<
  Estimate,
  | 'has_real_estate'
  | 'adult_children'
  | 'minor_children'
  | 'plan_id'
  | 'options_ids'
>;

export type CustomerEstimate = {
  customer_id: string;
  estimate_id: string;
  number: number;
  amount_paid: number;
};

export enum NotificationStatus {
  PENDING = 'PENDING',
  CREATED = 'CREATED',
  SENT = 'SENT',
  DELIVERED = 'DELIVERED',
  OPENED = 'OPENED',
  FAILED = 'FAILED',
}

export enum NotificationType {
  ACCOUNT_REGISTER = 'ACCOUNT_REGISTER',
  ACCOUNT_CREATION = 'ACCOUNT_CREATION',
  ACCOUNT_PASSWORD_RESET = 'ACCOUNT_PASSWORD_RESET',

  ESTIMATE_QUOTE = 'ESTIMATE_QUOTE',
  ESTIMATE_SHARE = 'ESTIMATE_SHARE',
  ESTIMATE_SPLIT_PAYMENT = 'ESTIMATE_SPLIT_PAYMENT',
  ESTIMATE_CONFIRMATION = 'ESTIMATE_CONFIRMATION',

  PAYMENT_SUCCESS_ADMIN = 'PAYMENT_SUCCESS_ADMIN',
  PAYMENT_FAILED = 'PAYMENT_FAILED',

  FORMS_FEE_CONTRACT_SIGNATURE = 'FORMS_FEE_CONTRACT_SIGNATURE',
  FORMS_SUBMITTED = 'FORMS_SUBMITTED',
  FORMS_EDITED = 'FORMS_EDITED',
  FORMS_VALIDATED_SPOUSE = 'FORMS_VALIDATED_SPOUSE',
  FORMS_VALIDATED = 'FORMS_VALIDATED',

  DOCUMENTS_NEW = 'DOCUMENTS_NEW',
  DOCUMENTS_UPLOAD_REMINDER = 'DOCUMENTS_UPLOAD_REMINDER',
  DOCUMENTS_UPLOADED = 'DOCUMENTS_UPLOADED',
  DOCUMENTS_ASK = 'DOCUMENTS_ASK',
  DOCUMENTS_COMMENT = 'DOCUMENTS_COMMENT',

  MESSAGING_NEW_MESSAGE = 'MESSAGING_NEW_MESSAGE',

  APPOINTMENT_UPDATED = 'APPOINTMENT_UPDATED',

  AGREEMENT_READY = 'AGREEMENT_READY',
  AGREEMENT_EDITION = 'AGREEMENT_EDITION',
  AGREEMENT_EDITION_DONE = 'AGREEMENT_EDITION_DONE',
  AGREEMENT_VALIDATION_REMINDER = 'AGREEMENT_VALIDATION_REMINDER',
  AGREEMENT_VALIDATED = 'AGREEMENT_VALIDATED',

  RECOMMENDED_LRE_SENT = 'RECOMMENDED_LRE_SENT',
  RECOMMENDED_LRAR_SENT = 'RECOMMENDED_LRAR_SENT',
  RECOMMENDED_RECEIVED_SPOUSE = 'RECOMMENDED_RECEIVED_SPOUSE',
  RECOMMENDED_RECEIVED_ALL = 'RECOMMENDED_RECEIVED_ALL',
  RECOMMENDED_UPDATED = 'RECOMMENDED_UPDATED',

  FORMALITIES_PROCEDURE_FINALIZATION = 'FORMALITIES_PROCEDURE_FINALIZATION',
  FORMALITIES_DIVORCE_CERTIFICATE = 'FORMALITIES_DIVORCE_CERTIFICATE',
  FORMALITIES_WEDDING_CERTIFICATE_TRANSCRIPTION = 'FORMALITIES_WEDDING_CERTIFICATE_TRANSCRIPTION',
  FORMALITIES_FINAL_FOLDER_SENDING = 'FORMALITIES_FINAL_FOLDER_SENDING',
}

export type Notification = {
  id: string;
  created_at: Date | null;
  sent_at: Date | null;
  delivered_at: Date | null;
  opened_at: Date | null;

  type: NotificationType;
  status: NotificationStatus;
  error: string | null;
  email: string | null;
  phone: string | null;
  data: Record<string, string> | null;

  user_id: User['id'] | null;
};

export enum PaymentStatus {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export type Payment = {
  id: string;
  created_at: Date;
  updated_at: Date;

  amount: number;
  paypal_id: string | null;
  alma_id: string | null;
  status: PaymentStatus;
  error: string | null;
  expires_at: Date | null;

  customer_id: Customer['id'];
  estimate_id: Estimate['id'];
};

export type PaymentFull = Payment & {
  customer: Customer;
  estimate: Estimate;
};

export enum ProcedureStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  ARCHIVED = 'ARCHIVED',
}

export enum ProcedureStepType {
  FORM = 'FORM',
  DOCUMENT = 'DOCUMENT',
  VERIFICATION = 'VERIFICATION',
  WRITING_AGREEMENT = 'WRITING_AGREEMENT',
  REVIEW_AGREEMENT = 'REVIEW_AGREEMENT',
  AGREEMENT_SENT = 'AGREEMENT_SENT',
  SIGNATURE = 'SIGNATURE',
  ARCHIVE_SIGN = 'ARCHIVE_SIGN',
  NOTARY = 'NOTARY',
  MARITAL_STATUS = 'MARITAL_STATUS',
  FINAL_SENDING = 'FINAL_SENDING',
  CLOSED = 'CLOSED',
}

export enum ProcedureStepStatus {
  LOCKED = 'LOCKED',
  IN_PROGRESS = 'IN_PROGRESS',
  IN_REVIEW = 'IN_REVIEW',
  VALIDATED = 'VALIDATED',
}

export type Procedure = {
  id: string;
  created_at: Date; // (internal)
  updated_at: Date; // (internal)

  name: string;
  reference: number;
  status: ProcedureStatus;
  assigned: boolean; // (internal)
  notary_partner: boolean; // (internal)
  has_real_estate: boolean;
  adult_children: number;
  minor_children: number;
  lawyer_consultations: boolean;
  step: ProcedureStepType;
  current_step: ProcedureStep;
  unprocessed_messages: number; // (internal)
  yo_process_case_id: string | null; // (internal)
  yo_process_sync_date: Date | null; // (internal)
  deletion_date: Date | null;

  estimate_id: Estimate['id'];
  manager_id: Manager['id'] | null;
  spouse1_id: Customer['id'] | null;
  spouse2_id: Customer['id'] | null;
  lawyer1_id: Lawyer['id'] | null; // (internal)
  lawyer2_id: Lawyer['id'] | null; // (internal)
  signature_appointment_id: Appointment['id'] | null;
  divorce_agreement_id: DivorceAgreement['id'] | null;
  last_step_updated_at: Date;
  appointment_date: Date;

  tp: boolean;
  not_ext: boolean;
  trad: boolean;
  av: boolean;
  csl: boolean;
  express: boolean;
  premium: boolean;
  bi: boolean;

  lawyer_fees_status: 'COMPLETE' | 'PARTIAL' | 'INCOMPLETE';
  payment_status: 'COMPLETE' | 'PARTIAL';
  consent_status: 'RECEIVED' | 'UNRECEIVED' | 'P_RECEIVED' | 'NOT_CONCERNED';
  letter_status:
    | 'RECEIVED'
    | 'UNRECEIVED'
    | 'P_RECEIVED'
    | 'NOT_CONCERNED'
    | 'NEGLECTED'
    | 'P_NEGLECTED';

  letter_errors: string[];
  signature_errors: string[];
};

export enum SpouseRole {
  SPOUSE_FOLLOWER = 'SPOUSE_FOLLOWER',
  SPOUSE_LEAD = 'SPOUSE_LEAD',
}

export enum AppointmentStatus {
  CREATED = 'CREATED',
  CANCELLED = 'CANCELLED',
  VALIDATED = 'VALIDATED',
}

export type Appointment = {
  id: string;
  created_at: Date;
  updated_at: Date | null;
  cancelled_at: Date | null;
  validated_at: Date | null;

  start_date: string;
  end_date: string;
  address: string;
  notes: string | null;
  status: AppointmentStatus;
  provider_id: string;

  procedure_id: Procedure['id'];
};

export enum DivorceAgreementStatus {
  LOCKED = 'LOCKED',
  CREATED = 'CREATED',
  VALIDATED = 'VALIDATED',
  SENT = 'SENT',
  RECEIVED = 'RECEIVED',
  SIGNED = 'SIGNED',
}

export type DivorceAgreement = {
  id: string;
  created_at: Date;
  updated_at: Date;
  validated_at: Date | null;
  sent_at: Date | null;
  received_at: Date | null;
  signed_at: Date | null;

  locked: boolean;
  status: DivorceAgreementStatus;

  project_document_id: Document['id'] | null;
  signed_document_id: Document['id'] | null;
  signature_id: Signature['id'] | null;
  procedure_id: Procedure['id'];
};

export type DivorceAgreementFull = DivorceAgreement & {
  validations: DivorceAgreementValidation[];
};

export interface DivorceAgreementValidation {
  user_id: User['id'];
  procedure_id: Procedure['id'];
  divorce_agreement_id: DivorceAgreement['id'];

  validated_at: Date | null;
}

export type ProcedureWorkflow = {
  step: ProcedureStepType;
  order: number;
};

export type ProcedureStepValidation = {
  created_at: Date;
  step: ProcedureStepType;
  procedure_id: Procedure['id'];
  user_id: User['id'];
};

export type ProcedureStep = {
  created_at: Date;
  updated_at: string;

  status: ProcedureStepStatus;
  step: ProcedureStepType;
  procedure_id: Procedure['id'];
};

export type ProcedureStepFull = ProcedureStep & {
  validations: ProcedureStepValidation[];
};

export type ProcedureFull = Procedure & {
  manager?: Manager | null;
  spouse1?: User | null;
  spouse2?: User | null;
  lawyer1?: Lawyer | null;
  lawyer2?: Lawyer | null;
  steps: (ProcedureStep & ProcedureWorkflow)[];
};

export type PaymentUrl = {
  url: string;
};

export type Message = {
  id: string;
  created_at: string;
  updated_at: string;

  content: string;
  processed: boolean;

  conversation_id: Conversation['id'];
  author_id?: User['id'];
};

export type MessageFull = Message & {
  author?: User;
  attachments: File[];
};

export type Conversation = {
  id: string;
  created_at: Date;
  updated_at: Date | null;

  spouse_id: ProcedureSpouse['spouse_id'] | null;
  procedure_id: ProcedureSpouse['procedure_id'];
  last_message_id: Message['id'] | null;
  unprocessed_messages: number;
};

export type ConversationFull = Conversation & {
  participants: User[];
  last_message: Message | null;
};

export enum FormStatus {
  TO_COMPLETE = 'TO_COMPLETE',
  COMPLETED = 'COMPLETED',
  IN_REVIEW = 'IN_REVIEW',
  VALIDATED = 'VALIDATED',
}

export enum FormTemplateId {
  COMPENSATORY_ALLOWANCE = 'COMPENSATORY_ALLOWANCE',
  GOODS_AND_DEBTS = 'GOODS_AND_DEBTS',
  PROPERTIES = 'PROPERTIES',
  YOUNG_CHILDREN = 'YOUNG_CHILDREN',
  ADULT_CHILDREN = 'ADULT_CHILDREN',
  AGREEMENT_YOUNG_CHILDREN = 'AGREEMENT_YOUNG_CHILDREN',
  AGREEMENT_ADULT_CHILDREN = 'AGREEMENT_ADULT_CHILDREN',
  WEDDING = 'WEDDING',
  SPOUSE_LEAD = 'SPOUSE_LEAD',
  SPOUSE_FOLLOWER = 'SPOUSE_FOLLOWER',
  MARITAL_HOME = 'MARITAL_HOME',
}

export type FormTemplate = {
  id: FormTemplateId;
  title: string;
  schema: JSONSchema7[];
  order: number;
};

export type FormAnswer = {
  id: string;
  created_at: Date;
  updated_at: string | null;

  key: string;
  value: string | Record<string, string>[];
  step: number;

  form_id: Form['id'];
  created_by_id: User['id'];
  updated_by_id: User['id'] | null;
};

export type Form = {
  id: string;
  created_at: Date;
  updated_at: Date | null;

  name: string;
  procedure_id: Procedure['id'];
  form_template_id: FormTemplate['id'];
  status: FormStatus;
  concerned_spouse_id: string | null;
};

export type FormFull = Form & {
  template: FormTemplate;
  answers: FormAnswer[];
  concerned_spouse: { id: string; full_name: string } | null;
};

export type ProceduresStats = {
  new_count: number;
  forms_step_count: number;
  documents_step_count: number;
  archived_count: number;
  error_count: number;
};

export type ProcedureSpouse = {
  lawyer_consultation: number;
  number: number;

  spouse_id: Customer['id'];
  procedure_id: Procedure['id'];
  lawyer_id: Lawyer['id'] | null;
  signed_lawyer_fees_id: Document['id'] | null;
};

export type ProcedureSpouseFull = ProcedureSpouse & {
  spouse: Customer;
  lawyer?: Lawyer | null;
};

export type Comment = {
  id: string;
  created_at: Date;
  updated_at: Date | null;

  content: string;
  processed: boolean | null;

  created_by_id: User['id'];
  updated_by_id: User['id'] | null;
};

export type CommentFull = Comment & {
  created_by: User;
  updated_by: User | null;
};

export enum FileProvider {
  S3 = 'S3',
}

export enum FileMimeType {
  JPG = 'image/jpeg',
  PNG = 'image/png',
  PDF = 'application/pdf',
}

export type File = {
  id: string; // (internal)
  created_at: Date; // (internal)
  updated_at: Date; // (internal)

  name: string;
  mime_type: FileMimeType;
  encoding: string;
  size: number;
  provider: FileProvider; // (internal)
  provider_id: string; // (internal)
};

export enum DocumentStatus {
  BEING_ADDED = 'BEING_ADDED',
  IN_PROGRESS = 'IN_PROGRESS',
  IN_REVIEW = 'IN_REVIEW',
  VALIDATE = 'VALIDATE',
}

export enum DocumentTemplateId {
  ID_SPOUSE = 'ID_SPOUSE',
  BIRTH_CERTIFICATE_SPOUSE = 'BIRTH_CERTIFICATE_SPOUSE',
  PROOF_OF_INCOME = 'PROOF_OF_INCOME',
  PROOF_OF_INCOME_OTHER = 'PROOF_OF_INCOME_OTHER',
  WEDDING_CERTIFICATE = 'WEDDING_CERTIFICATE',
  WEDDING_CONTRACT = 'WEDDING_CONTRACT',
  FAMILY_RECORD_BOOK = 'FAMILY_RECORD_BOOK',
  BIRTH_CERTIFICATE_CHILD = 'BIRTH_CERTIFICATE_CHILD',
  DISCERNMENT_FORM = 'DISCERNMENT_FORM',
  PROOF_OF_GOOD = 'PROOF_OF_GOOD',
  PROOF_OF_DEBT = 'PROOF_OF_DEBT',
  PROOF_OF_DONATION = 'PROOF_OF_DONATION',
  LOAN_AMORTIZATION_TABLE = 'LOAN_AMORTIZATION_TABLE',
  PROOF_OVER_INDEBTEDNESS_FOLDER = 'PROOF_OVER_INDEBTEDNESS_FOLDER',
  CAR_REGISTRATION = 'CAR_REGISTRATION',
  CAR_ARGUS = 'CAR_ARGUS',
  LAST_ACCOUNT_STATEMENT = 'LAST_ACCOUNT_STATEMENT',
  COMPANY_SHARES_CERTIFICATE = 'COMPANY_SHARES_CERTIFICATE',
  COMPENSATORY_ALLOWANCE = 'COMPENSATORY_ALLOWANCE',
  LAWYER_FEE_CONTRACT = 'LAWYER_FEE_CONTRACT',
  AGREEMENT_PROJECT = 'AGREEMENT_PROJECT',
  AGREEMENT_PROJECT_DELIVERY_RECEIPT = 'AGREEMENT_PROJECT_DELIVERY_RECEIPT',
  AGREEMENT_SIGNED = 'AGREEMENT_SIGNED',
  NOTARY_LETTER = 'NOTARY_LETTER',
  MARITAL_STATUS = 'MARITAL_STATUS',
  LETTER_FINAL_SENDING = 'LETTER_FINAL_SENDING',
  ALERT_PC = 'ALERT_PC',
  ALERT_PC_SPOUSE_FEEDBACK = 'ALERT_PC_SPOUSE_FEEDBACK',
  ALERT_PA_GC = 'ALERT_PA_GC',
  ALERT_PA_GC_SPOUSE_FEEDBACK = 'ALERT_PA_GC_SPOUSE_FEEDBACK',
  ALERT_PA_GA = 'ALERT_PA_GA',
  ALERT_PA_GA_SPOUSE_FEEDBACK = 'ALERT_PA_GA_SPOUSE_FEEDBACK',
  TAX_NOTICE = 'TAX_NOTICE',
  OTHER = 'OTHER',
  OTHER_DOCUMENT = 'OTHER_DOCUMENT',
  GOOD_SELL_CERTIFICATE = 'GOOD_SELL_CERTIFICATE',
  GOOD_PREPAYMENT_CERTIFICATE = 'GOOD_PREPAYMENT_CERTIFICATE',
  WEALTH_DECLARATION = 'WEALTH_DECLARATION',
  COMPANY_KBIS = 'COMPANY_KBIS',
  COMPANY_STATUS = 'COMPANY_STATUS',
  COMPANY_VALUE_CERTIFICATE = 'COMPANY_VALUE_CERTIFICATE',
  GOOD_SALES_AGREEMENT = 'GOOD_SALES_AGREEMENT',
  PROPERTY_TITLE = 'PROPERTY_TITLE',
  PROPERTY_TITLE_INDIVISIBLE = 'PROPERTY_TITLE_INDIVISIBLE',
  PROCEDURE_CERTIFICATE = 'PROCEDURE_CERTIFICATE',
  LRE_CONSENT_PROOF = 'LRE_CONSENT_PROOF',
  MATRIMONIAL_SETTLEMENT_FINAL_NOTARY = 'MATRIMONIAL_SETTLEMENT_FINAL_NOTARY',
  JOINT_OWNERSHIP_AGREEMENT_FINAL_NOTARY = 'JOINT_OWNERSHIP_AGREEMENT_FINAL_NOTARY',
  REFERRAL_CERTIFICATE = 'REFERRAL_CERTIFICATE',
  TRANSCRIPTION_REQUEST = 'TRANSCRIPTION_REQUEST',
  SPOUSE_SENDING_LETTER = 'SPOUSE_SENDING_LETTER',
}

export interface DocumentTemplate {
  id: DocumentTemplateId;
  title: string;
  order: number;
  is_administrative: boolean;
}

export type Document = {
  id: string;
  created_at: Date;
  updated_at: string | null;

  title: string;
  status: DocumentStatus;
  procedure_id: Procedure['id'];
  document_template_id: DocumentTemplate['id'];
  file_id: File['id'] | null;
  concerned_spouse_id: string | null;
};

export enum DocumentCommentType {
  // GENERAL
  INVALID = 'INVALID',
  READABILITY = 'READABILITY',
  UNCONFIRM_FORM = 'UNCONFIRM_FORM',
  WRONG = 'WRONG',
  DOCUMENT_UNOPENABLE = 'DOCUMENT_UNOPENABLE',
  POOR_FRAMING = 'POOR_FRAMING',
  BACKGROUND_VISIBLE = 'BACKGROUND_VISIBLE',
  // CNI
  CROPPED_EDGES = 'CROPPED_EDGES',
  WRONG_DOCUMENT_TYPE = 'WRONG_DOCUMENT_TYPE',
  MISSING_BACKSIDE = 'MISSING_BACKSIDE',
  EXCESSIVE_BACKGROUND = 'EXCESSIVE_BACKGROUND',
  GRAYSCALE_NOT_ACCEPTED = 'GRAYSCALE_NOT_ACCEPTED',
  MISSING_SIGNATURE_PAGE = 'MISSING_SIGNATURE_PAGE',
  // LDF
  INCOMPLETE_BOOKLET = 'INCOMPLETE_BOOKLET',
  // ACTE ETAT CIVIL
  EXTRACT_NOT_FULL_COPY = 'EXTRACT_NOT_FULL_COPY',
  TRANSLATION_REQUIRED = 'TRANSLATION_REQUIRED',
  MISSING_OFFICIAL_STAMP = 'MISSING_OFFICIAL_STAMP',
  EXPIRED_DOCUMENT = 'EXPIRED_DOCUMENT',
  MISSING_TRANSLATION = 'MISSING_TRANSLATION',
  WRONG_CERTIFICATE_TYPE = 'WRONG_CERTIFICATE_TYPE',
  DOCUMENT_EXPIRED = 'DOCUMENT_EXPIRED',
  COMEDEC_NOT_ACCEPTED = 'COMEDEC_NOT_ACCEPTED',
  // REVENUS
  PAYSLIP_REQUIRED = 'PAYSLIP_REQUIRED',
  SWORN_STATEMENT_NEEDED = 'SWORN_STATEMENT_NEEDED',
  PROVISIONAL_NOT_FINAL = 'PROVISIONAL_NOT_FINAL',
  // DP
  FORM_INCORRECTLY_FILLED = 'FORM_INCORRECTLY_FILLED',
  MISSING_CAPITAL_AMOUNT = 'MISSING_CAPITAL_AMOUNT',
  // EL
  SETTLEMENT_STATEMENT_REQUIRED = 'SETTLEMENT_STATEMENT_REQUIRED',
  // FD
  HANDWRITTEN_SIGNATURE_NEEDED = 'HANDWRITTEN_SIGNATURE_NEEDED',
  // BI
  FULL_TITLE_DEED_REQUIRED = 'FULL_TITLE_DEED_REQUIRED',
}

export type DocumentComment = {
  document_id: Document['id'];
  comment_id: Comment['id'];
  type: DocumentCommentType;
};

export type DocumentCommentFull = DocumentComment & {
  comment: Comment;
};

export type DocumentFull = Document & {
  file: File | null;
  documents_comments: DocumentCommentFull[];
  concerned_spouse: { id: string; full_name: string } | null;
};

export type SignatureTemplate = {
  id: string;
  name: string;
};

export enum SignatureStatus {
  PENDING = 'PENDING',
  CREATED = 'CREATED',
  SENT = 'SENT',
  COMPLETED = 'COMPLETED',
}

export enum SignatureType {
  SIGNED_AGREEMENT = 'SIGNED_AGREEMENT',
  FEE_CONTRACT_SPOUSE = 'FEE_CONTRACT_SPOUSE',
}

export type Signature = {
  id: string;
  type: SignatureType;
  name: string;

  procedure_id: Procedure['id'];
  template_id: SignatureTemplate['id'] | null;
  external_id: string | null;

  sent_at: Date | null;
  completed_at: Date | null;

  status: SignatureStatus;
};

export enum ProcedureAlertType {
  PC = 'PC',
  PA_GC = 'PA_GC',
  PA_GA = 'PA_GA',
}

export type ProcedureAlert = {
  id: string;

  procedure_id: Procedure['id'];
  type: ProcedureAlertType;
  date: Date | null;
  document_id: Document['id'] | null;
};

export type ProcedureAlertFull = ProcedureAlert & {
  feedbacks: ProcedureSpouseAlert[];
};

export type ProcedureSpouseAlert = {
  procedure_id: Procedure['id'];
  spouse_id: Customer['id'];
  alert_id: ProcedureAlert['id'];
  feedback_document_id: Document['id'] | null;
};

export enum ProcedureFeedbackType {
  DOCUMENTS = 'DOCUMENTS',
  LAWYER = 'LAWYER',
}

export type ProcedureFeedback = {
  id: string;

  procedure_id: Procedure['id'];
  type: ProcedureFeedbackType;
  date: Date | null;
  text: string | null;
  lawyer: string | null;
};

export enum LetterType {
  LRE = 'LRE',
  LRAR = 'LRAR',
}

export enum LetterStatus {
  CREATED = 'CREATED',
  SENT = 'SENT',
  RECEIVED = 'RECEIVED',
  REFUSED = 'REFUSED',
  NEGLECTED = 'NEGLECTED',
  BOUNCED = 'BOUNCED',
  FAILED = 'FAILED',
  ERROR = 'ERROR',
}

export type Letter = {
  id: string;
  type: LetterType;
  external_id: string | null;

  created_at: Date;
  sent_at: string | null;
  received_at: string | null;
  refused_at: Date | null;
  neglected_at: Date | null;
  bounced_at: Date | null;
  failed_at: Date | null;

  status: LetterStatus;
  receipt_id: File['id'] | null;
  procedure_id: Procedure['id'];
  sender_id: Lawyer['id'];
  recipient_id: Customer['id'];
  document_id: Document['id'];
};

export enum ConsentStatus {
  CREATED = 'CREATED',
  SENT = 'SENT',
  VALIDATED = 'VALIDATED',
  REFUSED = 'REFUSED',
  BOUNCED = 'BOUNCED',
  FAILED = 'FAILED',
  EXPIRED = 'EXPIRED',
}

export type Consent = {
  id: string;
  external_id: string | null;

  created_at: Date;
  sent_at: string | null;
  validated_at: string | null;
  refused_at: Date | null;
  bounced_at: Date | null;
  failed_at: Date | null;

  status: ConsentStatus;
  receipt_id: File['id'] | null;
  customer_id: Customer['id'];
};

export type NotarialDeed = {
  id: string;

  requested_at: string | null;
  received_at: string | null;

  document_id: Document['id'] | null;
  procedure_id: Procedure['id'];
};

export type CivilStatus = {
  id: string;

  requested_at: string | null;
  received_at: string | null;

  document_id: Document['id'] | null;
  procedure_id: Procedure['id'];
};

export type FinalSending = {
  id: string;

  sent_at: string | null;

  document_id: Document['id'] | null;
  procedure_id: Procedure['id'];
  spouse_id: Customer['id'];
};

export type MessageTemplate = {
  id: string;
  created_at: string; // (internal)
  updated_at: string; // (internal)
  title: string;
  content: string;
  attachments: File[];
};

export type Partner = {
  id: string;
  created_at: Date;
  updated_at: Date | null;
  title: string;
  subtitle: string;
  description: string;
  logo: File | null;
  text_to_action: string;
  card_label: string;
  logo_url: string;
  enabled: boolean;
  buttons?: PartnerButton[];
};

export type PartnerButton = {
  id?: string;
  db_id?: string;
  link: string;
  icon: string;
  bg_color: string;
  text_color: string;
  text: string;
  tag: string;
  click?: number;
};

export type Page = {
  id: string;
  created_at: Date;
  updated_at: Date | null;
  name: string;
  title: string;
  description: string;
};
