import { FC, Fragment, useEffect } from 'react';
import { matchPath, useNavigate } from 'react-router-dom';

import { Flex } from 'components/Layout/Flex';
import { IconButton } from 'components/Buttons/IconButton';
import { theme } from 'theme';
import { Text } from 'components/Layout/Text';
import { Icon } from 'components/Images/Icon';

import { AdminAppRoutes } from 'AdminApp';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../hooks/useApi';
import { Procedure } from '../../../types/resources';

type BreadcrumbsProps = {
  procedureId?: Procedure['id'];
  name?: string;
  reference?: string;
};

interface BreadcrumbRoute {
  path: string;
  breadcrumb: string | (() => JSX.Element);
}

interface BreadcrumbItem {
  path: string;
  breadcrumb: string | JSX.Element;
}

export const Breadcrumbs: FC<BreadcrumbsProps> = ({
  name,
  reference,
  procedureId,
}) => {
  const { execute: getProcedure, state: getProcedureState } = useApi<Procedure>(
    `/procedures/${procedureId}`,
  );
  const procedure = getProcedureState.data?.value;

  useEffect(() => {
    if (procedureId && !name && !reference) {
      getProcedure();
    }
  }, [procedureId, name, reference]);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const routes: BreadcrumbRoute[] = [
    { path: AdminAppRoutes.HOME, breadcrumb: t('breadcrumb.homepage') },
    {
      path: AdminAppRoutes.CASES_DETAILS,
      breadcrumb: () => (
        <span>
          {t('breadcrumb.opportunity', {
            reference: reference ?? procedure?.reference,
            name: name ?? procedure?.name,
          })}
        </span>
      ),
    },
    {
      path: AdminAppRoutes.USER_UPDATE_ID,
      breadcrumb: () => <span>{name}</span>,
    },
    {
      path: AdminAppRoutes.ESTIMATES_ADD,
      breadcrumb: t('estimate.add'),
    },
    {
      path: AdminAppRoutes.ESTIMATES_DETAILS,
      breadcrumb: t('estimate.details.title'),
    },
    {
      path: AdminAppRoutes.PAYMENTS_ADD,
      breadcrumb: t('payments.add.title'),
    },
    {
      path: AdminAppRoutes.PRODUCTS_ADD,
      breadcrumb: t('product.add'),
    },
    {
      path: AdminAppRoutes.PRODUCTS_DETAILS,
      breadcrumb: () => <span>{name}</span>,
    },
    {
      path: AdminAppRoutes.PROSPECTS_ADD,
      breadcrumb: t('breadcrumb.prospects_add_new'),
    },
    {
      path: AdminAppRoutes.CUSTOMERS_ADD,
      breadcrumb: t('breadcrumb.customers_add_new'),
    },
    {
      path: AdminAppRoutes.CUSTOMERS_DETAILS,
      breadcrumb: () => <span>{name}</span>,
    },
    {
      path: AdminAppRoutes.PROSPECTS_DETAILS,
      breadcrumb: () => <span>{name}</span>,
    },
    {
      path: AdminAppRoutes.USER_ADD,
      breadcrumb: t('breadcrumb.add_user'),
    },
  ];

  const PATH_TO_NOT_SHOW = [`${AdminAppRoutes.USERS}/edition`];

  const generateBreadcrumbs = (): BreadcrumbItem[] => {
    const pathSegments = location.pathname.split('/').filter((x) => x);

    const breadcrumbs: BreadcrumbItem[] = [];

    const homeRoute = routes.find(
      (route) => route.path === AdminAppRoutes.HOME,
    );
    if (homeRoute) {
      breadcrumbs.push({
        path: AdminAppRoutes.HOME,
        breadcrumb:
          typeof homeRoute.breadcrumb === 'function'
            ? homeRoute.breadcrumb()
            : homeRoute.breadcrumb,
      });
    }

    let currentPath = '';

    for (let i = 0; i < pathSegments.length; i++) {
      const segment = pathSegments[i];
      currentPath += `/${segment}`;

      let matchedRoute = routes.find((route) => route.path === currentPath);

      if (!matchedRoute) {
        for (const route of routes) {
          const routePattern = route.path.replace(/:\w+/g, '[^/]+');
          const pattern = new RegExp(`^${routePattern}$`);

          if (pattern.test(currentPath) || matchPath(route.path, currentPath)) {
            matchedRoute = route;
            break;
          }
        }
      }

      if (matchedRoute && !PATH_TO_NOT_SHOW.includes(currentPath)) {
        const breadcrumb =
          typeof matchedRoute.breadcrumb === 'function'
            ? matchedRoute.breadcrumb()
            : matchedRoute.breadcrumb;

        breadcrumbs.push({
          path: currentPath,
          breadcrumb,
        });
      }
    }
    return breadcrumbs;
  };

  const breadcrumbs = generateBreadcrumbs();

  return (
    <Flex alignItems="center" marginBottom={{ xs: 'space24' }}>
      <Flex marginRight={{ xs: 'space16' }}>
        <IconButton
          iconName="ArrowLeft"
          color={theme.colors.white}
          backgroundColor={theme.colors.green1}
          hoverIconColor={theme.colors.green1}
          hoverBackgroundColor={theme.colors.green4}
          size="small"
          onClick={() => navigate(-1)}
          rounded
        />
      </Flex>
      {breadcrumbs.map(({ path, breadcrumb }, index) => (
        <Fragment key={path}>
          <Flex onClick={() => navigate(path)} cursor="pointer">
            <Text
              content={breadcrumb as string}
              fontStyle="body3"
              weight="medium"
            />
          </Flex>
          {index !== breadcrumbs.length - 1 && (
            <Icon
              name="ChevronRight"
              primaryColor={theme.colors.black}
              size="small"
            />
          )}
        </Fragment>
      ))}
    </Flex>
  );
};
