import { FC, useEffect, useState } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import frLocale from 'date-fns/locale/fr';

// Styles
import { GlobalStyle } from 'theme/global';

// Components
import { ViewportProvider } from 'hooks/useViewport';
import { AuthProvider } from './context';
import AppRouter from './AppRouter';
import { initEnv } from './config/env';
import { BrowserRouter } from 'react-router-dom';
import { theme } from 'theme';
import { height, size } from './theme/styles/size';
import { fontFamily } from './theme/styles/fonts';
import { ToasterWithMax } from 'components/ToasterWithMax';
import { checkAppVersion, initVersionChecker } from './utils/cacheCheck';

const App: FC = () => {
  const [configLoading, setConfigLoading] = useState(true);

  useEffect(() => {
    initVersionChecker();

    const loadConfig = async () => {
      try {
        await checkAppVersion();

        await initEnv();
        setConfigLoading(false);
      } catch (error) {
        console.error('Error during initialization:', error);
        setConfigLoading(false);
      }
    };

    loadConfig().catch((error) => {
      console.error('Unhandled error during initialization:', error);
      setConfigLoading(false);
    });
  }, []);

  if (configLoading) {
    return null;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
      <ToasterWithMax
        max={2}
        toastOptions={{
          error: {
            style: {
              backgroundColor: theme.colors.red3,
              color: theme.colors.red1,
            },
          },
          success: {
            style: { color: '#54B86B', backgroundColor: '#DFF1E6' },
          },
          style: {
            width: '90%',
            maxWidth: '920px',
            minHeight: '72px',
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing.space24,
            borderRadius: theme.spacing.space8,
            backgroundColor: 'DFF1E6',
            fontSize: size.size3,
            fontFamily: fontFamily.inter,
            lineHeight: height.height4,
            gap: theme.spacing.space16,
          },
        }}
      />
      <GlobalStyle />
      <ViewportProvider>
        <AuthProvider>
          <BrowserRouter>
            <AppRouter />
          </BrowserRouter>
        </AuthProvider>
      </ViewportProvider>
    </LocalizationProvider>
  );
};

export default App;
