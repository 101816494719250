import React, { CSSProperties, FC, useState } from 'react';
import styled from 'styled-components';
import Loader from 'react-loader-spinner';
import { IconButton } from 'components/Buttons/IconButton';
import { Text } from 'components/Layout/Text';
import { md } from 'theme/styles/mediaQueries';
import { IconProps } from 'components/Images/Icon';
import { theme } from '../../theme';

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;

  &:disabled {
    opacity: 0.5;
  }

  ${md(`
    justify-content: start; 
  `)}
`;

interface InlineButtonProps {
  onClick?: () => void;
  iconColor: string;
  text: string;
  backgroundColor: string;
  iconName: IconProps['name'];
  hoverBackgroundColor?: string;
  hoverIconColor?: string;
  noWrap?: boolean;
  style?: CSSProperties;
  disabled?: boolean;
  loading?: boolean;
}

const InlineButton: FC<InlineButtonProps> = ({
  onClick,
  iconColor,
  text,
  backgroundColor,
  iconName,
  hoverBackgroundColor,
  hoverIconColor,
  noWrap = true,
  style,
  disabled,
  loading,
}) => {
  const [hoverState, setHoverState] = useState(false);

  return (
    <Button
      style={style}
      onClick={onClick}
      onMouseEnter={() => setHoverState(true)}
      onMouseLeave={() => setHoverState(false)}
      disabled={disabled || loading}
    >
      {loading ? (
        <Loader
          type="TailSpin"
          color={theme.colors.black}
          height={32}
          width={32}
        />
      ) : (
        <IconButton
          color={hoverState ? hoverIconColor || iconColor : iconColor}
          backgroundColor={
            hoverState
              ? hoverBackgroundColor || backgroundColor
              : backgroundColor
          }
          iconName={iconName}
          rounded
          size="small"
        />
      )}
      <Text
        content={text}
        fontStyle="label"
        whiteSpace={noWrap ? 'nowrap' : 'normal'}
        marginLeft={{ xs: 'space8' }}
      />
    </Button>
  );
};

export default InlineButton;
