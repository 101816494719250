import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Flex } from 'components/Layout/Flex';
import { InputText } from 'components/FormTemplate/Fields/InputText';

// data
// Utils
import { theme } from 'theme';
import { useSearchParamsContext } from '../../../context';
import { CustomersFilters } from '../../../pages/admin/Customers';

const ProspectsListFilters: FC = () => {
  const { t } = useTranslation();
  const {
    filters: currentFilters,
    updateFilters,
    removeFilter,
    getFilter,
  } = useSearchParamsContext<CustomersFilters>();

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    updateFilters({ search: e?.target.value });
  };

  return (
    <Flex marginTop={{ xs: 'space40' }} justify="start">
      <Flex>
        <InputText
          onChange={handleSearchChange}
          label={t('filter.search')}
          labelUppercase={true}
          labelFontStyle="label"
          placeholder={t('filter.search_prospect')}
          width="288px"
          labelWeight="bold"
          $size="small"
          value={(getFilter('search') as string) || ''}
          icon={{ name: 'Search', primaryColor: theme.colors.salmon1 }}
          debounceDelay={500}
        />
      </Flex>
    </Flex>
  );
};

export default ProspectsListFilters;
