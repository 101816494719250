import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Col, Flex, Row } from 'components/Layout';
import { TextEllipsis } from 'components/Text';
import { DateInput } from 'components/Forms';
import { Button, IconButton } from 'components/Buttons';
import { Table } from 'components/Table';

// Data
// Utils
import { theme } from 'theme';
import { UploadModal } from 'components/Modal';
import AddressCard from '../cards/AddressCard';
import { parseISO } from 'date-fns';
import {
  CivilStatus,
  Procedure,
  ProcedureFull,
  ProcedureStepType,
} from '../../../types/resources';
import { useApi } from '../../../hooks/useApi';
import DocumentPreviewModal from '../../../components/Modal/DocumentPreviewModal';
import { ColumnDef } from '@tanstack/react-table';

interface CivilStatusProps {
  procedure: ProcedureFull;
  refetchProcedure: () => void;
}

const CivilStatusPage: FC<CivilStatusProps> = ({
  procedure,
  refetchProcedure,
}) => {
  const step = ProcedureStepType.MARITAL_STATUS;
  const name = 'État civil';
  const { t } = useTranslation();
  const [uploadModalState, setUploadModalState] = useState<boolean>(false);
  const [previewModalState, setPreviewModalState] = useState<boolean>(false);
  const [selected, setSelected] = useState<CivilStatus>();

  const { execute: getData, state: getDataState } = useApi<CivilStatus[]>(
    `/procedures/${procedure.id}/civil-status`,
  );
  const civilStatuses = getDataState.data?.value;
  const civilStatus = civilStatuses?.find(
    (n) => n.procedure_id === procedure.id,
  );

  const { execute: updateData, state: updateDataState } = useApi(
    `/procedures/${procedure.id}/civil-status`,
    {
      method: 'PUT',
      onSuccess: () => {
        onUploadClose();
        getData();
      },
    },
  );

  const finishStep = Boolean(civilStatus?.document_id);

  const onUploadOpen = (selected: CivilStatus) => {
    setSelected(selected);
    setUploadModalState(true);
  };

  const onPreviewOpen = (selected: CivilStatus) => {
    setSelected(selected);
    setPreviewModalState(true);
  };

  const onUploadClose = () => {
    setUploadModalState(false);
  };

  const onPreviewClose = () => {
    setSelected(undefined);
    setPreviewModalState(false);
  };

  const onConfirmUpload = (blob: Blob) => {
    const body = new FormData();
    body.append('file', blob, 'état civil.pdf');

    updateData({
      body,
      onSuccess: () => {
        onUploadClose();
        getData();
        refetchProcedure();
      },
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const columns: ColumnDef<Procedure>[] = [
    {
      header: t('letters.columns.name') || 'Nom',
      id: 'id',
      accessorKey: 'id',
      size: 234,
      enableSorting: false,
      cell: () => (
        <Flex height="100%" alignItems="center">
          <TextEllipsis fontStyle="body1" content={name} weight="medium" />
        </Flex>
      ),
    },
    {
      header: t('letters.columns.demand_date') || 'Date de demande',
      id: 'requested_at',
      accessorKey: 'requested_at',
      size: 200,
      enableSorting: false,
      cell: () => (
        <DateInput
          onChange={(value) => {
            const isValid =
              value instanceof Date &&
              !isNaN(value.getTime()) &&
              value?.getFullYear() > 1900;

            if (isValid) {
              const timezoneOffset = value.getTimezoneOffset();
              value.setHours(0, -timezoneOffset, 0, 0);
              const body = new FormData();
              body.set('requested_at', value.toISOString());
              updateData({ body });
            }
          }}
          disableFuture
          value={
            civilStatus?.requested_at
              ? parseISO(civilStatus.requested_at)
              : null
          }
        />
      ),
    },
    {
      header: t('letters.columns.received_date') || 'Date de réception',
      id: 'received_at',
      accessorKey: 'received_at',
      size: 200,
      enableSorting: false,
      cell: () => (
        <DateInput
          onChange={(value) => {
            const isValid =
              value instanceof Date &&
              !isNaN(value.getTime()) &&
              value?.getFullYear() > 1900;

            if (isValid) {
              const timezoneOffset = value.getTimezoneOffset();
              value.setHours(0, -timezoneOffset, 0, 0);
              const body = new FormData();
              body.set('received_at', value.toISOString());
              updateData({ body });
            }
          }}
          minDate={
            civilStatus?.requested_at
              ? parseISO(civilStatus.requested_at)
              : undefined
          }
          value={
            civilStatus?.received_at ? parseISO(civilStatus.received_at) : null
          }
          disabled={!civilStatus?.requested_at}
        />
      ),
    },
    {
      header: t(`letters.columns.upload_${step}`) || 'Télécharger le courrier',
      id: 'document_id',
      accessorKey: 'document_id',
      enableSorting: false,
      size: 212,
      cell: () => {
        const receipt = t(`formality_letters.buttons.receipt_${step}`);
        const upload = t(`formality_letters.buttons.upload_${step}`);
        return (
          <Flex alignItems="center">
            <Button
              onClick={() => {
                if (civilStatus) {
                  if (civilStatus.document_id) {
                    onPreviewOpen(civilStatus);
                  } else {
                    onUploadOpen(civilStatus);
                  }
                }
              }}
              content={civilStatus?.document_id ? receipt : upload}
              primary={!civilStatus?.document_id}
              disabled={
                !(civilStatus?.received_at && civilStatus?.requested_at)
              }
              marginRight={{
                xs: civilStatus?.document_id ? 'space8' : 'none',
              }}
            />
            {civilStatus?.document_id && (
              <IconButton
                primary
                color={theme.colors.white}
                backgroundColor={theme.colors.salmon1}
                iconName="Edit"
                onClick={() => {
                  onUploadOpen(civilStatus);
                }}
                size="extra-large"
              />
            )}
          </Flex>
        );
      },
    },
  ];

  return (
    <>
      <Row marginBottom={{ xs: 'space32' }}>
        <Col xs={12}>
          <Table data={[procedure]} columns={columns} />
        </Col>
        {selected && (
          <UploadModal
            opened={uploadModalState}
            onClose={() => setUploadModalState(false)}
            onConfirm={onConfirmUpload}
            loading={updateDataState.loading}
            tip={false}
            documentName={name}
          />
        )}
        {civilStatus?.document_id && (
          <DocumentPreviewModal
            opened={previewModalState}
            onClose={onPreviewClose}
            procedure={procedure}
            documentId={civilStatus.document_id}
          />
        )}
      </Row>
      <Row>
        <Col xs={12} md={4}>
          <AddressCard
            customerId={procedure.spouse1_id}
            disabled={finishStep}
            index={1}
          />
        </Col>
        <Col xs={12} md={4}>
          <AddressCard
            customerId={procedure.spouse2_id}
            disabled={finishStep}
            index={2}
          />
        </Col>
      </Row>
    </>
  );
};

export default CivilStatusPage;
