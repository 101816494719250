import { FC } from 'react';
import ConversationListItem from './Item';
import { theme } from 'theme';
import { Flex } from '../../Layout';
import { UserGender } from 'types/resources';

interface ConversationListProps {
  conversations: {
    recipients: {
      full_name?: string;
      first_name?: string;
      last_name?: string;
      gender?: UserGender;
    }[];
    lastMessage?: string;
    onClick: () => void;
    active?: boolean;
  }[];
}

const ConversationList: FC<ConversationListProps> = ({ conversations }) => {
  return (
    <Flex
      direction={{ xs: 'row', lg: 'column' }}
      style={{ gap: theme.spacing.space12 }}
    >
      {conversations.map((conversation, index) => (
        <ConversationListItem
          {...conversation}
          key={`conversation-list-${index}`}
        />
      ))}
    </Flex>
  );
};

export default ConversationList;
