interface VersionInfo {
  version: string;
  buildDate: string;
}

const CONFIG = {
  VERSION_URL: '/version.json',
  VERSION_KEY: 'app_version',
  LAST_CHECK_KEY: 'last_version_check',
  CHECK_INTERVAL: 60 * 60 * 1000,
};

async function checkAppVersion(): Promise<void> {
  try {
    const lastCheck: string | null = localStorage.getItem(
      CONFIG.LAST_CHECK_KEY,
    );
    const now: number = Date.now();

    if (lastCheck && now - parseInt(lastCheck, 10) < CONFIG.CHECK_INTERVAL) {
      console.log('Skip version check - checked recently');
      return;
    }

    localStorage.setItem(CONFIG.LAST_CHECK_KEY, now.toString());

    const response: Response = await fetch(`${CONFIG.VERSION_URL}?_=${now}`, {
      cache: 'no-cache',
      headers: {
        'Cache-Control': 'no-cache',
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch version: ${response.status}`);
    }

    const { version, buildDate }: VersionInfo = await response.json();
    const currentVersion: string | null = localStorage.getItem(
      CONFIG.VERSION_KEY,
    );

    console.log(
      `Current app version: ${currentVersion}, Server version: ${version}`,
    );

    if (!currentVersion) {
      localStorage.setItem(CONFIG.VERSION_KEY, version);
    } else if (currentVersion !== version) {
      console.log('New version detected, clearing cache and reloading...');

      if ('caches' in window) {
        const cacheNames: string[] = await caches.keys();
        await Promise.all(
          cacheNames.map((cacheName: string) => caches.delete(cacheName)),
        );
        console.log('All caches cleared');
      }

      localStorage.setItem(CONFIG.VERSION_KEY, version);

      window.location.reload();
      // window.location.href = window.location.href;
    }
  } catch (error) {
    console.error(
      'Error checking app version:',
      error instanceof Error ? error.message : String(error),
    );
  }
}

function initVersionChecker(): void {
  document.addEventListener('DOMContentLoaded', () => {
    checkAppVersion().catch((error) => {
      console.error('Error checking app version on DOM load:', error);
    });
  });

  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      checkAppVersion().catch((error) => {
        console.error(
          'Error checking app version on visibility change:',
          error,
        );
      });
    }
  });
}

initVersionChecker();

export { checkAppVersion, initVersionChecker };
