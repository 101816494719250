import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { SpecificationCard } from 'components/Cards';
import { useApi } from '../../../../hooks/useApi';
import { ProceduresStats } from '../../../../types/resources';

const OpportunitiesCountStatus: FC = () => {
  const { t } = useTranslation();

  const { execute: getProceduresStats, state: getProceduresStatsState } =
    useApi<ProceduresStats>(`/procedures/stats`);
  const stats = getProceduresStatsState.data?.value;

  useEffect(() => {
    getProceduresStats();
  }, []);

  const opportunitiesStatus = [
    // {
    //   status: t('opportunity.new_files'),
    //   number: stats?.new_count,
    // },
    {
      status: t('opportunity.in_form_step'),
      number: stats?.forms_step_count,
    },
    {
      status: t('opportunity.in_document_step'),
      number: stats?.documents_step_count,
    },
    {
      status: t('opportunity.to_archive'),
      number: stats?.archived_count,
    },
    {
      status: t('opportunity.in_error'),
      number: stats?.error_count,
    },
  ];

  return (
    <div className="tw-gap-5 tw-grid md:tw-grid-cols-4 lg:tw-grid-cols-4 tw-mb-8">
      {opportunitiesStatus.map((opportunity, index) => (
        <div key={`opportunities-status-${index}`}>
          <SpecificationCard
            iconName="Folder"
            specification={opportunity.status}
            value={opportunity.number?.toString() || '...'}
          />
        </div>
      ))}
    </div>
  );
};

export default OpportunitiesCountStatus;
