import { AjvError } from '@rjsf/core';
import { FieldError } from 'react-hook-form';

const mappingFormat: { [key: string]: string } = {
  'custom-regex-phone': 'de téléphone international.',
  'custom-regex-email': "d'email.",
  'custom-postal-code-fr': 'code postal français.',
};

export const transformErrors = (errors: AjvError[]): AjvError[] => {
  return errors.reduce<AjvError[]>((err, error) => {
    // Per schema
    switch (error.name) {
      case 'type':
        error.message = `La valeur est incorrecte.`;
        err.push(error);
        break;
      case 'format':
        error.message = `Doit correspondre au format ${
          mappingFormat[error.params.format] || error.params.format
        }`;
        err.push(error);
        break;
      case 'minItems':
        error.message = `Doit contenir au moins ${error.params.limit} élément(s).`;
        err.push(error);
        break;
      case 'required':
        error.message = 'Veuillez renseigner ce champ';
        err.push(error);
        break;
      case 'minLength':
        error.message = `Le champ doit être au minimum de ${error.params.limit} caractères`;
        err.push(error);
        break;
      case 'maxLength':
        error.message = `Le champ doit être au maximum de ${error.params.limit} caractères`;
        err.push(error);
        break;
      case 'oneOf':
        break;
      case 'const': // no errors for const value
        break;
      case 'enum': // no errors for enum value
        break;
      case 'if':
        break;
      default:
        err.push(error);
    }

    // console.error('error form', err, error);
    return err;
  }, []);
};

export const determineIfIsFieldErrorOrString = (
  toBeDetermined: string | FieldError,
): toBeDetermined is FieldError => {
  if ((toBeDetermined as FieldError).type) {
    return true;
  }
  return false;
};
