import { Icon, IconProps } from 'components/Images/Icon';
import React, {
  ChangeEvent,
  forwardRef,
  InputHTMLAttributes,
  MouseEventHandler,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { FieldError } from 'react-hook-form';
import { Flex } from 'components/Layout/Flex';
import { FontStyles } from 'theme/styles/fonts';
import { Label } from 'components/Text/Label';
import RequiredField from 'components/Forms/Utils/RequiredField';
import { Text } from 'components/Layout/Text';
// Types
import { Weights } from 'theme/styles/size';
// Utils
import { determineIfIsFieldErrorOrString } from 'utils/errors';
import styled from 'styled-components';
// Components
import { theme } from 'theme';
import debounce from 'lodash/debounce';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  $size: Size;
  withError?: boolean;
  width?: string;
  hasIcon?: boolean;
  cursor?: string;
}

const Input = styled.input<InputProps>`
  position: relative;
  height: ${({ $size: size, height }) =>
    height ?? (size === 'medium' ? '64px' : '56px')};
  border: 1px solid
    ${({ withError }) => (withError ? theme.colors.red1 : theme.colors.gray3)};
  border-radius: ${theme.spacing.space16};
  font-size: ${({ $size: size }) => (size === 'medium' ? '16px' : '14px')};
  color: ${theme.colors.black};
  padding: 0 ${theme.spacing.space24};
  padding-right: ${({ hasIcon }) => hasIcon && theme.spacing.space48};
  width: ${(props) => props.width};
  transition: border 0.5s ease;
  font-weight: ${({ disabled, readOnly }) =>
    disabled || readOnly ? '500' : 'normal'};
  cursor: ${({ disabled, readOnly, cursor }) =>
    cursor ? cursor : disabled || readOnly ? 'not-allowed' : 'auto'};

  &:disabled {
    background-color: ${theme.colors.gray2};
  }

  &:read-only {
    background-color: ${theme.colors.gray2};
  }

  &::placeholder {
    font-weight: 400;
    color: ${theme.colors.gray5};
  }

  &:enabled {
    &:active,
    &:focus {
      border: 1px solid
        ${({ withError }) =>
          withError ? theme.colors.red1 : theme.colors.gray4};
      outline: none;
    }
  }
`;

const InputContainer = styled.div`
  position: relative;
`;

const IconContainer = styled.div<IconContainerProps>`
  position: absolute;
  right: ${theme.spacing.space24};
  top: 50%;
  transform: translateY(-50%);
  cursor: ${(props) => props.onClick && 'pointer'};
  display: flex;
`;

type Size = 'small' | 'medium';

interface IconContainerProps {
  onClick?: MouseEventHandler<HTMLDivElement>;
}

export interface InputTextProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  placeholder?: string;
  description?: string;
  descriptionFontStyle?: FontStyles;
  type?: 'email' | 'text' | 'tel' | 'password' | 'number';
  icon?: IconProps;
  $size?: Size;
  width?: string;
  error?: FieldError | string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  required?: boolean;
  labelWeight?: Weights;
  labelUppercase?: boolean;
  labelFontStyle?: FontStyles;
  multiline?: boolean;
  cursor?: string;
  onInputClick?: MouseEventHandler<HTMLInputElement>;
  uppercase?: boolean;
  debounceDelay?: number;
}

/**
 * Simple InputsText with a label and icon use for example in search and in UserAdd and UserUpdate
 * @param param0
 */
export const InputText = forwardRef((props: InputTextProps, ref) => {
  const {
    type = 'text',
    placeholder,
    label,
    description,
    descriptionFontStyle = 'body2',
    icon,
    onClick,
    onInputClick,
    error,
    $size = 'medium',
    required,
    onChange,
    width,
    labelFontStyle,
    labelWeight,
    disabled,
    cursor,
    uppercase,
    debounceDelay,
    value,
    ...otherProps
  } = props;

  const [inputValue, setInputValue] = useState(value || '');

  useEffect(() => {
    if (value !== undefined) {
      setInputValue(value);
    }
  }, [value]);

  const debouncedHandler = useMemo(() => {
    if (!debounceDelay || !onChange) return undefined;

    return debounce((e: ChangeEvent<HTMLInputElement>) => {
      onChange(e);
    }, debounceDelay);
  }, [onChange, debounceDelay]);

  useEffect(() => {
    return () => {
      if (debouncedHandler) {
        debouncedHandler.cancel();
      }
    };
  }, [debouncedHandler]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let newValue = e.target.value;

    if (uppercase) {
      newValue = newValue.toUpperCase();
      e.target.value = newValue;
    }

    if (debounceDelay && debouncedHandler) {
      setInputValue(newValue);

      const eventCopy = { ...e };
      debouncedHandler(eventCopy as ChangeEvent<HTMLInputElement>);
    } else {
      onChange?.(e);
    }
  };
  return (
    <Flex direction={{ xs: 'column' }} width="100%">
      {label && (
        <Flex marginBottom={{ xs: 'space8' }}>
          <RequiredField required={required}>
            <Label
              dangerouslySetInnerHTML={{ __html: label }}
              fontStyle={labelFontStyle}
              weight={labelWeight}
            />
          </RequiredField>
        </Flex>
      )}
      {description && (
        <Flex marginBottom={{ xs: 'space8' }}>
          <Text fontStyle={descriptionFontStyle} content={description} />
        </Flex>
      )}
      <InputContainer onClick={onInputClick}>
        <Input
          ref={ref as any}
          type={type}
          placeholder={placeholder}
          $size={$size}
          width={width}
          onChange={handleChange}
          withError={!!error}
          disabled={disabled}
          hasIcon={!!icon}
          cursor={cursor}
          value={debounceDelay ? inputValue : value}
          {...otherProps}
        />
        {icon && (
          <IconContainer onClick={onClick}>
            <Icon name={icon.name} primaryColor={icon.primaryColor} />
          </IconContainer>
        )}
      </InputContainer>
      {error && (
        <Text
          content={
            error && determineIfIsFieldErrorOrString(error)
              ? error.message
              : error
          }
          fontStyle="body2"
          color={theme.colors.red1}
          marginTop={{ xs: 'space8' }}
        />
      )}
    </Flex>
  );
});

InputText.displayName = 'InputText';
