import { FC, useState } from 'react';
import { Modal } from 'components/Modal';
import { Text } from 'components/Layout';
import { Button } from 'components/Buttons';
import { useTranslation } from 'react-i18next';
import { Clock, CheckCircle2, XCircle, Loader2 } from 'lucide-react';
import { File as CustomFile, ProcedureFull } from 'types/resources';
import { useApiAsync } from 'hooks/useApi';
import MessageTemplatesList from 'components/MessageTemplates/MessageTemplatesList';

type ProcedureProgressStatus = 'pending' | 'processing' | 'completed' | 'error';

type ProcedureProgress = {
  id: string;
  reference: string;
  name: string;
  status: ProcedureProgressStatus;
  error?: string;
};

type ProgressState = {
  total: number;
  current: number;
  currentItem: string;
  procedures: ProcedureProgress[];
};

interface SendMessageModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedRows: ProcedureFull[];
}

const SendMessageModal: FC<SendMessageModalProps> = ({
  isOpen,
  onClose,
  selectedRows,
}) => {
  const { t } = useTranslation();
  const [preview, setPreview] = useState<
    | {
        templateId: string;
        data: string;
      }
    | undefined
  >();
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [progress, setProgress] = useState<ProgressState>({
    total: 0,
    current: 0,
    currentItem: '',
    procedures: [],
  });

  const { execute: sendGroupMessage } = useApiAsync(
    `/conversations/models/:id/send/:procedure_id`,
    {
      method: 'POST',
    },
  );

  const handleModelSelect = (
    templateId: string | undefined,
    data: string,
    attachments?: CustomFile[],
  ) => {
    setPreview({ templateId: templateId!, data });
  };

  const handleSendMessage = async (templateId: string | undefined) => {
    setShowProgress(true);
    const total = selectedRows.length;

    const initialProcedures = selectedRows.map((row) => ({
      id: row.id,
      reference: row.reference.toString(),
      name: row.name,
      status: 'pending' as ProcedureProgressStatus,
    }));

    setProgress({
      total,
      current: 0,
      currentItem: '',
      procedures: initialProcedures,
    });

    for (let i = 0; i < selectedRows.length; i++) {
      const procedure = selectedRows[i];
      setProgress((prev) => ({
        ...prev,
        current: i + 1,
        currentItem: `${procedure.reference} - ${procedure.name}`,
        procedures: prev.procedures.map((p) =>
          p.id === procedure.id ? { ...p, status: 'processing' } : p,
        ),
      }));

      try {
        await sendGroupMessage({
          endpoint: `/conversations/models/${templateId}/send/${procedure.id}`,
        });

        setProgress((prev) => ({
          ...prev,
          procedures: prev.procedures.map((p) =>
            p.id === procedure.id ? { ...p, status: 'completed' } : p,
          ),
        }));
      } catch (error) {
        console.log(error);

        setProgress((prev) => ({
          ...prev,
          procedures: prev.procedures.map((p) =>
            p.id === procedure.id
              ? {
                  ...p,
                  status: 'error',
                  error:
                    error instanceof Error
                      ? error.message
                      : "Une erreur est survenue lors de l'envoi du message",
                }
              : p,
          ),
        }));
      }

      await new Promise((resolve) => setTimeout(resolve, 500));
    }
  };

  const resetAndClose = () => {
    setPreview(undefined);
    setShowProgress(false);
    setProgress({
      total: 0,
      current: 0,
      currentItem: '',
      procedures: [],
    });
    onClose();
  };

  const allCompleted = progress.procedures.every(
    (p) => p.status === 'completed' || p.status === 'error',
  );

  const StatusIcon = ({ status }: { status: ProcedureProgressStatus }) => {
    switch (status) {
      case 'completed':
        return <CheckCircle2 className="tw-w-4 tw-h-4 tw-text-green-500" />;
      case 'error':
        return <XCircle className="tw-w-4 tw-h-4 tw-text-red-500" />;
      case 'processing':
        return (
          <Loader2 className="tw-w-4 tw-h-4 tw-animate-spin tw-text-primary" />
        );
      case 'pending':
        return <Clock className="tw-w-4 tw-h-4 tw-text-gray-400" />;
      default:
        return null;
    }
  };

  return (
    <Modal opened={isOpen} onClose={resetAndClose} width="1024px" zIndex={13}>
      {!showProgress ? (
        <div className="tw-flex tw-flex-col tw-gap-4">
          <div className="tw-flex tw-justify-between tw-items-center">
            <h3 className="tw-text-lg tw-font-semibold">
              {t('opportunity.grouped_actions.send_message.title')}
            </h3>
          </div>

          <Text
            fontStyle="body2"
            content={t('opportunity.grouped_actions.send_message.description')}
          />

          <div className="tw-w-full">
            {preview ? (
              <div>
                <p>Vous vous apprêtez a envoyer le message suivant :</p>
                <p className="tw-border tw-rounded-xl tw-p-2 tw-whitespace-pre-line tw-overflow-y-auto tw-max-h-64">
                  {preview.data}
                </p>
                <div className="tw-flex tw-flex-row tw-gap-8 tw-justify-end">
                  <Button type="button" onClick={() => setPreview(undefined)}>
                    {t('back')}
                  </Button>
                  <Button
                    type="button"
                    primary
                    onClick={() => handleSendMessage(preview?.templateId)}
                  >
                    {t('validate')}
                  </Button>
                </div>
              </div>
            ) : (
              <MessageTemplatesList
                isModal={true}
                onSelect={handleModelSelect}
              />
            )}
          </div>

          <div className="">
            {t('opportunity.grouped_actions.send_message.count')}:{' '}
            {selectedRows.length}
          </div>

          <div className="tw-flex tw-justify-end tw-gap-2 tw-mt-4">
            <Button onClick={resetAndClose}>{t('cancel')}</Button>
          </div>
        </div>
      ) : (
        <div className="tw-flex tw-flex-col tw-gap-4">
          <div className="tw-flex tw-justify-between tw-items-center">
            <h3 className="tw-text-lg tw-font-semibold">
              {allCompleted
                ? t('opportunity.grouped_actions.send_message.completed')
                : t('opportunity.grouped_actions.send_message.in_progress')}
            </h3>
            {allCompleted && (
              <button
                onClick={resetAndClose}
                className="tw-px-3 tw-py-2 tw-text-sm tw-bg-primary tw-rounded-md hover:tw-bg-primary/90 tw-transition tw-border tw-text-white"
              >
                {t('close')}
              </button>
            )}
          </div>

          <div className="tw-w-full tw-h-2 tw-bg-gray-100 tw-rounded-full tw-overflow-hidden">
            <div
              className="tw-h-full tw-bg-primary tw-transition-all tw-duration-300"
              style={{ width: `${(progress.current / progress.total) * 100}%` }}
            />
          </div>

          <span className="tw-text-sm tw-text-gray-500">
            {t('opportunity.grouped_actions.progress', 'Traitement')}{' '}
            {progress.current} {t('common:on', 'sur')} {progress.total}
          </span>

          <div className="tw-max-h-96 tw-overflow-y-auto">
            {progress.procedures.map((procedure) => (
              <div
                key={procedure.id}
                className="tw-flex tw-flex-col tw-py-2 tw-items-start tw-border-b tw-border-gray-100 last:tw-border-0"
              >
                <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
                  <StatusIcon status={procedure.status} />
                  <span className="tw-font-medium">
                    {procedure.reference} - {procedure.name}
                  </span>
                  <span className="tw-text-sm tw-text-gray-500">
                    {procedure.status === 'pending' &&
                      t(
                        'opportunity.grouped_actions.status.pending',
                        'En attente',
                      )}
                    {procedure.status === 'processing' &&
                      t(
                        'opportunity.grouped_actions.status.processing',
                        'En cours',
                      )}
                    {procedure.status === 'completed' &&
                      t(
                        'opportunity.grouped_actions.status.completed',
                        'Terminé',
                      )}
                    {procedure.status === 'error' &&
                      t('opportunity.grouped_actions.status.error', 'Erreur')}
                  </span>
                </div>
                {procedure.error && (
                  <span className="tw-text-sm tw-text-red-500 tw-mt-1">
                    {procedure.error}
                  </span>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </Modal>
  );
};

export default SendMessageModal;
