import { theme } from 'theme';

export const getColors = (
  primary: boolean | undefined,
  disabled: boolean | undefined = false,
  backgroundColor: string | undefined,
  textColor: string | undefined,
  borderColor: string | undefined,
): Record<string, string> => {
  if (disabled)
    return {
      bgColor: theme.colors.gray2,
      bdColor: theme.colors.gray3,
      txtColor: theme.colors.gray4,
    };
  if (primary) {
    return {
      bgColor: theme.colors.salmon1,
      bdColor: theme.colors.salmon2,
      txtColor: theme.colors.white,
    };
  }
  if (backgroundColor && textColor)
    return {
      bgColor: backgroundColor,
      bdColor: borderColor ? borderColor : 'transparent',
      txtColor: textColor,
    };
  return {
    bgColor: theme.colors.white,
    bdColor: theme.colors.gray3,
    txtColor: theme.colors.black,
  };
};

export const getOpportunityStatusColors = (
  type: 'info' | 'danger' | 'success' | 'warning',
) => {
  if (type === 'info') {
    return {
      borderColor: '#BDD7EE',
      bgColor: '#BDD7EE',
      color: theme.colors.black,
    };
  }
  if (type === 'success') {
    return {
      borderColor: '#88AB75',
      bgColor: '#88AB75',
      color: theme.colors.black,
    };
  }

  if (type === 'danger') {
    return {
      borderColor: '#FBBDB7',
      bgColor: '#FBBDB7',
      color: theme.colors.black,
    };
  }
  if (type === 'warning') {
    return {
      borderColor: '#FCE4D6',
      bgColor: '#FCE4D6',
      color: theme.colors.black,
    };
  }

  return {
    borderColor: 'black',
    bgColor: 'white',
    color: 'black',
  };
};

export const scrollToTop = () => {
  window.scroll({ top: 0, left: 0, behavior: 'smooth' });
};
