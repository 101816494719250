import { FC, useEffect } from 'react';

import { SearchParamsProvider } from '../../context';
import { CasesList } from '../../container/admin';
import { useQueryParams } from '../../hooks/useQueryParams';
import { useAuth } from '../../hooks/useAuth';
import { isUserAllowed } from '../../utils/user';
import { Lawyer, Manager, Role } from '../../types/resources';
import { useApi } from '../../hooks/useApi';

export type CasesFilters = {
  search?: string;
  step_type?: string;
  step_status?: string;
  express?: boolean;
  premium?: boolean;
  tp?: boolean;
  not_ext?: boolean;
  manager_id?: string;
  lawyer_id?: string;
  signed_lawyer_fee?: 'COMPLETE' | 'PARTIAL' | 'INCOMPLETE';
  payment?: 'COMPLETE' | 'PARTIAL';
  consents?: 'RECEIVED' | 'UNRECEIVED' | 'P_RECEIVED' | 'NOT_CONCERNED';
  letters?: 'RECEIVED' | 'UNRECEIVED' | 'NOT_CONCERNED' | 'NEGLECTED';
  first_doc_validation?: boolean;
  other_doc_validation?: boolean;
  sort: string;
  direction: string;
  page?: number;
  has_unprocessed_messages?: boolean;
  has_unprocessed_comments?: boolean;
  not_express?: boolean;
  trad?: boolean;
  av?: boolean;
  csl?: boolean;
  bi?: boolean;
  appointment_from?: string;
  appointment_to?: string;
  columns: string[];
};

const Cases: FC = () => {
  const query = useQueryParams();
  const { user } = useAuth();

  const lawyerId = isUserAllowed(user?.role, [Role.ADMIN])
    ? query.get('lawyer_id')
    : isUserAllowed(user?.role, [Role.LAWYER])
    ? user?.id
    : undefined;

  const managerId = isUserAllowed(user?.role, [Role.ADMIN])
    ? query.get('manager_id')
    : isUserAllowed(user?.role, [Role.MANAGER])
    ? user?.id
    : undefined;

  const { execute: getLawyers, state: getLawyersState } =
    useApi<Lawyer[]>(`/lawyers`);

  const { execute: getManagers, state: getManagersState } =
    useApi<Manager[]>(`/managers`);

  useEffect(() => {
    getLawyers();
    getManagers();
  }, []);

  return (
    <SearchParamsProvider<CasesFilters> arrayKeys={['columns']}>
      <CasesList
        lawyerId={lawyerId}
        managerId={managerId}
        lawyers={getLawyersState.data?.value}
        managers={getManagersState.data?.value}
      />
    </SearchParamsProvider>
  );
};

export default Cases;
