import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InputText } from 'components/FormTemplate/Fields/InputText';
import { theme } from 'theme';
import { useSearchParamsContext } from '../../../context';
import { CasesFilters } from '../../../pages/admin/Cases';

const SearchOpportunity: FC = () => {
  const { t } = useTranslation();
  const { filters, updateFilters } = useSearchParamsContext<CasesFilters>();

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    updateFilters({ search: e.target.value });
  };

  return (
    <InputText
      onChange={handleSearchChange}
      labelUppercase={true}
      labelFontStyle="label"
      placeholder={t('filter.search_opportunity')}
      width="100%"
      labelWeight="bold"
      $size="small"
      value={filters.search || ''}
      icon={{ name: 'Search', primaryColor: theme.colors.salmon1 }}
      debounceDelay={500}
    />
  );
};

export default SearchOpportunity;
