import { FC, useState } from 'react';
import styled from 'styled-components';

import { theme } from 'theme';

import { Text } from 'components/Layout';
import { IconButton } from './IconButton';
import { IconProps } from 'components/Images/Icon';

const Button = styled.button`
  display: flex;
  align-self: flex-start;
  align-items: center;
  cursor: pointer;
  border: none;
  background: transparent;
`;

const SubMenuButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  background: transparent;
  padding: ${theme.spacing.space8} 0;
  margin-left: ${theme.spacing.space24};
`;

interface MenuButtonProps {
  onClick?: () => void;
  content: string;
  iconName?: IconProps['name'];
  active?: boolean;
  isCategory?: boolean;
  isSubMenuItem?: boolean;
  isOpened?: boolean;
}

const MenuButton: FC<MenuButtonProps> = ({
  onClick,
  content,
  iconName,
  active = false,
  isCategory = false,
  isSubMenuItem = false,
  isOpened,
}) => {
  const [hoverState, setHoverState] = useState(false);

  const getColor = () => {
    if (active) return theme.colors.salmon1;
    return theme.colors.green1;
  };

  const getBackgoundColor = () => {
    if (active) return theme.colors.white;
    if (hoverState) return theme.colors.green2;
    return theme.colors.gray3;
  };

  const color = getColor();
  const backgroundColor = getBackgoundColor();

  if (isCategory) {
    return (
      <Button
        onClick={onClick}
        onMouseEnter={() => setHoverState(true)}
        onMouseLeave={() => setHoverState(false)}
      >
        {iconName && (
          <IconButton
            iconName={iconName}
            color={color}
            backgroundColor={backgroundColor}
          />
        )}
        <Text
          content={content}
          fontStyle="body3"
          color={theme.colors.white}
          decoration="none"
          weight={hoverState || active ? 'bold' : 'medium'}
          marginLeft={{ xs: 'space8' }}
        />
        <IconButton
          iconName={isOpened ? 'ChevronUpCircle' : 'ChevronDownCircle'}
          color={theme.colors.white}
          backgroundColor={theme.colors.green1}
        />
      </Button>
    );
  }

  if (isSubMenuItem) {
    return (
      <SubMenuButton
        onClick={onClick}
        onMouseEnter={() => setHoverState(true)}
        onMouseLeave={() => setHoverState(false)}
      >
        {iconName && (
          <IconButton
            iconName={iconName}
            color={color}
            backgroundColor={backgroundColor}
          />
        )}
        <Text
          fontStyle="body3"
          content={content}
          color={theme.colors.white}
          marginLeft={{ xs: 'space8' }}
        />
      </SubMenuButton>
    );
  }

  return (
    <Button
      onClick={onClick}
      onMouseEnter={() => setHoverState(true)}
      onMouseLeave={() => setHoverState(false)}
    >
      {iconName && (
        <IconButton
          iconName={iconName}
          color={color}
          backgroundColor={backgroundColor}
        />
      )}
      <Text
        content={content}
        fontStyle="body3"
        color={theme.colors.white}
        weight={hoverState || active ? 'bold' : 'medium'}
        marginLeft={{ xs: 'space8' }}
      />
    </Button>
  );
};

export default MenuButton;
