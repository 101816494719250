import { FC, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import { Button } from 'components/Buttons/Button';
import Table from 'components/Table/Table';
import { Flex } from 'components/Layout/Flex';

import { AdminAppRoutes } from 'AdminApp';
import { getRouteWithParams } from 'utils/router';
import { Icon } from '../../../components/Images';
import { theme } from '../../../theme';
import { Customer, Role } from '../../../types/resources';
import { ExecuteFunction } from '../../../hooks/useFetch';
import { ColumnDef, OnChangeFn, SortingState } from '@tanstack/react-table';
import { useSearchParamsContext } from '../../../context';
import { CustomersFilters } from '../../../pages/admin/Customers';
import { parseInt } from 'lodash';
import { useAuth } from 'hooks/useAuth';

type CustomersListProps = {
  pageSize: number;
  fetch: ExecuteFunction<Customer[]>;
  total: number;
  items: Customer[];
};

const CustomersList: FC<CustomersListProps> = ({
  pageSize,
  fetch,
  items,
  total,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { loginAsUser, user, isLoading } = useAuth();

  const {
    filters: currentFilters,
    updateFilters,
    removeFilter,
    getFilter,
  } = useSearchParamsContext<CustomersFilters>();

  useEffect(() => {
    fetch({
      query: {
        items: pageSize,
        sort: currentFilters.sort,
        direction: currentFilters.direction,
        search: currentFilters.search || undefined,
        page: currentFilters.page || 1,
      },
    });
  }, [currentFilters]);

  const columns = useMemo<ColumnDef<Customer>[]>(
    () => [
      {
        header: t('customer.last_name'),
        accessorKey: 'last_name',
        size: 125,
      },
      {
        header: t('customer.first_name'),
        accessorKey: 'first_name',
        size: 125,
      },
      {
        header: t('customer.email'),
        accessorKey: 'email',
        size: 200,
      },
      {
        header: t('customer.activated'),
        accessorKey: 'activated',
        size: 80,
        cell: ({ row }) => (
          <Icon
            name={row.original.activated ? 'check' : 'close'}
            fill="none"
            primaryColor={
              row.original.activated ? theme.colors.green3 : theme.colors.red1
            }
          />
        ),
      },
      {
        header: '',
        accessorKey: 'id',
        size: 100,
        enableSorting: false,
        cell: ({ row }) => (
          <Flex justify="end" expand gap={{ xs: 'space12' }}>
            <Button
              content={t('view')}
              size="small"
              primary
              onClick={() =>
                navigate(
                  getRouteWithParams(AdminAppRoutes.CUSTOMERS_DETAILS, {
                    id: row.original.id,
                  }),
                )
              }
            />
            {user?.role === Role.ADMIN && (
              <Button
                disabled={!row.original.activated}
                content="Se connecter"
                type="button"
                size="small"
                $loading={isLoading}
                onClick={() =>
                  loginAsUser({
                    email: row.original.email,
                    id: row.original.id,
                    onSuccess: () => {
                      navigate('/');
                    },
                    onError: (err: any) => {
                      const errText =
                        err?.response?.status === 429
                          ? t('signin.errors.too_many_attempts')
                          : t('signin.errors.unauthorized');
                      console.error(errText);
                    },
                  })
                }
              />
            )}
          </Flex>
        ),
      },
    ],
    [history],
  );

  const currentSorting: SortingState = (() => {
    const sortColumn = getFilter('sort') as string;
    const sortDirection = getFilter('direction') as string;

    if (sortColumn) {
      return [
        {
          id: sortColumn,
          desc: sortDirection === 'desc',
        },
      ];
    }
    return [];
  })();

  const handleSortingChange: OnChangeFn<SortingState> = useCallback(
    (updaterOrValue) => {
      const updatedSorting = Array.isArray(updaterOrValue)
        ? updaterOrValue
        : updaterOrValue([]);

      try {
        const sortInfo = updatedSorting[0];

        if (sortInfo) {
          updateFilters({
            sort: sortInfo.id,
            direction: sortInfo.desc ? 'desc' : 'asc',
          });
        }
      } catch (error) {
        console.error('Erreur lors du tri:', error);
      }
    },
    [updateFilters],
  );

  return (
    <Table
      data={items}
      columns={columns}
      onPaginationChange={(pageIndex) => updateFilters({ page: pageIndex })}
      pageCount={Math.ceil((total || 0) / pageSize)}
      pagination={{
        pageIndex: getFilter('page')
          ? parseInt(getFilter('page') as string)
          : 1,
        pageSize: pageSize,
      }}
      sorting={currentSorting}
      // onRowClick={(item) => {
      //   navigate(
      //     getRouteWithParams(AdminAppRoutes.CUSTOMERS_DETAILS, {
      //       id: item.id as string,
      //     }),
      //   );
      // }}
      onSortingChange={handleSortingChange}
    />
  );
};

export default CustomersList;
