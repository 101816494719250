import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Flex, Text } from 'components/Layout';

import { CustomIcon } from 'theme/styles/icons';
import { IconlyIcon } from 'types/react-iconly';
import { OpportunityStatusButton } from 'components/Buttons';
import Table from 'components/Table/Table';
import config from 'config/app';
import { format } from 'date-fns';
import { getInitialName } from 'utils/getter';
import { getTypeByStatus } from 'utils/status';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useApi, useApiAsync } from '../../../hooks/useApi';
import {
  Lawyer,
  Manager,
  ProcedureFull,
  ProcedureStepStatus,
  ProcedureStepType,
} from '../../../types/resources';
import { Icon } from '../../../components/Images';
import { theme } from '../../../theme';
import CasesListOptionsFilter from '../filters/CasesListOptionsFilter';
import { ColumnDef, OnChangeFn, SortingState } from '@tanstack/react-table';
import FilterSystem from '../filters/ProceduresListFilters';
import { FilterGroup, FilterOption } from '../../../types/filters';
import {
  CalendarDays,
  CheckCircle2,
  Clock,
  FileText,
  Filter,
  Grid2x2,
  Loader2,
  Mail,
  Settings2,
  SquareCheck,
  Wallet,
  XCircle,
  Signature,
} from 'lucide-react';
import { formatInputSelect } from '../../../utils/format';
import { useSearchParamsContext } from '../../../context';
import { CasesFilters } from '../../../pages/admin/Cases';
import { AdminAppRoutes } from '../../../AdminApp';
import { getRouteWithParams } from '../../../utils/router';
import { useNavigate } from 'react-router-dom';
import ColumnSelector from '../../../components/Table/ColumnSelector';
import { parseInt } from 'lodash';
import GroupedActions from 'components/Table/GroupedActions';
import { Modal } from 'components/Modal';
import { useDownloadOpportunity } from '../../../utils/downloadFile';
import { ConfirmOptions, useConfirm } from 'hooks/useConfirm';
import ExportCSVButton from 'components/Table/ExportCSVButton';
import OpportunityStep from 'components/Table/OpportunityStatus';
import SendMessageModal from 'components/Table/SendMessageModal';

export interface OpportunitiesProps {
  setRowsSelected?: (rowsSelected: string[]) => void;
  showFooter?: boolean;
  toggleModal?: (state: boolean) => void;
  managerId?: string | null;
  lawyerId?: string | null;
  footerIcon?: keyof typeof IconlyIcon | keyof typeof CustomIcon;
  footerText?: string;
  lawyers?: Lawyer[];
  managers?: Manager[];
}

type ProcedureProgressStatus = 'pending' | 'processing' | 'completed' | 'error';

type ProcedureProgress = {
  id: string;
  reference: string;
  name: string;
  status: ProcedureProgressStatus;
  error?: string;
};

type GroupedActions =
  | 'SYNC_YOP'
  | 'DOWNLOAD'
  | 'SEND_MESSAGE'
  | 'MOVE_TO_APPOINTMENT_IN_PROGRESS'
  | 'MOVE_TO_VERIFICATION_IN_REVIEW';

type ProgressState = {
  total: number;
  current: number;
  currentItem: string;
  procedures: ProcedureProgress[];
};

const TextEllipsis = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CasesList: FC<OpportunitiesProps> = ({
  lawyerId,
  managerId,
  lawyers,
  managers,
}) => {
  const { t } = useTranslation();
  const isProcessingFilterRef = useRef(false);
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState<ProcedureFull[]>([]);
  const [isProgressOpen, setIsProgressOpen] = useState(false);
  const [isSendMessageModalOpen, setIsSendMessageModalOpen] = useState(false);

  const [progress, setProgress] = useState<ProgressState>({
    total: 0,
    current: 0,
    currentItem: '',
    procedures: [],
  });
  const {
    filters: currentFilters,
    updateFilters,
    removeFilter,
    getFilter,
    toggleFilter,
  } = useSearchParamsContext<CasesFilters>();

  const pageSize = 15;

  const { onDownload, loading } = useDownloadOpportunity();

  const { confirm, ConfirmationDialog, isLoading } = useConfirm(async (data) =>
    handleGroupedActions(data),
  );

  const { execute: getCases, state: getCasesState } =
    useApi<ProcedureFull[]>(`/procedures`);
  const cases = getCasesState.data?.value ?? [];
  const total = getCasesState.data?.pagination?.total ?? 0;

  const { execute: syncYoProcess } = useApiAsync(
    `/procedures/id/sync/yo-process`,
    { method: 'POST' },
  );

  const { execute: updateStep } = useApiAsync(
    `/procedures/:procedure_id/steps/:step`,
    { method: 'PATCH' },
  );

  const fetchData = () => {
    getCases({
      query: {
        search: currentFilters.search || undefined,
        step_type: currentFilters.step_type,
        step_status: currentFilters.step_status,
        manager_id: currentFilters.manager_id || managerId || undefined,
        lawyer_id: currentFilters.lawyer_id || lawyerId || undefined,
        has_unprocessed_comments: currentFilters.has_unprocessed_comments,
        has_unprocessed_messages: currentFilters.has_unprocessed_messages,
        page: currentFilters.page || 1,
        items: pageSize,
        sort: currentFilters.sort,
        direction: currentFilters.direction,
        express: currentFilters.express,
        not_express: currentFilters.not_express,
        premium: currentFilters.premium,
        tp: currentFilters.tp,
        not_ext: currentFilters.not_ext,
        signed_lawyer_fee: currentFilters.signed_lawyer_fee,
        consents: currentFilters.consents,
        letters: currentFilters.letters,
        payment: currentFilters.payment,
        first_doc_validation: currentFilters.first_doc_validation,
        other_doc_validation: currentFilters.other_doc_validation,
        trad: currentFilters.trad,
        av: currentFilters.av,
        csl: currentFilters.csl,
        bi: currentFilters.bi,
        appointment_from: currentFilters.appointment_from,
        appointment_to: currentFilters.appointment_to,
      },
      onError: () => console.log('error'),
      onSuccess: (data) => {
        console.log(data);
        const totalPages = Math.ceil((data.pagination?.total || 0) / pageSize);
        const currentPage = currentFilters.page ? currentFilters.page : 1;

        if (currentPage > totalPages && totalPages > 0) {
          updateFilters({ page: totalPages });
        }
      },
    });
  };

  useEffect(() => {
    if (isProcessingFilterRef.current) return;

    fetchData();
  }, [
    currentFilters.search,
    currentFilters.step_type,
    currentFilters.step_status,
    currentFilters.manager_id,
    currentFilters.lawyer_id,
    currentFilters.has_unprocessed_messages,
    currentFilters.has_unprocessed_comments,
    currentFilters.page,
    currentFilters.sort,
    currentFilters.direction,
    currentFilters.express,
    currentFilters.not_express,
    currentFilters.premium,
    currentFilters.tp,
    currentFilters.not_ext,
    currentFilters.signed_lawyer_fee,
    currentFilters.consents,
    currentFilters.letters,
    currentFilters.payment,
    currentFilters.first_doc_validation,
    currentFilters.other_doc_validation,
    currentFilters.trad,
    currentFilters.av,
    currentFilters.csl,
    currentFilters.bi,
    currentFilters.appointment_from,
    currentFilters.appointment_to,
  ]);

  const columns = useMemo<ColumnDef<ProcedureFull>[]>(
    () => [
      {
        header: t('opportunity.columns.reference') || 'Référence',
        accessorKey: 'reference',
        id: 'reference',
        size: 80,
        enableSorting: false,
        cell: ({ row }) => (
          <TextEllipsis
            fontStyle="body2"
            content={row.original.reference.toString()}
            weight="medium"
          />
        ),
      },
      {
        header: t('opportunity.columns.spouse_leader') || 'Conjoint 1',
        accessorKey: 'spouse1',
        id: 'spouse1',
        enableSorting: false,
        size: 106,
        cell: ({ row }) => (
          <Text
            fontStyle="body2"
            content={
              row.original.spouse1
                ? `${row.original.spouse1?.first_name?.[0]}. ${row.original.spouse1?.last_name}`
                : ''
            }
            weight="medium"
            style={{
              wordWrap: 'break-word',
              width: '100%',
            }}
          />
        ),
      },
      {
        header: t('opportunity.columns.spouse_follower') || 'Conjoint 2',
        accessorKey: 'spouse2',
        id: 'spouse2',
        enableSorting: false,
        size: 106,
        cell: ({ row }) => (
          <Text
            fontStyle="body2"
            content={
              row.original.spouse2
                ? `${row.original.spouse2?.first_name?.[0]}. ${row.original.spouse2?.last_name}`
                : ''
            }
            weight="medium"
            style={{
              wordWrap: 'break-word',
              width: '100%',
            }}
          />
        ),
      },
      {
        header: t('opportunity.columns.step') || 'Étape du dossier',
        id: 'step.type',
        accessorKey: 'current_step',
        enableSorting: false,
        cell: ({ row }) => (
          <OpportunityStep
            step={row.original.step}
            text={t(`opportunity.steps.${row.original.step}`)}
            className="!tw-normal-case !tw-rounded-lg !tw-px-4"
          />
        ),
      },
      {
        header: t('opportunity.columns.step_status') || 'Statut',
        id: 'step.status',
        accessorKey: 'current_step',
        enableSorting: false,
        size: 145,
        cell: ({ row }) => (
          <OpportunityStatusButton
            type={getTypeByStatus(row.original.current_step.status)}
            text={t(`enums:${row.original.current_step.status}`)}
            className="!tw-normal-case !tw-rounded-lg !tw-px-4"
          />
        ),
      },
      {
        header: t('opportunity.columns.created_at') || 'Création',
        accessorKey: 'created_at',
        id: 'created_at',
        size: 114,
        cell: ({ row }) => (
          <TextEllipsis
            fontStyle="body2"
            content={format(
              new Date(row.original.created_at),
              config.dateFormat,
            )}
            weight="medium"
          />
        ),
      },
      {
        header: t('opportunity.columns.updated_at') || 'Dernière action',
        accessorKey: 'last_step_updated_at',
        id: 'last_step_updated_at',
        cell: ({ row }) => (
          <Text
            fontStyle="body2"
            content={format(
              new Date(row.original.last_step_updated_at),
              'dd/MM/yyyy HH:mm',
            )}
            weight="medium"
          />
        ),
      },
      {
        header: t('opportunity.columns.appointment_date') || 'Date RDV',
        accessorKey: 'appointment_date',
        id: 'appointment_date',
        cell: ({ row }) => (
          <Text
            fontStyle="body2"
            content={
              row.original.appointment_date
                ? format(new Date(row.original.appointment_date), 'dd/MM/yyyy')
                : ''
            }
            weight="medium"
          />
        ),
        enableHiding: true,
      },
      {
        header: t('opportunity.columns.manager') || 'Gestionnaire',
        accessorKey: 'manager',
        id: 'manager',
        size: 127,
        enableSorting: false,
        cell: ({ row }) => (
          <TextEllipsis
            fontStyle="body2"
            content={
              row.original.manager
                ? getInitialName(row.original.manager?.full_name)
                : undefined
            }
            weight="medium"
          />
        ),
      },
      {
        header: 'Avocat 1',
        accessorKey: 'lawyer1',
        id: 'lawyer1',
        size: 127,
        enableSorting: false,
        enableHiding: true,
        cell: ({ row }) => (
          <TextEllipsis
            fontStyle="body2"
            content={
              row.original.lawyer1
                ? getInitialName(row.original.lawyer1?.full_name)
                : undefined
            }
            weight="medium"
          />
        ),
      },
      {
        header: 'Avocat 2',
        accessorKey: 'lawyer2',
        id: 'lawyer2',
        size: 127,
        enableSorting: false,
        enableHiding: true,
        cell: ({ row }) => (
          <TextEllipsis
            fontStyle="body2"
            content={
              row.original.lawyer2
                ? getInitialName(row.original.lawyer2?.full_name)
                : undefined
            }
            weight="medium"
          />
        ),
      },
      {
        header: t('opportunity.columns.express') || 'Express',
        accessorKey: 'express',
        id: 'express',
        size: 100,
        cell: ({ row }) => (
          <Icon
            name={row.original.express ? 'check' : 'close'}
            fill="none"
            primaryColor={
              row.original.express ? theme.colors.green3 : theme.colors.red1
            }
          />
        ),
      },
      {
        header: t('opportunity.columns.premium') || 'Premium',
        accessorKey: 'premium',
        id: 'premium',
        size: 100,
        cell: ({ row }) => (
          <Icon
            name={row.original.premium ? 'check' : 'close'}
            fill="none"
            primaryColor={
              row.original.premium ? theme.colors.green3 : theme.colors.red1
            }
          />
        ),
      },
      {
        header: t('opportunity.columns.not_ext') || 'Notaire Extérieur',
        accessorKey: 'not_ext',
        id: 'not_ext',
        size: 100,
        cell: ({ row }) => (
          <Icon
            name={row.original.not_ext ? 'check' : 'close'}
            fill="none"
            primaryColor={
              row.original.not_ext ? theme.colors.green3 : theme.colors.red1
            }
          />
        ),
        enableHiding: true,
      },
      {
        header: t('opportunity.columns.bi') || 'Bi non payés',
        accessorKey: 'bi',
        id: 'bi',
        size: 100,
        cell: ({ row }) => (
          <Icon
            name={row.original.bi ? 'check' : 'close'}
            fill="none"
            primaryColor={
              row.original.bi ? theme.colors.green3 : theme.colors.red1
            }
          />
        ),
        enableHiding: true,
      },
      {
        header: t('opportunity.columns.trad') || 'Trad',
        accessorKey: 'trad',
        id: 'trad',
        size: 100,
        cell: ({ row }) => (
          <Icon
            name={row.original.trad ? 'check' : 'close'}
            fill="none"
            primaryColor={
              row.original.trad ? theme.colors.green3 : theme.colors.red1
            }
          />
        ),
        enableHiding: true,
      },
      {
        header: t('opportunity.columns.av') || 'AV',
        accessorKey: 'av',
        id: 'av',
        size: 100,
        cell: ({ row }) => (
          <Icon
            name={row.original.av ? 'check' : 'close'}
            fill="none"
            primaryColor={
              row.original.av ? theme.colors.green3 : theme.colors.red1
            }
          />
        ),
        enableHiding: true,
      },
      {
        header: t('opportunity.columns.csl') || 'CSL',
        accessorKey: 'csl',
        id: 'csl',
        size: 100,
        cell: ({ row }) => (
          <Icon
            name={row.original.csl ? 'check' : 'close'}
            fill="none"
            primaryColor={
              row.original.csl ? theme.colors.green3 : theme.colors.red1
            }
          />
        ),
        enableHiding: true,
      },
      {
        header: t('opportunity.columns.tp') || 'Trésor Public',
        accessorKey: 'tp',
        id: 'tp',
        size: 100,
        cell: ({ row }) => (
          <Icon
            name={row.original.tp ? 'check' : 'close'}
            fill="none"
            primaryColor={
              row.original.tp ? theme.colors.green3 : theme.colors.red1
            }
          />
        ),
        enableHiding: true,
      },
      {
        header:
          t('opportunity.columns.lawyer_fees_status') ||
          "Convention d'honoraires",
        accessorKey: 'lawyer_fees_status',
        id: 'lawyer_fees_status',
        size: 100,
        enableHiding: true,
        cell: ({ row }) =>
          t(`opportunity.statuses.${row.original.lawyer_fees_status}`),
      },
      {
        header: t('opportunity.columns.payment_status') || 'Paiement',
        accessorKey: 'payment_status',
        id: 'payment_status',
        size: 100,
        enableHiding: true,
        cell: ({ row }) =>
          t(`opportunity.statuses.${row.original.payment_status}`),
      },
      {
        header: t('opportunity.columns.consent_status') || 'Consentements',
        accessorKey: 'consent_status',
        id: 'consent_status',
        size: 100,
        enableHiding: true,
        cell: ({ row }) =>
          t(`opportunity.statuses.${row.original.consent_status}`),
      },
      {
        header: t('opportunity.columns.letter_status') || 'Recommandés',
        accessorKey: 'letter_status',
        id: 'letter_status',
        size: 100,
        enableHiding: true,
        cell: ({ row }) =>
          t(`opportunity.statuses.${row.original.letter_status}`),
      },
    ],
    [history],
  );

  const [visibleColumnsState, setVisibleColumnsState] = useState(() => {
    const initialVisibility: { [key: string]: boolean } = {};
    columns
      .filter((f) => !f.enableHiding)
      .forEach((column) => {
        initialVisibility[column.id as string] = true;
      });

    const columnsInUrl = getFilter('columns') as string[];

    if (columnsInUrl && columnsInUrl.length > 0) {
      columnsInUrl.forEach((column) => {
        initialVisibility[column] = true;
      });
    }

    return initialVisibility;
  });

  useEffect(() => {
    const columnsInUrl = (getFilter('columns') as string[]) || [];

    const newVisibleColumns = { ...visibleColumnsState };

    columns.forEach((column) => {
      const columnId = column.id as string;
      if (columnsInUrl.includes(columnId)) {
        newVisibleColumns[columnId] = true;
      }
    });

    setVisibleColumnsState(newVisibleColumns);
  }, []);

  const currentSorting: SortingState = (() => {
    const sortColumn = getFilter('sort') as string;
    const sortDirection = getFilter('direction') as string;

    if (sortColumn) {
      return [
        {
          id: sortColumn,
          desc: sortDirection === 'desc',
        },
      ];
    }
    return [];
  })();

  useEffect(() => {
    if (
      getFilter('step_type') === ProcedureStepType.ARCHIVE_SIGN &&
      getFilter('step_status') === ProcedureStepStatus.IN_PROGRESS &&
      !visibleColumnsState['appointment_date']
    ) {
      isProcessingFilterRef.current = true;

      handleVisibilityChange('appointment_date', true);

      setTimeout(() => {
        isProcessingFilterRef.current = false;
      }, 0);
    }

    if (getFilter('payment') && !visibleColumnsState['payment_status']) {
      isProcessingFilterRef.current = true;

      handleVisibilityChange('payment_status', true);

      setTimeout(() => {
        isProcessingFilterRef.current = false;
      }, 0);
    }

    if (getFilter('consents') && !visibleColumnsState['consent_status']) {
      isProcessingFilterRef.current = true;

      handleVisibilityChange('consent_status', true);

      setTimeout(() => {
        isProcessingFilterRef.current = false;
      }, 0);
    }

    if (getFilter('letters') && !visibleColumnsState['letter_status']) {
      isProcessingFilterRef.current = true;

      handleVisibilityChange('letter_status', true);

      setTimeout(() => {
        isProcessingFilterRef.current = false;
      }, 0);
    }

    if (
      getFilter('lawyer_id') &&
      !visibleColumnsState['lawyer1'] &&
      !visibleColumnsState['lawyer2']
    ) {
      isProcessingFilterRef.current = true;

      handleVisibilityChange('lawyer1', true);
      handleVisibilityChange('lawyer2', true);

      setTimeout(() => {
        isProcessingFilterRef.current = false;
      }, 0);
    }

    if (getFilter('av') && !visibleColumnsState['av']) {
      isProcessingFilterRef.current = true;

      handleVisibilityChange('av', true);

      setTimeout(() => {
        isProcessingFilterRef.current = false;
      }, 0);
    }

    if (getFilter('trad') && !visibleColumnsState['trad']) {
      isProcessingFilterRef.current = true;

      handleVisibilityChange('trad', true);

      setTimeout(() => {
        isProcessingFilterRef.current = false;
      }, 0);
    }

    if (getFilter('bi') && !visibleColumnsState['bi']) {
      isProcessingFilterRef.current = true;

      handleVisibilityChange('bi', true);

      setTimeout(() => {
        isProcessingFilterRef.current = false;
      }, 0);
    }

    if (getFilter('csl') && !visibleColumnsState['csl']) {
      isProcessingFilterRef.current = true;

      handleVisibilityChange('csl', true);

      setTimeout(() => {
        isProcessingFilterRef.current = false;
      }, 0);
    }

    if (getFilter('not_ext') && !visibleColumnsState['not_ext']) {
      isProcessingFilterRef.current = true;

      handleVisibilityChange('not_ext', true);

      setTimeout(() => {
        isProcessingFilterRef.current = false;
      }, 0);
    }

    if (getFilter('tp') && !visibleColumnsState['tp']) {
      isProcessingFilterRef.current = true;

      handleVisibilityChange('tp', true);

      setTimeout(() => {
        isProcessingFilterRef.current = false;
      }, 0);
    }
  }, [
    currentFilters.step_status,
    currentFilters.step_type,
    currentFilters.payment,
    currentFilters.consents,
    currentFilters.letters,
    currentFilters.lawyer_id,
    currentFilters.av,
    currentFilters.trad,
    currentFilters.bi,
    currentFilters.csl,
    currentFilters.not_ext,
    currentFilters.tp,
    visibleColumnsState,
  ]);

  useEffect(() => {
    setSelectedRows([]);
  }, [currentFilters.step_type, currentFilters.step_status]);

  const filters: FilterGroup[] = [
    {
      id: 'step_type',
      icon: <Grid2x2 size={16} />,
      label: t('opportunity.columns.step'),
      type: 'checkbox',
      options: Object.values(ProcedureStepType).map((type) => ({
        label: t(`enums:${type}`),
        value: type,
      })),
    },
    {
      id: 'step_status',
      icon: <SquareCheck size={16} />,
      label: t('opportunity.columns.step_status'),
      type: 'checkbox',
      options: formatInputSelect(ProcedureStepStatus) as FilterOption[],
    },
    {
      id: 'other',
      icon: <Settings2 size={16} />,
      label: 'Autres caractéristiques',
      type: 'checkbox',
      multi: true,
      options: [
        { label: 'Premium', value: 'premium' },
        { label: 'Express', value: 'express' },
        { label: 'Non express', value: 'not_express' },
        { label: 'Bi non payés', value: 'bi' },
        { label: 'TRAD', value: 'trad' },
        { label: 'AV', value: 'av' },
        { label: 'CSL', value: 'csl' },
        { label: 'Notaire Exterieur', value: 'not_ext' },
        { label: '1ère validation doc', value: 'first_doc_validation' },
        { label: '2nd et + validation doc', value: 'other_doc_validation' },
        { label: 'Messages non traités', value: 'has_unprocessed_messages' },
        {
          label: 'Commentaires non traités',
          value: 'has_unprocessed_comments',
        },
      ],
    },
    {
      id: 'signed_lawyer_fee',
      icon: <FileText size={16} />,
      label: "Convention d'honoraires",
      type: 'checkbox',
      options: [
        { label: 'Signé', value: 'COMPLETE' },
        { label: 'Partiellement signé', value: 'PARTIAL' },
        { label: 'Pas signé', value: 'INCOMPLETE' },
      ],
    },
    {
      id: 'payment',
      icon: <Wallet size={16} />,
      label: 'Paiement',
      type: 'checkbox',
      options: [
        { label: 'Payé', value: 'COMPLETE' },
        { label: 'Partiellement payé', value: 'PARTIAL' },
      ],
    },
    {
      id: 'consents',
      icon: <Signature size={16} />,
      label: 'Consentement',
      type: 'checkbox',
      options: [
        { label: 'Réceptionnés', value: 'RECEIVED' },
        { label: 'Non réceptionnés', value: 'UNRECEIVED' },
        { label: 'Partiellement réceptionnés', value: 'P_RECEIVED' },
        { label: 'Non-concerné', value: 'NOT_CONCERNED' },
      ],
    },
    {
      id: 'letters',
      icon: <Mail size={16} />,
      label: 'Recommandés',
      type: 'checkbox',
      options: [
        { label: 'Réceptionnés', value: 'RECEIVED' },
        { label: 'Partiellement réceptionnés', value: 'P_RECEIVED' },
        { label: 'Non réceptionnés', value: 'UNRECEIVED' },
        { label: 'Négligé', value: 'NEGLECTED' },
      ],
    },
    {
      id: 'appointment',
      icon: <CalendarDays size={16} />,
      label: 'Rendez-vous',
      type: 'date',
    },
    {
      id: 'manager_id',
      icon: <Filter size={16} />,
      label: t('opportunity.columns.manager'),
      type: 'checkbox',
      options: managers?.map((managers) => ({
        label: managers.full_name,
        value: managers.id,
      })),
    },
    {
      id: 'lawyer_id',
      icon: <Filter size={16} />,
      label: t('opportunity.columns.lawyer'),
      type: 'checkbox',
      options: lawyers?.map((lawyer) => ({
        label: lawyer.full_name,
        value: lawyer.id,
      })),
    },
  ];

  const handleVisibilityChange = (columnId: string, isVisible: boolean) => {
    setVisibleColumnsState((prev) => ({
      ...prev,
      [columnId]: isVisible,
    }));

    const currentColumnsInUrl = (getFilter('columns') as string[]) || [];

    if (isVisible && !currentColumnsInUrl.includes(columnId)) {
      const params = new URLSearchParams(window.location.search);
      params.append('columns', columnId);
      window.history.replaceState(
        null,
        '',
        `${window.location.pathname}?${params.toString()}`,
      );
    } else if (!isVisible && currentColumnsInUrl.includes(columnId)) {
      const params = new URLSearchParams(window.location.search);
      params.delete('columns');
      currentColumnsInUrl
        .filter((c) => c !== columnId)
        .forEach((c) => params.append('columns', c));
      window.history.replaceState(
        null,
        '',
        `${window.location.pathname}?${params.toString()}`,
      );
    }
  };

  const handleSortingChange: OnChangeFn<SortingState> = useCallback(
    (updaterOrValue) => {
      const updatedSorting = Array.isArray(updaterOrValue)
        ? updaterOrValue
        : updaterOrValue([]);

      try {
        const sortInfo = updatedSorting[0];

        if (sortInfo) {
          updateFilters({
            sort: sortInfo.id,
            direction: sortInfo.desc ? 'desc' : 'asc',
          });
        }
      } catch (error) {
        console.error('Erreur lors du tri:', error);
      }
    },
    [updateFilters],
  );

  const handleGroupedActionsClick = async (action: GroupedActions) => {
    const options: ConfirmOptions = {
      title: t('opportunity.grouped_actions.confirm_modal.title'),
      description: t('opportunity.grouped_actions.confirm_modal.description'),
      confirmLabel: t('opportunity.grouped_actions.confirm_modal.confirmLabel'),
      cancelLabel: t('opportunity.grouped_actions.confirm_modal.cancelLabel'),
      dangerouslySetInnerHTML: true,
    };

    await confirm(options, action);
  };

  const handleGroupedActions = async (action: GroupedActions) => {
    if (action === 'SEND_MESSAGE') {
      return setIsSendMessageModalOpen(true);
    }

    setIsProgressOpen(true);
    const total = selectedRows.length;

    const initialProcedures = selectedRows.map((row) => ({
      id: row.id,
      reference: row.reference.toString(),
      name: row.name,
      status: 'pending' as ProcedureProgressStatus,
    }));

    setProgress({
      total,
      current: 0,
      currentItem: '',
      procedures: initialProcedures,
    });

    for (let i = 0; i < selectedRows.length; i++) {
      const procedure = selectedRows[i];
      setProgress((prev) => ({
        ...prev,
        current: i + 1,
        currentItem: `${procedure.reference} - ${procedure.name}`,
        procedures: prev.procedures.map((p) =>
          p.id === procedure.id ? { ...p, status: 'processing' } : p,
        ),
      }));
      try {
        switch (action) {
          case 'SYNC_YOP':
            await syncYoProcess({
              endpoint: `/procedures/${procedure.id}/sync/yo-process`,
            });
            break;
          case 'DOWNLOAD':
            await onDownload({
              opportunityId: procedure.id,
              filename: `${
                procedure.reference
              }-${procedure.name.toLowerCase()}`,
            });
            break;
          case 'MOVE_TO_APPOINTMENT_IN_PROGRESS':
            await updateStep({
              endpoint: `/procedures/${procedure.id}/steps/${ProcedureStepType.AGREEMENT_SENT}`,
              body: { status: ProcedureStepStatus.VALIDATED },
            });
            break;
          case 'MOVE_TO_VERIFICATION_IN_REVIEW':
            await updateStep({
              endpoint: `/procedures/${procedure.id}/steps/${ProcedureStepType.VERIFICATION}`,
              body: { status: ProcedureStepStatus.VALIDATED },
            });
            break;
        }
        setProgress((prev) => ({
          ...prev,
          procedures: prev.procedures.map((p) =>
            p.id === procedure.id ? { ...p, status: 'completed' } : p,
          ),
        }));
      } catch (error) {
        setProgress((prev) => ({
          ...prev,
          procedures: prev.procedures.map((p) =>
            p.id === procedure.id
              ? {
                  ...p,
                  status: 'error',
                  error:
                    error instanceof Error
                      ? error.message
                      : 'Une erreur est survenue',
                }
              : p,
          ),
        }));
      }

      await new Promise((resolve) => setTimeout(resolve, 500));
    }

    if (
      [
        'MOVE_TO_VERIFICATION_IN_REVIEW',
        'MOVE_TO_APPOINTMENT_IN_PROGRESS',
      ].includes(action)
    ) {
      fetchData();
      setSelectedRows([]);
    }
  };

  const allCompleted = progress.procedures.every(
    (p) => p.status === 'completed' || p.status === 'error',
  );

  const StatusIcon = ({ status }: { status: ProcedureProgressStatus }) => {
    switch (status) {
      case 'completed':
        return <CheckCircle2 className="tw-w-4 tw-h-4 tw-text-green-500" />;
      case 'error':
        return <XCircle className="tw-w-4 tw-h-4 tw-text-red-500" />;
      case 'processing':
        return (
          <Loader2 className="tw-w-4 tw-h-4 tw-animate-spin tw-text-primary" />
        );
      case 'pending':
        return <Clock className="tw-w-4 tw-h-4 tw-text-gray-400" />;
      default:
        return null;
    }
  };

  return (
    <>
      <Flex
        justify="between"
        direction={{ xs: 'column', sm: 'row' }}
        gap={{ xs: 'space16' }}
      >
        <Col xs={12} md={4}>
          <Text
            content={`${t('procedure.title')} (${total})`}
            fontStyle="heading2"
          />
        </Col>
      </Flex>

      <div className="tw-flex tw-gap-4 tw-justify-end tw-py-4 tw-divide-x">
        <div className="tw-flex tw-gap-4">
          <CasesListOptionsFilter />
          <ColumnSelector
            columns={columns}
            visibleColumns={visibleColumnsState}
            onVisibilityChange={handleVisibilityChange}
          />
        </div>
        <div className="tw-flex tw-gap-4 tw-pl-4">
          <ExportCSVButton
            columns={columns}
            visibleColumns={visibleColumnsState}
          />
          <GroupedActions
            disabled={selectedRows.length <= 0}
            buttons={[
              {
                label: "Passer les dossiers à l'étape Rendez-vous",
                onClick: () =>
                  handleGroupedActionsClick('MOVE_TO_APPOINTMENT_IN_PROGRESS'),
                hidden:
                  getFilter('step_type') !== ProcedureStepType.AGREEMENT_SENT ||
                  getFilter('step_status') !== ProcedureStepStatus.IN_REVIEW,
              },
              {
                label: 'Envoi en rédaction avocat',
                onClick: () =>
                  handleGroupedActionsClick('MOVE_TO_VERIFICATION_IN_REVIEW'),
                hidden:
                  getFilter('step_type') !== ProcedureStepType.VERIFICATION ||
                  getFilter('step_status') !== ProcedureStepStatus.IN_PROGRESS,
              },
              {
                label: 'Envoyer les données à YOP',
                onClick: () => handleGroupedActionsClick('SYNC_YOP'),
                hidden:
                  !getFilter('step_type') ||
                  getFilter('step_type') === ProcedureStepType.FORM,
              },
              {
                label: 'Télécharger les dossiers',
                onClick: () => handleGroupedActionsClick('DOWNLOAD'),
              },
              {
                label: 'Envoyer un message de groupe',
                onClick: () => handleGroupedActionsClick('SEND_MESSAGE'),
              },
            ]}
          />
        </div>
      </div>
      <div className="tw-border-t tw-pt-4">
        <FilterSystem filters={filters} />
      </div>
      <div className="tw-overflow-x-auto">
        <Table
          enableSelection
          loading={getCasesState.loading}
          data={cases || []}
          columns={columns.filter(
            (column) => visibleColumnsState[column.id as string],
          )}
          onPaginationChange={(pageIndex) => updateFilters({ page: pageIndex })}
          pageCount={Math.ceil(
            (getCasesState.data?.pagination?.total || 0) / pageSize,
          )}
          pagination={{
            pageIndex: getFilter('page')
              ? parseInt(getFilter('page') as string)
              : 1,
            pageSize: pageSize,
          }}
          sorting={currentSorting}
          onRowClick={(item) => {
            const route = currentFilters.has_unprocessed_messages
              ? AdminAppRoutes.OPPORTUNITY_MESSAGING
              : AdminAppRoutes.CASES_DETAILS;

            navigate(getRouteWithParams(route, { id: item.id as string }));
          }}
          onSortingChange={handleSortingChange}
          onRowSelectionChange={(rows) => setSelectedRows(rows)}
          selectedRows={selectedRows}
        />
      </div>
      <SendMessageModal
        isOpen={isSendMessageModalOpen}
        onClose={() => setIsSendMessageModalOpen(false)}
        selectedRows={selectedRows}
      />
      <ConfirmationDialog />
      <Modal
        opened={isProgressOpen}
        onClose={() => setIsProgressOpen(false)}
        width="640px"
        zIndex={13}
      >
        <div className="tw-flex tw-flex-col tw-gap-4">
          <div className="tw-flex tw-justify-between tw-items-center">
            <h3 className="tw-text-lg tw-font-semibold">
              {allCompleted ? 'Traitement terminé' : 'Traitement en cours...'}
            </h3>
            {allCompleted && (
              <button
                onClick={() => setIsProgressOpen(false)}
                className="tw-px-3 tw-py-2 tw-text-sm tw-bg-primary tw-rounded-md hover:tw-bg-primary/90 tw-transition tw-border tw-text-white"
              >
                Fermer
              </button>
            )}
          </div>

          <div className="tw-w-full tw-h-2 tw-bg-gray-100 tw-rounded-full tw-overflow-hidden">
            <div
              className="tw-h-full tw-bg-primary tw-transition-all tw-duration-300"
              style={{ width: `${(progress.current / progress.total) * 100}%` }}
            />
          </div>

          <span className="tw-text-sm tw-text-gray-500">
            Traitement {progress.current} sur {progress.total}
          </span>

          <div className="tw-max-h-96 tw-overflow-y-auto">
            {progress.procedures.map((procedure) => (
              <div
                key={procedure.id}
                className="tw-flex tw-flex-col tw-py-2 tw-items-start tw-border-b tw-border-gray-100 last:tw-border-0"
              >
                <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
                  <StatusIcon status={procedure.status} />
                  <span className="tw-font-medium">
                    {procedure.reference} - {procedure.name}
                  </span>
                  <span className="tw-text-sm tw-text-gray-500">
                    {procedure.status === 'pending' && 'En attente'}
                    {procedure.status === 'processing' && 'En cours'}
                    {procedure.status === 'completed' && 'Terminé'}
                    {procedure.status === 'error' && 'Erreur'}
                  </span>
                </div>
                {procedure.error && (
                  <span className="tw-text-sm tw-text-red-500 tw-mt-1">
                    {procedure.error}
                  </span>
                )}
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CasesList;
