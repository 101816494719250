import { Lawyer, Role, SpouseRole, UserGender } from 'types/resources';

export const TemplatesVariables = {
  'chère/cher': '{Dear}',
  genre: '{Gender}',
  'prénom époux': '{customer_first_name}',
  'nom époux': '{customer_last_name}',
  'nom complet époux': '{customer_full_name}',
  maître: '{Counselor}',
  'prénom avocat': '{lawyer_first_name}',
  'nom avocat': '{lawyer_last_name}',
  'nom complet avocat': '{lawyer_full_name}',
};

export const replaceMessageVariables = (
  message: string,
  recipients: {
    full_name?: string;
    first_name?: string;
    last_name?: string;
    gender?: UserGender;
    role: Role;
    type?: SpouseRole | null | undefined;
    lawyer?: Lawyer;
  }[],
): string => {
  if (!recipients || recipients.length === 0 || !message) {
    return message;
  }

  let result = message;

  recipients.forEach((recipient) => {
    const conselorText = 'maître';

    if (recipient.gender) {
      let genreText = '';
      let dearText = '';

      if (recipient.gender === 'M') {
        genreText = 'monsieur';
        dearText = 'cher';
      } else if (recipient.gender === 'F') {
        genreText = 'madame';
        dearText = 'chère';
      }

      result = result.replace(/{gender}/g, genreText);
      result = result.replace(
        /{Gender}/g,
        genreText.charAt(0).toUpperCase() + genreText.slice(1),
      );

      result = result.replace(/{dear}/g, dearText);
      result = result.replace(
        /{Dear}/g,
        dearText.charAt(0).toUpperCase() + dearText.slice(1),
      );
    }

    result = result.replace(/{counselor}/g, conselorText);
    result = result.replace(
      /{Counselor}/g,
      conselorText.charAt(0).toUpperCase() + conselorText.slice(1),
    );

    result = result.replace(
      /{customer_first_name}/g,
      recipient.first_name || '',
    );
    result = result.replace(/{customer_last_name}/g, recipient.last_name || '');
    result = result.replace(/{customer_full_name}/g, recipient.full_name || '');

    result = result.replace(
      /{lawyer_first_name}/g,
      recipient.lawyer?.first_name || '',
    );
    result = result.replace(
      /{lawyer_last_name}/g,
      recipient.lawyer?.last_name || '',
    );
    result = result.replace(
      /{lawyer_full_name}/g,
      recipient.lawyer?.full_name || '',
    );
  });

  return result;
};
