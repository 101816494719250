import { FC, useCallback, useEffect, useState } from 'react';

// Components
import { Container } from 'components/Layout';

// Containers
import { DocumentsList } from 'container/client';
import { ViewGate } from 'container/common';

// Data
import { useCustomer } from '../../hooks/useCustomer';
import {
  ProcedureFull,
  ProcedureStepStatus,
  ProcedureStepType,
} from '../../types/resources';
import { useApi } from '../../hooks/useApi';
import { useNavigate } from 'react-router-dom';
import { ClientAppRoutes } from '../../ClientApp';
import { Modal, ModalHeader } from 'components/Modal';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/Buttons';
import { useLocalStorage } from 'react-use';

const ClientDocuments: FC = () => {
  const { customer } = useCustomer();
  const { t } = useTranslation();
  const [warningModalValue, setWarningModalValue] =
    useLocalStorage('document-warning');
  const [displayWarningModal, setDisplayWarningModal] = useState(
    !warningModalValue,
  );

  const navigate = useNavigate();
  const { execute: getProcedure, state: getProcedureState } =
    useApi<ProcedureFull>('');
  const procedure = getProcedureState.data?.value;

  const getProcedureCb = useCallback(() => {
    if (customer?.procedure_id)
      getProcedure({
        endpoint: `/procedures/${customer.procedure_id}`,
        query: { customer_id: customer.id },
      });
  }, [customer]);

  useEffect(() => {
    getProcedureCb();
  }, [customer?.procedure_id]);

  if (!procedure) return null;

  if (
    procedure.current_step.step === ProcedureStepType.DOCUMENT &&
    procedure.current_step.status === ProcedureStepStatus.LOCKED
  ) {
    navigate(ClientAppRoutes.HOME);
  }

  const handleCloseModal = () => {
    setWarningModalValue(true);
    setDisplayWarningModal(false);
  };

  return (
    <ViewGate procedure={procedure} steps={[ProcedureStepType.DOCUMENT]}>
      <Container>
        <DocumentsList
          procedure={procedure}
          refetchProcedure={getProcedureCb}
        />
        <Modal
          opened={displayWarningModal}
          onClose={handleCloseModal}
          width="720px"
        >
          <ModalHeader
            iconName="Danger"
            content={t('upload.warning_modal.title')}
          />
          <p className="tw-whitespace-pre-line">
            {t('upload.warning_modal.description')}
          </p>
          <div>
            <Button
              onClick={handleCloseModal}
              content={t('upload.warning_modal.close')}
              primary
              className="ml-auto"
            />
          </div>
        </Modal>
      </Container>
    </ViewGate>
  );
};

export default ClientDocuments;
