import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Controller, useForm } from 'react-hook-form';
import { InputText } from 'components/FormTemplate/Fields/InputText';
import styled from 'styled-components';
import { theme } from 'theme';
import { Flex, Text } from 'components/Layout';
import { Button } from 'components/Buttons';
import { useApi } from '../../../hooks/useApi';
import { regexes } from '../../../utils/regex';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { InputTel } from '../../../components/FormTemplate/Fields/InputTel';
import InputCheckbox from '../../../components/FormTemplate/Fields/InputCheckbox';
import { CustomerFull, UserGender } from '../../../types/resources';
import InputSelect from '../../../components/FormTemplate/Fields/InputSelect';
import toast from 'react-hot-toast';

const FullWidth = styled.div`
  grid-column: 1/-1;
`;

type UpdateCustomerInput = {
  email?: string;
  first_name?: string;
  last_name?: string;
  department_code?: string | null;
  phone?: string;
  gender: string | undefined;
  accept_marketing_contact?: boolean;
  address?: UpdateCustomerAddressInput;
};

type UpdateCustomerAddressInput = {
  street?: string;
  city?: string;
  post_code?: string;
  country?: string;
  additional_address?: string;
};

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 38px 32px;
  width: 928px;
  margin-top: ${theme.spacing.space8};
  margin-bottom: ${theme.spacing.space24};
`;

type CustomerUpdateFormProps = {
  customer: CustomerFull;
};

const CustomerUpdateForm: FC<CustomerUpdateFormProps> = ({ customer }) => {
  const {
    control,
    setValue,
    register,
    formState: { errors },
    clearErrors,
    handleSubmit,
  } = useForm<UpdateCustomerInput>({
    defaultValues: {
      ...customer,
      gender: customer.gender ?? undefined,
      address: {
        street: customer.address?.street,
        city: customer.address?.city,
        post_code: customer.address?.post_code,
        country: customer.address?.country ?? 'France',
        additional_address: customer.address?.additional_address,
      },
    },
  });

  const { t } = useTranslation();
  const [address, setAddress] = useState<boolean>(Boolean(customer.address));
  const {
    execute: updateCustomer,
    state: { loading },
  } = useApi(`/customers/${customer.id}`, { method: 'PATCH' });

  const genderOptions = Object.values(UserGender).map((value) => ({
    value: value,
    label: t(`gender.${value}`),
  }));

  const onSubmit = async (data: UpdateCustomerInput) => {
    if (data) {
      updateCustomer({
        body: {
          first_name: data.first_name,
          last_name: data.last_name,
          email: data.email,
          phone: data.phone,
          gender: data.gender,
          accept_marketing_contact: data.accept_marketing_contact,
          address: address
            ? {
                street: data.address?.street,
                city: data.address?.city,
                post_code: data.address?.post_code,
                country: data.address?.country,
                additional_address: data.address?.additional_address,
              }
            : undefined,
        },
        onSuccess: () =>
          void toast.success(t('customer.details.update_success')),
        onError: () => toast.error(t('customer.details.update_error')),
      });
    }
  };

  return (
    <>
      <Flex marginTop={{ xs: 'space48' }}>
        <Text content={t('prospect.information')} fontStyle="heading3" />
      </Flex>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputText
          {...register('email', {
            required: t('error.form.required').toString(),
            pattern: {
              value: regexes.email,
              message: t('error.form.email'),
            },
          })}
          error={errors.email}
          label={t('customer.email')}
          placeholder={t('customer.email')}
          width="100%"
        />
        <Controller
          control={control}
          name="phone"
          rules={{
            required: t('error.form.required').toString(),
            validate: (value: string | undefined) => {
              if (value && !isValidPhoneNumber(value)) {
                return t('error.form.phone').toString();
              }
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <InputTel
              label={t('customer.phone')}
              placeholder={t('customer.phone')}
              error={error}
              {...field}
              onChange={(value) => {
                setValue(`phone`, value);
                clearErrors('phone');
              }}
            />
          )}
        />

        <InputText
          {...register('first_name', {
            required: t('error.form.required').toString(),
          })}
          error={errors.first_name}
          label={t('customer.first_name')}
          placeholder={t('customer.first_name')}
          width="100%"
          uppercase
        />
        <InputText
          {...register('last_name', {
            required: t('error.form.required').toString(),
          })}
          error={errors.last_name}
          label={t('customer.last_name')}
          placeholder={t('customer.last_name')}
          width="100%"
          uppercase
        />

        <Controller
          control={control}
          name="gender"
          render={({ field, fieldState: { error } }) => (
            <InputSelect
              label={t('user.gender')}
              placeholder={t('user.gender')}
              options={genderOptions}
              error={error}
              {...field}
            />
          )}
        />

        <Flex></Flex>

        <FullWidth>
          <InputCheckbox
            onChange={(value) => {
              setAddress(value);
            }}
            fontWeight="bold"
            align="center"
            checkPoint
            checked={address}
            label={t(`customer.address.address`)}
          />
        </FullWidth>

        {address && (
          <>
            <InputText
              uppercase
              {...register('address.street', {
                required: address
                  ? t('error.form.required').toString()
                  : undefined,
                maxLength: {
                  value: 38,
                  message: t('error.form.max_length', { limit: 38 }),
                },
              })}
              error={errors.address?.street}
              label={t('customer.address.street')}
              placeholder={t('customer.address.street')}
              width="100%"
              required={address}
            />
            <InputText
              uppercase
              {...register('address.additional_address', {
                maxLength: {
                  value: 38,
                  message: t('error.form.max_length', { limit: 38 }),
                },
              })}
              error={errors.address?.additional_address}
              label={t('customer.address.additional_address')}
              placeholder={t('customer.address.additional_address')}
              width="100%"
            />

            <InputText
              uppercase
              {...register('address.city', {
                required: address
                  ? t('error.form.required').toString()
                  : undefined,
              })}
              error={errors.address?.city}
              label={t('customer.address.city')}
              placeholder={t('customer.address.city')}
              width="100%"
              required={address}
            />

            <InputText
              {...register('address.post_code', {
                required: address
                  ? t('error.form.required').toString()
                  : undefined,
              })}
              error={errors.address?.post_code}
              label={t('customer.address.post_code')}
              placeholder={t('customer.address.post_code')}
              width="100%"
              required={address}
            />

            <InputText
              {...register('address.country', {
                required: address
                  ? t('error.form.required').toString()
                  : undefined,
              })}
              error={errors.address?.country}
              label={t('customer.address.country')}
              placeholder={t('customer.address.country')}
              width="100%"
              required={address}
            />
          </>
        )}

        <Controller
          control={control}
          name="accept_marketing_contact"
          rules={{
            validate: (value) => {
              if (value === undefined) {
                return t('error.form.checkbox').toString();
              }
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <InputCheckbox
              {...field}
              checked={!!field.value}
              onChange={(value) => {
                setValue(`accept_marketing_contact`, !!value);
              }}
              fontWeight="bold"
              align="center"
              checkPoint
              error={!!error}
              label={t(`customer.accept_marketing_contact`)}
            />
          )}
        />

        <FullWidth>
          <Flex>
            <Button
              content={t('customer.details.submit_update')}
              type="submit"
              $loading={loading}
              primary
            />
          </Flex>
        </FullWidth>
      </Form>
    </>
  );
};

export default CustomerUpdateForm;
