import { FC, useState } from 'react';
import { Flex, Text } from 'components/Layout';

import { ClientAppRoutes } from 'ClientApp';
import { IconButton } from 'components/Buttons';
import { IconProps } from 'components/Images/Icon';
import { PickKey } from 'types/typescript';
import styled from 'styled-components';
import { theme } from 'theme';
import { useNavigate } from 'react-router-dom';
import {
  Procedure,
  ProcedureStep,
  ProcedureStepStatus,
  ProcedureStepType,
} from '../../../../types/resources';
import { env } from '../../../../config/env';

interface NotificationButtonProps {
  procedure: Procedure;
  isMobile?: boolean;
}

interface NotificationElement {
  onClick?: () => void;
  title?: string;
  label?: string;
  icon?: IconProps['name'];
  displayOnMobile?: boolean;
}

type AccessorNotificationElement<T extends unknown> = Record<
  ProcedureStepType,
  Partial<
    Record<
      | ProcedureStepStatus.IN_PROGRESS
      | ProcedureStepStatus.IN_REVIEW
      | ProcedureStepStatus.LOCKED,
      T | undefined
    >
  >
>;

type CustomStepStatus = PickKey<
  typeof ProcedureStepStatus,
  'IN_PROGRESS' | 'IN_REVIEW'
>;

const Button = styled.button`
  display: flex;
  align-items: center;
  border: 1px solid ${theme.colors.gray3};
  border-radius: ${theme.spacing.space16};
  background-color: ${theme.colors.beige};
  padding: 12px;
  cursor: pointer;
`;

const NotificationButton: FC<NotificationButtonProps> = ({
  procedure,
  isMobile,
}) => {
  const [hoverState, setHoverState] = useState(false);
  const navigate = useNavigate();

  const handleClickContact = () => {
    navigate(ClientAppRoutes.MESSAGING);
  };

  const legalReflexionDelay = Number(env.procedure.legalReflectionPeriod);

  const getNotification = (currentStep: ProcedureStep): NotificationElement => {
    const icon: AccessorNotificationElement<IconProps['name']> = {
      FORM: {
        IN_PROGRESS: 'ArrowRight',
        IN_REVIEW: 'ArrowRight',
      },
      DOCUMENT: {
        IN_PROGRESS: 'ArrowRight',
        IN_REVIEW: 'TimeCircle',
        LOCKED: 'Lock',
      },
      VERIFICATION: {
        IN_PROGRESS: 'TimeCircle',
        IN_REVIEW: 'TimeCircle',
      },
      WRITING_AGREEMENT: {
        IN_PROGRESS: 'TimeCircle',
        IN_REVIEW: 'TimeCircle',
      },
      REVIEW_AGREEMENT: {
        LOCKED: 'TimeCircle',
        IN_PROGRESS: 'ArrowRight',
        IN_REVIEW: 'TimeCircle',
      },
      AGREEMENT_SENT: {
        IN_PROGRESS: 'TimeCircle',
        IN_REVIEW: 'TimeCircle',
      },
      SIGNATURE: {
        IN_PROGRESS: 'ArrowRight',
        IN_REVIEW: 'TimeCircle',
      },
      ARCHIVE_SIGN: {
        IN_PROGRESS: 'TimeCircle',
        IN_REVIEW: 'TimeCircle',
      },
      NOTARY: {
        IN_PROGRESS: 'ArrowRight',
        IN_REVIEW: 'ArrowRight',
      },
      MARITAL_STATUS: {
        IN_PROGRESS: 'ArrowRight',
        IN_REVIEW: 'ArrowRight',
      },
      FINAL_SENDING: {
        IN_PROGRESS: 'ArrowRight',
        IN_REVIEW: 'ArrowRight',
      },
      CLOSED: {},
    };

    const title: AccessorNotificationElement<string> = {
      FORM: {
        IN_PROGRESS: 'Veuillez remplir et valider vos formulaires',
        IN_REVIEW:
          "Vos formulaires sont en attente de validation de l'un des conjoints.",
      },
      DOCUMENT: {
        IN_PROGRESS: 'Veuillez téléverser et valider vos documents',
        IN_REVIEW:
          "Vos documents sont en cours de vérification par votre gestionnaire. <br /> Les délais de traitement sont de 7 jours ouvrés en option EXPRESS  (sans l'option, délai indicatif de 3 semaines).",
        LOCKED:
          "Votre dossier contient des biens immobiliers, il va faire l’objet d’une analyse par vos avocats sous 72h ouvrées (hors samedi, dimanche et jours fériés) afin d’ajuster la demande documentaire.<br />L'étape “Documents” sera débloquée automatiquement dès que l’analyse sera terminée.",
      },
      VERIFICATION: {
        IN_PROGRESS:
          "Votre dossier est en cours de vérification par le contrôle qualité. <br /> Le délai de traitement est de 72 heures ouvrées en option EXPRESS (sans l'option, délai indicatif d'une semaine)",
        IN_REVIEW:
          "Votre dossier est en cours de vérification par le contrôle qualité. <br /> Le délai de traitement est de 72 heures ouvrées en option EXPRESS (sans l'option, délai indicatif d'une semaine)",
      },
      WRITING_AGREEMENT: {
        IN_PROGRESS:
          "Votre projet de convention de divorce est en cours de rédaction. <br /> Le délai de traitement moyen est de 10 jours ouvrés en option EXPRESS  (sans l'option, délai indicatif de 3 semaines)",
        IN_REVIEW:
          "Votre projet de convention de divorce est en cours de rédaction. <br /> Le délai de traitement moyen est de 10 jours ouvrés en option EXPRESS  (sans l'option, délai indicatif de 3 semaines)",
      },
      REVIEW_AGREEMENT: {
        LOCKED:
          "Votre convention de divorce est en cours de modification. <br /> Le délai de traitement est de 72 heures ouvrées en option EXPRESS (sans l'option, délai indicatif d'une semaine)",
        IN_PROGRESS:
          'Veuillez lire, vérifier et valider votre convention de divorce',
        IN_REVIEW:
          "Votre convention de divorce est en cours de validation par vos avocats. <br /> Le délai de traitement est de 48 heures ouvrées en option EXPRESS (sans l'option, délai indicatif d'une semaine)",
      },
      AGREEMENT_SENT: {
        IN_PROGRESS: 'Vos recommandés sont en cours d’envoi',
        IN_REVIEW:
          "Vos recommandés ont été envoyés et nous sommes en attente de l'accusé de reception",
      },
      SIGNATURE: {
        IN_PROGRESS: `Votre délai de réflexion de ${legalReflexionDelay} jours est en cours, vous pouvez cependant prendre votre rendez-vous de signature finale`,
        IN_REVIEW:
          'Vous êtes actuellement en attente de votre rendez-vous de signature de votre convention de divorce',
      },
      ARCHIVE_SIGN: {
        IN_PROGRESS: 'Votre rendez-vous est programmé.',
        IN_REVIEW: 'Votre rendez-vous est programmé.',
      },
      NOTARY: {
        IN_PROGRESS:
          "La demande d'enregistrement notarié va être envoyée dans les prochains jours",
        IN_REVIEW:
          'Le notaire procède à l’enregistrement de votre divorce au rang de ses minutes (délai légal 3 semaines)',
      },
      MARITAL_STATUS: {
        IN_PROGRESS:
          'Votre avocat s’occupe de la transcription du divorce auprès de l’état civil (France uniquement)',
        IN_REVIEW:
          'Votre avocat s’occupe de la transcription du divorce auprès de l’état civil (France uniquement)',
      },
      FINAL_SENDING: {
        IN_PROGRESS: 'Votre dossier complet va vous être envoyé par courrier',
        IN_REVIEW:
          'Votre dossier complet vous a été adressé par courrier. Nous vous souhaitons une bonne continuation.',
      },
      CLOSED: {},
    };

    const label: AccessorNotificationElement<string> = {
      FORM: {
        IN_PROGRESS: 'Accéder aux formulaires',
        IN_REVIEW: 'Accéder aux formulaires',
      },
      DOCUMENT: {
        IN_PROGRESS: 'Accéder aux documents',
      },
      VERIFICATION: {
        IN_PROGRESS: 'Contacter mon conseiller',
        IN_REVIEW: 'Contacter mon conseiller',
      },
      WRITING_AGREEMENT: {
        IN_PROGRESS: 'Contacter mon conseiller',
        IN_REVIEW: 'Contacter mon conseiller',
      },
      REVIEW_AGREEMENT: {
        LOCKED: 'Accéder à la convention',
        IN_PROGRESS: 'Accéder à la convention',
        IN_REVIEW: 'Accéder à la convention',
      },
      AGREEMENT_SENT: {
        IN_PROGRESS: 'Accéder à mon recommandé',
        IN_REVIEW: 'Accéder à mon recommandé',
      },
      SIGNATURE: {
        IN_PROGRESS: 'Prendre rendez-vous',
        IN_REVIEW: 'Accéder au rendez-vous',
      },
      ARCHIVE_SIGN: {
        IN_PROGRESS: 'Contacter mon conseiller',
        IN_REVIEW: 'Contacter mon conseiller',
      },
      NOTARY: {
        IN_PROGRESS: 'Contacter mon conseiller',
        IN_REVIEW: 'Accéder',
      },
      MARITAL_STATUS: {
        IN_PROGRESS: 'Contacter mon conseiller',
        IN_REVIEW: 'Accéder',
      },
      FINAL_SENDING: {
        IN_PROGRESS: "Suivre l'envoi",
        IN_REVIEW: "Suivre l'envoi",
      },
      CLOSED: {
        IN_PROGRESS: 'Contacter mon conseiller',
      },
    };

    const onClick: AccessorNotificationElement<() => void> = {
      FORM: {
        IN_PROGRESS: () => navigate(ClientAppRoutes.FORMS),
        IN_REVIEW: () => navigate(ClientAppRoutes.FORMS),
      },
      DOCUMENT: {
        IN_PROGRESS: () => navigate(ClientAppRoutes.DOCUMENTS),
      },
      VERIFICATION: {
        IN_PROGRESS: () => handleClickContact(),
        IN_REVIEW: () => handleClickContact(),
      },
      WRITING_AGREEMENT: {
        IN_PROGRESS: () => handleClickContact(),
        IN_REVIEW: () => handleClickContact(),
      },
      REVIEW_AGREEMENT: {
        LOCKED: () => navigate(ClientAppRoutes.CONVENTION),
        IN_PROGRESS: () => navigate(ClientAppRoutes.CONVENTION),
        IN_REVIEW: () => navigate(ClientAppRoutes.CONVENTION),
      },
      AGREEMENT_SENT: {
        IN_PROGRESS: () => navigate(ClientAppRoutes.REGISTERED_LETTERS),
        IN_REVIEW: () => navigate(ClientAppRoutes.REGISTERED_LETTERS),
      },
      SIGNATURE: {
        IN_PROGRESS: () => navigate(ClientAppRoutes.APPOINTMENT),
        IN_REVIEW: () => navigate(ClientAppRoutes.APPOINTMENT),
      },
      ARCHIVE_SIGN: {
        IN_PROGRESS: () => handleClickContact(),
        IN_REVIEW: () => handleClickContact(),
      },
      NOTARY: {
        IN_PROGRESS: () => handleClickContact(),
        IN_REVIEW: () => navigate(ClientAppRoutes.FORMALITY_NOTARY),
      },
      MARITAL_STATUS: {
        IN_PROGRESS: () => handleClickContact(),
        IN_REVIEW: () => navigate(ClientAppRoutes.FORMALITY_MARITAL_STATUS),
      },
      FINAL_SENDING: {
        IN_PROGRESS: () => navigate(ClientAppRoutes.FORMALITY_FINAL_SENDING),
        IN_REVIEW: () => navigate(ClientAppRoutes.FORMALITY_FINAL_SENDING),
      },
      CLOSED: {
        IN_PROGRESS: () => {
          navigate(ClientAppRoutes.ADMINISTRATIVE_DOCUMENTS);
        },
      },
    };

    const displayOnMobile: Record<ProcedureStepType, boolean> = {
      FORM: true,
      DOCUMENT: true,
      VERIFICATION: true,
      WRITING_AGREEMENT: true,
      REVIEW_AGREEMENT: false,
      AGREEMENT_SENT: false,
      SIGNATURE: false,
      ARCHIVE_SIGN: false,
      NOTARY: false,
      MARITAL_STATUS: false,
      FINAL_SENDING: false,
      CLOSED: false,
    };

    return {
      onClick: currentStep
        ? onClick[currentStep.step][currentStep.status as CustomStepStatus]
        : () => {},
      title: currentStep
        ? title[currentStep.step][currentStep.status as CustomStepStatus]
        : '',
      label: currentStep
        ? label[currentStep.step][currentStep.status as CustomStepStatus]
        : '',
      icon: currentStep
        ? icon[currentStep.step][currentStep.status as CustomStepStatus]
        : undefined,
      displayOnMobile: currentStep
        ? displayOnMobile[currentStep.step]
        : undefined,
    };
  };

  if (!procedure.current_step) return null;

  const { label, title, onClick, icon, displayOnMobile } = getNotification(
    procedure.current_step,
  );

  if (title && icon) {
    return (
      <Flex marginRight={{ xs: 'space16' }} className="tw-w-full">
        <Button
          onClick={onClick}
          onMouseEnter={() => setHoverState(true)}
          onMouseLeave={() => setHoverState(false)}
        >
          <div className="tw-flex tw-flex-col sm:tw-flex-row tw-items-center">
            <IconButton
              color={
                hoverState && onClick ? theme.colors.gray6 : theme.colors.black
              }
              backgroundColor="transparent"
              iconName={icon}
              className="!tw-hidden sm:!tw-block"
            />
            <Text
              fontStyle="body2"
              dangerouslySetInnerHTML={{ __html: title }}
              weight="medium"
              color={
                hoverState && onClick ? theme.colors.gray6 : theme.colors.black
              }
            />
            {label &&
              onClick &&
              (!isMobile || (isMobile && displayOnMobile)) && (
                <Flex
                  marginLeft={{ xs: 'space16' }}
                  alignItems="center"
                  className="tw-mt-4 sm:tw-mt-0"
                >
                  <IconButton
                    iconName="ArrowRight"
                    color={theme.colors.green1}
                    backgroundColor={theme.colors.green2}
                    rounded
                    size="small"
                  />
                  <Text
                    fontStyle="body3"
                    content={label}
                    weight="bold"
                    whiteSpace="nowrap"
                    color={
                      hoverState ? theme.colors.green2 : theme.colors.green1
                    }
                    transform="uppercase"
                    marginLeft={{ xs: 'space16' }}
                  />
                </Flex>
              )}
          </div>
        </Button>
      </Flex>
    );
  }
  return null;
};

export default NotificationButton;
