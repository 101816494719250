import {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';

interface SearchParamsContextType<T> {
  filters: T;
  updateFilters: (newFilters: Partial<T>) => void;
  removeFilter: (key: string) => void;
  getFilter: (key: string) => string | string[] | boolean | null;
  toggleFilter: (key: string, value: string) => void;
}

interface SearchParamsProviderProps<T> {
  children: ReactNode;
  arrayKeys?: string[];
}

const SearchParamsContext = createContext<
  SearchParamsContextType<any> | undefined
>(undefined);

export function SearchParamsProvider<T>({
  children,
  arrayKeys,
}: SearchParamsProviderProps<T>) {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const getFiltersFromUrl = (): T => {
    const filters = {} as T;

    for (const key of Array.from(searchParams.keys())) {
      if (arrayKeys?.includes(key)) {
        const values = searchParams.getAll(key);
        if (values.length > 0) {
          (filters as any)[key] = values;
        }
      } else {
        const value = searchParams.get(key);
        if (value !== '') {
          if (value === 'true') (filters as any)[key] = true;
          else if (value === 'false') (filters as any)[key] = false;
          else if (value === 'null') (filters as any)[key] = null;
          else (filters as any)[key] = value;
        }
      }
    }

    return filters;
  };

  const [filters, setFilters] = useState<T>(getFiltersFromUrl());

  useEffect(() => {
    setFilters(getFiltersFromUrl());
  }, [location.search]);

  const updateFilters = (newFilters: Partial<T>) => {
    const currentUrlParams = new URLSearchParams(window.location.search);

    Object.entries(newFilters).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== '') {
        currentUrlParams.set(key, String(value));
      } else {
        currentUrlParams.delete(key);
      }
    });

    const newUrl = `${location.pathname}${
      currentUrlParams.toString() ? `?${currentUrlParams.toString()}` : ''
    }`;
    window.history.replaceState(null, '', newUrl);

    setFilters((prev) => ({
      ...prev,
      ...newFilters,
    }));
  };

  const removeFilter = (key: string) => {
    const currentUrlParams = new URLSearchParams(window.location.search);

    currentUrlParams.delete(String(key));

    const newUrl = `${location.pathname}${
      currentUrlParams.toString() ? `?${currentUrlParams.toString()}` : ''
    }`;
    window.history.replaceState(null, '', newUrl);

    setFilters((prev) => {
      const newFilters = { ...prev };
      delete (newFilters as any)[key];
      return newFilters;
    });
  };

  const getFilter = (key: string) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const value = arrayKeys?.includes(key)
      ? urlSearchParams.getAll(String(key))
      : urlSearchParams.get(String(key));

    if (value === null || value === '') return null;
    if (value === 'true') return true;
    if (value === 'false') return false;
    return value;
  };

  const toggleFilter = (key: string, value?: string) => {
    const currentUrlParams = new URLSearchParams(window.location.search);

    if (arrayKeys?.includes(key) && value !== undefined) {
      const values = currentUrlParams.getAll(key);

      if (values.includes(String(value))) {
        currentUrlParams.delete(key);
        values
          .filter((v) => v !== String(value))
          .forEach((v) => {
            currentUrlParams.append(key, v);
          });
      } else {
        currentUrlParams.append(key, String(value));
      }
    } else {
      const currentValue = currentUrlParams.get(key);

      if (currentValue !== null) {
        currentUrlParams.delete(key);
      } else if (value !== undefined) {
        currentUrlParams.set(key, String(value));
      } else {
        currentUrlParams.set(key, 'true');
      }
    }

    const newUrl = `${location.pathname}${
      currentUrlParams.toString() ? `?${currentUrlParams.toString()}` : ''
    }`;
    window.history.replaceState(null, '', newUrl);

    setFilters(getFiltersFromUrl());
  };

  return (
    <SearchParamsContext.Provider
      value={{ filters, updateFilters, removeFilter, getFilter, toggleFilter }}
    >
      {children}
    </SearchParamsContext.Provider>
  );
}

export function useSearchParamsContext<T>() {
  const context = useContext(SearchParamsContext);
  if (context === undefined) {
    throw new Error(
      'useSearchParamsContext must be used within a SearchParamsProvider',
    );
  }
  return context as SearchParamsContextType<T>;
}
