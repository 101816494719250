import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

// Components
import { SideMenu } from 'components/Layout/Header/SideMenu';
import { IconProps } from 'components/Images/Icon';
import { DesktopOnly, MobileOnly } from 'components/Layout';

// Utils
import { getInitialName } from 'utils/getter';

// Types
import { AdminAppRoutes } from 'AdminApp';
import { Header } from 'container/common/Header/Header';
import { Role } from '../../../types/resources';
import { useAuth } from '../../../hooks/useAuth';

export interface AdminMenuSubLinkProps {
  icon: IconProps['name'];
  name: string;
  goTo: (link: string) => void;
  url: string;
  roles: Role[];
}

export interface AdminMenuLinkProps {
  icon: IconProps['name'];
  name: string;
  goTo?: (link: string) => void;
  url?: string;
  roles: Role[];
  subLinks?: AdminMenuSubLinkProps[];
}

export interface UserProfileProps {
  initials: string;
  goTo: (link: string) => void;
  url: string;
}

const AdminMenuContainer: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const initials = getInitialName(`${user?.first_name} ${user?.last_name}`);

  const location = useLocation();

  if (location.pathname === AdminAppRoutes.PARTNER_PREVIEW) {
    return null;
  }

  const handleGoTo = (link: string) => {
    navigate(link);
  };

  const userProfile = {
    initials,
    goTo: handleGoTo,
    url: AdminAppRoutes.ACCOUNT,
  };

  const menuLinks: AdminMenuLinkProps[] =
    user?.role === Role.ADMIN
      ? [
          {
            icon: 'Home',
            name: t('home'),
            goTo: handleGoTo,
            url: AdminAppRoutes.HOME,
            roles: [Role.ADMIN, Role.MANAGER],
          },
          {
            icon: 'Document',
            name: 'Gestion',
            roles: [Role.ADMIN, Role.MANAGER, Role.LAWYER],
            subLinks: [
              {
                icon: 'Graph',
                name: 'Suivi activité',
                goTo: handleGoTo,
                url: AdminAppRoutes.ACTIVITY,
                roles: [Role.ADMIN],
              },
              {
                icon: 'Folder',
                name: t('opportunity.opportunities'),
                goTo: handleGoTo,
                url: AdminAppRoutes.CASES,
                roles: [Role.ADMIN, Role.MANAGER, Role.LAWYER],
              },
              {
                icon: 'People',
                name: t('customer.customers'),
                goTo: handleGoTo,
                url: AdminAppRoutes.CUSTOMERS,
                roles: [Role.ADMIN, Role.MANAGER, Role.SALES],
              },
              {
                icon: 'Document',
                name: t('message_template.title'),
                goTo: handleGoTo,
                url: AdminAppRoutes.MESSAGE_TEMPLATES,
                roles: [Role.ADMIN],
              },
            ],
          },
          {
            icon: 'headphones',
            name: 'Commerce',
            roles: [Role.ADMIN, Role.MANAGER, Role.SALES],
            subLinks: [
              {
                icon: 'Paper',
                name: t('estimate.estimates'),
                goTo: handleGoTo,
                url: AdminAppRoutes.ESTIMATE,
                roles: [Role.ADMIN, Role.SALES],
              },
              {
                icon: 'Call',
                name: t('user.prospects'),
                goTo: handleGoTo,
                url: AdminAppRoutes.PROSPECTS,
                roles: [Role.ADMIN, Role.SALES],
              },
              {
                icon: 'Wallet',
                name: t('payments.title'),
                goTo: handleGoTo,
                url: AdminAppRoutes.PAYMENTS,
                roles: [Role.ADMIN, Role.SALES],
              },
              {
                icon: 'Buy',
                name: t('products'),
                goTo: handleGoTo,
                url: AdminAppRoutes.PRODUCT,
                roles: [Role.ADMIN],
              },
            ],
          },
          {
            icon: 'Notification',
            name: t('notification.title'),
            goTo: handleGoTo,
            url: AdminAppRoutes.NOTIFICATIONS,
            roles: [Role.ADMIN],
          },
          {
            icon: 'Star',
            name: t('partners.title'),
            goTo: handleGoTo,
            url: AdminAppRoutes.PARTNERS,
            roles: [Role.ADMIN],
          },
          {
            icon: 'User',
            name: t('user.users'),
            goTo: handleGoTo,
            url: AdminAppRoutes.USERS,
            roles: [Role.ADMIN],
          },
        ]
      : [
          {
            icon: 'Home',
            name: t('home'),
            goTo: handleGoTo,
            url: AdminAppRoutes.HOME,
            roles: [Role.ADMIN, Role.MANAGER],
          },
          {
            icon: 'Folder',
            name: t('opportunity.opportunities'),
            goTo: handleGoTo,
            url: AdminAppRoutes.CASES,
            roles: [Role.ADMIN, Role.MANAGER, Role.LAWYER],
          },
          {
            icon: 'User',
            name: t('user.users'),
            goTo: handleGoTo,
            url: AdminAppRoutes.USERS,
            roles: [Role.ADMIN],
          },
          {
            icon: 'People',
            name: t('customer.customers'),
            goTo: handleGoTo,
            url: AdminAppRoutes.CUSTOMERS,
            roles: [Role.ADMIN, Role.MANAGER, Role.SALES],
          },
          {
            icon: 'Paper',
            name: t('estimate.estimates'),
            goTo: handleGoTo,
            url: AdminAppRoutes.ESTIMATE,
            roles: [Role.ADMIN, Role.SALES],
          },
          {
            icon: 'Call',
            name: t('user.prospects'),
            goTo: handleGoTo,
            url: AdminAppRoutes.PROSPECTS,
            roles: [Role.ADMIN, Role.SALES],
          },
          {
            icon: 'Buy',
            name: t('products'),
            goTo: handleGoTo,
            url: AdminAppRoutes.PRODUCT,
            roles: [Role.ADMIN],
          },
          {
            icon: 'Wallet',
            name: t('payments.title'),
            goTo: handleGoTo,
            url: AdminAppRoutes.PAYMENTS,
            roles: [Role.ADMIN, Role.SALES],
          },
          {
            icon: 'Notification',
            name: t('notification.title'),
            goTo: handleGoTo,
            url: AdminAppRoutes.NOTIFICATIONS,
            roles: [Role.ADMIN],
          },
          {
            icon: 'Document',
            name: t('message_template.title'),
            goTo: handleGoTo,
            url: AdminAppRoutes.MESSAGE_TEMPLATES,
            roles: [Role.ADMIN],
          },
          {
            icon: 'Star',
            name: t('partners.title'),
            goTo: handleGoTo,
            url: AdminAppRoutes.PARTNERS,
            roles: [Role.ADMIN],
          },
          {
            icon: 'Home',
            name: 'Suivi activité',
            goTo: handleGoTo,
            url: AdminAppRoutes.ACTIVITY,
            roles: [Role.ADMIN],
          },
        ];

  return (
    <>
      <DesktopOnly>
        <SideMenu userProfile={userProfile} menuLinks={menuLinks} />
      </DesktopOnly>
      <MobileOnly>
        <Header admin />
      </MobileOnly>
    </>
  );
};

export default AdminMenuContainer;
