import { ArrowDown, ArrowUp, ChevronsUpDown } from 'lucide-react';
import cx from 'classnames';
import React from 'react';
import { Header } from '@tanstack/react-table';

interface TableColumnHeaderProps<TData, TValue>
  extends React.HTMLAttributes<HTMLDivElement> {
  header: Header<TData, unknown>;
}

const TableColumnHeader = <TData, TValue>({
  header,
  className,
}: TableColumnHeaderProps<TData, TValue>) => {
  if (!header.column.getCanSort()) {
    if (typeof header.column.columnDef.header === 'function') {
      return (
        <th className="tw-px-2 tw-py-8 tw-text-left tw-text-xs tw-font-bold tw-text-gray-700 tw-uppercase tw-tracking-wider">
          {header.column.columnDef.header(header.getContext())}
        </th>
      );
    }

    return (
      <th className="tw-px-2 tw-py-8 tw-text-left tw-text-xs tw-font-bold tw-text-gray-700 tw-uppercase tw-tracking-wider">
        {header.column.columnDef.header}
      </th>
    );
  }

  return (
    <th className={cx(className)}>
      <button
        type="button"
        className="tw-px-2 tw-py-8 tw-text-left tw-text-xs tw-font-bold tw-text-gray-700 tw-uppercase tw-tracking-wider tw-flex tw-flex-row tw-items-center "
        onClick={() => header.column.toggleSorting(undefined)}
      >
        <span>{header.column.columnDef.header}</span>
        {header.column.getIsSorted() === 'desc' ? (
          <ArrowDown className="tw-h-4 tw-w-4" />
        ) : header.column.getIsSorted() === 'asc' ? (
          <ArrowUp className="tw-h-4 tw-w-4" />
        ) : (
          <ChevronsUpDown className="tw-h-4 tw-w-4" />
        )}
      </button>
    </th>
  );
};

export default TableColumnHeader;
