import { FC, useState } from 'react';
import styled from 'styled-components';
import { theme } from 'theme';
import logo from 'assets/logo/logo-white.svg';

// Components
import { Flex } from 'components/Layout/Flex';
import { IconButton } from 'components/Buttons/IconButton';

// Types
import {
  AdminMenuLinkProps,
  AdminMenuSubLinkProps,
  UserProfileProps,
} from 'container/admin/menus/AdminMenu';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import MenuButton from 'components/Buttons/MenuButton';
import { Line } from '..';
import { Permission } from 'components/User';
import { useAuth } from '../../../hooks/useAuth';

const MenuContainer = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 0;
  inset: 0;
  align-items: flex-start;
  padding: 0 ${theme.spacing.space12} ${theme.spacing.space24}
    ${theme.spacing.space12};
  max-width: 186px;
  height: 100vh;
  background-color: ${theme.colors.green1};
  z-index: 6;
`;

const Image = styled.img``;

const CategoryContainer = styled.div`
  margin-top: ${theme.spacing.space24};
  width: 100%;
`;

export interface SideMenuProps {
  userProfile: UserProfileProps;
  menuLinks: AdminMenuLinkProps[];
}

export const SideMenu: FC<SideMenuProps> = ({ userProfile, menuLinks }) => {
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const [openCategories, setOpenCategories] = useState<{
    [key: string]: boolean;
  }>({});

  const { logout } = useAuth();
  const { t } = useTranslation();
  const location = useLocation();

  const toggleCategory = (categoryName: string) => {
    setOpenCategories((prev) => ({
      ...prev,
      [categoryName]: !prev[categoryName],
    }));
  };

  const isSubMenuActive = (subLink: AdminMenuSubLinkProps): boolean => {
    if (!subLink.url) return false;

    return subLink.url === '/'
      ? location.pathname === subLink.url
      : location.pathname.startsWith(subLink.url);
  };

  const isMenuActive = (link: AdminMenuLinkProps): boolean => {
    if (link.subLinks) {
      return link.subLinks.some((subLink) => isSubMenuActive(subLink));
    }

    if (!link.url) return false;

    return link.url === '/'
      ? location.pathname === link.url
      : location.pathname.startsWith(link.url);
  };

  return (
    <MenuContainer>
      <Flex direction={{ xs: 'column' }} justify="between" width="100%">
        <Flex
          marginBottom={{ xs: 'space24' }}
          marginTop={{ xs: 'space32' }}
          alignSelf={'center'}
        >
          <Image src={logo} alt="WeDivorce" />
        </Flex>
        {menuLinks &&
          menuLinks.map((link) => {
            const active = isMenuActive(link);
            const hasSubLinks = link.subLinks && link.subLinks.length > 0;
            const isCategoryOpen = openCategories[link.name] || false;

            return (
              <Permission key={link.name} roles={link.roles}>
                {hasSubLinks ? (
                  <CategoryContainer>
                    <MenuButton
                      content={link.name}
                      isCategory={true}
                      onClick={() => toggleCategory(link.name)}
                      iconName={link.icon}
                      isOpened={isCategoryOpen}
                    />

                    {isCategoryOpen && link.subLinks && (
                      <div className="tw-mt-2">
                        {link.subLinks.map((subLink) => (
                          <Permission key={subLink.name} roles={subLink.roles}>
                            <MenuButton
                              isSubMenuItem={true}
                              active={isSubMenuActive(subLink)}
                              onClick={() => subLink.goTo(subLink.url)}
                              content={subLink.name}
                              iconName={subLink.icon}
                            />
                          </Permission>
                        ))}
                      </div>
                    )}
                  </CategoryContainer>
                ) : (
                  <Flex marginTop={{ xs: 'space24' }}>
                    <MenuButton
                      onClick={() =>
                        link.goTo && link.url && link.goTo(link.url)
                      }
                      active={active}
                      content={link.name}
                      iconName={link.icon}
                    />
                  </Flex>
                )}
              </Permission>
            );
          })}
      </Flex>
      <Flex direction={{ xs: 'column' }} expand>
        <Line />
        <Flex
          alignSelf="start"
          marginTop={{ xs: 'space24' }}
          onClick={() => setOpenMenu(!openMenu)}
          style={{ cursor: 'pointer' }}
        >
          <IconButton
            letters={userProfile.initials}
            color={theme.colors.green1}
            backgroundColor={theme.colors.green2}
          />
          <IconButton
            iconName={openMenu ? 'ChevronUpCircle' : 'ChevronDownCircle'}
            color={theme.colors.white}
            backgroundColor={theme.colors.green1}
          />
        </Flex>
        {openMenu && (
          <>
            <Flex marginTop={{ xs: 'space32' }}>
              <MenuButton
                onClick={() => userProfile.goTo(userProfile.url)}
                iconName="User"
                content={t('user.my_profile')}
              />
            </Flex>
            <Flex marginTop={{ xs: 'space24' }}>
              <MenuButton
                onClick={() => logout()}
                iconName="Logout"
                content={t('sign_out')}
              />
            </Flex>
          </>
        )}
      </Flex>
    </MenuContainer>
  );
};
