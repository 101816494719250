import {
  AllFormsData,
  CustomFormProps,
  Form,
  FormComment,
} from 'wrapper/FormWrapper';
// types
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormValidation, UiSchema } from '@rjsf/core';
// uiSchema
import {
  UiSchemaAdultChildren,
  UiSchemaAgreementAdultChildren,
  UiSchemaAgreementYoungChildren,
  UiSchemaCompensatoryAllowance,
  UiSchemaGoodAndDebts,
  UiSchemaMaritalHome,
  UiSchemaProperties,
  UiSchemaSpouseFollower,
  UiSchemaSpouseLead,
  UiSchemaWedding,
  UiSchemaYoungChildren,
} from 'schemas/uiSchemaClient';
import { set } from 'lodash';
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { AdminAppRoutes } from 'AdminApp';
import { scrollToTop } from 'utils/layout';
import { useQueryParams } from 'hooks/useQueryParams';
import { useTranslation } from 'react-i18next';
import {
  CustomerFull,
  FormFull,
  FormStatus,
  FormTemplateId,
  ProcedureSpouseFull,
  Role,
} from '../../../types/resources';
import { useApi } from '../../../hooks/useApi';
import { useAuth } from '../../../hooks/useAuth';

export const uiSchema: Record<FormTemplateId, UiSchema[]> = {
  ADULT_CHILDREN: UiSchemaAdultChildren,
  YOUNG_CHILDREN: UiSchemaYoungChildren,
  AGREEMENT_ADULT_CHILDREN: UiSchemaAgreementAdultChildren,
  AGREEMENT_YOUNG_CHILDREN: UiSchemaAgreementYoungChildren,
  GOODS_AND_DEBTS: UiSchemaGoodAndDebts,
  COMPENSATORY_ALLOWANCE: UiSchemaCompensatoryAllowance,
  PROPERTIES: UiSchemaProperties,
  SPOUSE_LEAD: UiSchemaSpouseLead,
  SPOUSE_FOLLOWER: UiSchemaSpouseFollower,
  MARITAL_HOME: UiSchemaMaritalHome,
  WEDDING: UiSchemaWedding,
};

type CreateFormAnswerInput = {
  key: string;
  value: any;
  step: number;
};

const SingleForm: FC = () => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const location = useLocation();

  const { id: formId } = useParams<{ id: string }>();
  const { opportunityId: opportunityId } = useParams<{
    opportunityId: string;
  }>();
  const [formData, setFormData] = useState<AllFormsData>();
  const [comments, setComments] = useState<FormComment>();
  const query = useQueryParams();

  const { execute: createFormAnswers, state: createFormAnswersState } = useApi(
    `/procedures/${opportunityId}/forms/${formId}/answers`,
    { method: 'POST' },
  );
  const { execute: completeForm, state: completeFormState } = useApi(
    `/procedures/${opportunityId}/forms/${formId}/complete`,
    { method: 'POST' },
  );

  const navigate = useNavigate();

  const targetStep = query.get('step');

  const { execute: getForm, state: getFormState } = useApi<FormFull>(
    `/procedures/${opportunityId}/forms/${formId}`,
  );
  const form = getFormState.data?.value;

  const { execute: getSpouses, state: getSpousesState } = useApi<
    ProcedureSpouseFull[]
  >(`/procedures/${opportunityId}/spouses`);
  const spouses = getSpousesState.data?.value;

  const spouse1 = spouses?.find((s) => s.number === 1);
  const spouse2 = spouses?.find((s) => s.number === 2);

  const { execute: getCustomer1, state: getCustomer1State } =
    useApi<CustomerFull>(`/customers/${spouse1?.spouse_id}`);
  const customer1 = getCustomer1State.data?.value;

  const loading =
    getFormState.loading ||
    getSpousesState.loading ||
    getCustomer1State.loading;

  useEffect(() => {
    getSpouses();
    getForm();
  }, [opportunityId]);

  useEffect(() => {
    if (spouse1) {
      getCustomer1();
    }
  }, [spouse1]);

  const disabled = useMemo(
    () =>
      form?.status === FormStatus.IN_REVIEW ||
      form?.status === FormStatus.VALIDATED,
    [form],
  );

  const { schema, id } = form?.template || {};

  const formContextData = useMemo(() => {
    const formContextByFormType: Partial<
      Record<keyof typeof FormTemplateId, any>
    > = {
      SPOUSE_LEAD: {
        PARTIE1_NOM: spouse1?.spouse.last_name,
        PARTIE1_PRENOM: spouse1?.spouse.first_name,
        PARTIE1_ADRESSE: customer1?.address?.street,
        PARTIE1_CP: customer1?.address?.post_code,
        PARTIE1_VILLE: customer1?.address?.city,
      },
      SPOUSE_FOLLOWER: {
        PARTIE2_NOM: spouse2?.spouse.last_name,
        PARTIE2_PRENOM: spouse2?.spouse.first_name,
      },
    };

    if (id) {
      return {
        ...formContextByFormType[id],
        c1: spouse1?.spouse.full_name,
        c2: spouse2?.spouse.full_name,
      };
    }
  }, [id, spouse1, spouse2, customer1]) as CustomFormProps['formContextData'];

  const uiSchemaForm = uiSchema[id as keyof typeof uiSchema];

  useEffect(() => {
    if (form?.answers) {
      const formattedFormAnswers = form?.answers?.reduce(
        (acc, { key, value }) => set(acc, key, value),
        {},
      ) as AllFormsData;

      setFormData(formattedFormAnswers);

      const formattedComments = form?.answers?.reduce(
        (acc, { key, updated_by_id, step }) => {
          if (updated_by_id && updated_by_id !== user?.id) {
            set(acc, key, {
              id: key,
              label: t(`yoProcess.labels.${key}`),
              comment: `Le champ a été modifié`,
              step,
            });
          }
          return acc;
        },
        {},
      ) as FormComment;

      setComments(formattedComments);
    }
  }, [form]);

  const validate = (formData: AllFormsData, errors: FormValidation) => {
    return errors;
  };

  const handleSubmit = useCallback(
    (formData: AllFormsData, maxStep: boolean, step: number) => {
      return new Promise<void>((resolve) => {
        createFormAnswers({
          endpoint: `/procedures/${opportunityId}/forms/${formId}/answers/${step}`,
          body: Object.entries(formData).reduce(
            (acc: CreateFormAnswerInput[], [key, value]) => {
              if (value) acc.push({ key, value, step });
              return acc;
            },
            [],
          ),
          onSuccess: () => {
            resolve();

            if (maxStep) {
              if (!matchPath(AdminAppRoutes.CLIENT_FORM, location.pathname)) {
                completeForm({
                  onSuccess: () => {
                    navigate(-1);
                    scrollToTop();
                  },
                });
              } else {
                navigate(-1);
                scrollToTop();
              }
            }
          },
        });
      });
    },
    [createFormAnswers, formId],
  );

  if (!schema) return null;

  return (
    <Form
      schema={schema}
      uiSchema={uiSchemaForm}
      handleSubmit={handleSubmit}
      lastTextSubmit={disabled ? t('validate_and_close') : t('validate')}
      loadingSubmit={
        createFormAnswersState.loading || completeFormState.loading
      }
      loading={loading}
      validate={validate}
      data={formData}
      targetStep={targetStep ? parseInt(targetStep) : undefined}
      comments={comments}
      disabled={
        user && [Role.ADMIN, Role.MANAGER].includes(user?.role)
          ? false
          : disabled
      }
      formContextData={formContextData}
      submitAtStep
      redirectLastStepDisabled={() => navigate(-1)}
    />
  );
};

export default SingleForm;
