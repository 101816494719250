import { Button, IconButton } from 'components/Buttons';
import { Flex, Requires, Text } from 'components/Layout';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from 'theme';
import { Card } from 'components/Cards';

import NotesList from 'components/Notes/List/NotesList';
import styled from 'styled-components';
import NoteTextarea from 'components/Notes/NoteTextarea';
import { Icon } from 'components/Images';
import { CommentFull } from '../../types/resources';
import { PaginationMetadata } from '../../hooks/useFetch';
import { DeleteNoteProps, UpdateNoteProps } from 'components/Notes/NoteCard';

interface NotesCardProps {
  comments: CommentFull[];
  commentsPagination: PaginationMetadata;
  onCreate: (note: string) => Promise<void>;
  onUpdate: (variables: UpdateNoteProps) => Promise<void>;
  onDelete: (variables: DeleteNoteProps) => Promise<void>;
}

const StyledCard = styled(Card)`
  overflow: hidden;
`;

const OverflowStyledFlex = styled(Flex)`
  padding-left: 150px;
  max-height: 600px;
  overflow-y: auto;
`;

const MAX_NOTES = 3;

const NotesCard: FC<NotesCardProps> = ({
  comments,
  commentsPagination,
  onCreate,
  onUpdate,
  onDelete,
}) => {
  const { t } = useTranslation();
  const [seeMoreState, toggleSeeMore] = useState<boolean>(false);
  const [addNote, setAddNote] = useState<boolean>(false);
  const [seeValidate, setSeeValidate] = useState<boolean>(false);

  const totalCount = useMemo(() => commentsPagination.total || 0, [comments]);

  const notes = useMemo(() => {
    const filteredNotes = comments?.filter((note) => {
      return !(note.processed && !seeValidate);
    });

    return seeMoreState ? filteredNotes : filteredNotes?.slice(0, MAX_NOTES);
  }, [seeMoreState, seeValidate, comments]);

  const notesCount = useMemo(() => {
    const validCount = comments?.filter((note) => note.processed).length || 0;
    return {
      inProgress: totalCount - validCount,
      valid: validCount,
    };
  }, [comments]);

  return (
    <StyledCard>
      <Flex
        justify="between"
        width="100%"
        alignItems="start"
        direction={{ xs: 'row' }}
      >
        <Flex alignItems="center">
          <IconButton
            iconName="Chat"
            color={theme.colors.salmon1}
            backgroundColor={theme.colors.salmon3}
            rounded
            stroke="regular"
          />
          <Text
            content={t('opportunity.notes.title')}
            fontStyle="heading5"
            marginLeft={{ xs: 'space16' }}
            marginRight={{ xs: 'space16' }}
          />
        </Flex>

        <Flex direction={{ xs: 'column' }} alignItems="end">
          <Requires value={notesCount.inProgress > 0}>
            <Text
              fontStyle="body2"
              content={t('opportunity.notes.notes_count', {
                count: notesCount.inProgress,
              })}
              color={theme.colors.gray6}
              weight="medium"
              marginBottom={{ xs: 'space16' }}
            />
          </Requires>
          <Button
            content={t('opportunity.notes.button.add')}
            primary
            iconRight={{ name: 'Plus' }}
            onClick={() => setAddNote(true)}
            disabled={addNote}
          />
        </Flex>
      </Flex>

      <Requires value={totalCount > 0}>
        <OverflowStyledFlex
          direction={{ xs: 'column' }}
          marginBottom={{ xs: 'space24' }}
          marginTop={{ xs: 'space24' }}
        >
          <NotesList notes={notes} onUpdate={onUpdate} onDelete={onDelete} />
        </OverflowStyledFlex>
      </Requires>

      <Flex direction={{ xs: 'column' }}>
        <Requires value={totalCount > 0}>
          <Flex
            alignItems="center"
            justify={totalCount != notesCount.valid ? 'start' : 'center'}
          >
            <Requires value={totalCount != notesCount.valid}>
              <Button
                content={
                  seeMoreState
                    ? t('opportunity.notes.button.see_less')
                    : t('opportunity.notes.button.see_more')
                }
                iconLeft={{ name: seeMoreState ? 'Hide' : 'Show' }}
                marginLeft={{ xs: 'space150' }}
                onClick={() => toggleSeeMore(!seeMoreState)}
                disabled={notesCount.inProgress <= MAX_NOTES || seeValidate}
              />
            </Requires>

            <Flex
              onClick={() => {
                !seeMoreState && toggleSeeMore(true);
                setSeeValidate(!seeValidate);
              }}
              marginLeft={{ xs: 'space24' }}
              style={{ cursor: 'pointer' }}
              alignItems="center"
            >
              <Icon name="Swap" size="small" />
              <Text
                fontStyle="body2"
                marginLeft={{ xs: 'space8' }}
                content={
                  seeValidate
                    ? t('opportunity.notes.button.see_unvalidate', {
                        count: notesCount.valid,
                      })
                    : t('opportunity.notes.button.see_validate', {
                        count: notesCount.valid,
                      })
                }
                weight={notesCount.valid > 0 ? 'bold' : 'regular'}
                color={
                  notesCount.valid > 0 ? theme.colors.black : theme.colors.gray5
                }
              />
            </Flex>
          </Flex>
        </Requires>

        {addNote && (
          <NoteTextarea
            onCreate={onCreate}
            onCancel={() => setAddNote(false)}
          />
        )}
      </Flex>
    </StyledCard>
  );
};

export default NotesCard;
