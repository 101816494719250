import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Flex } from 'components/Layout/Flex';
import { InputText } from 'components/FormTemplate/Fields/InputText';

// Utils
import { theme } from 'theme';
import InputSelect from 'components/FormTemplate/Fields/InputSelect';
import { ProductType } from '../../../types/resources';
import { useSearchParamsContext } from '../../../context';
import { ProductsFilters } from '../../../pages/admin/Products';

const ProductsListFilter: FC = () => {
  const { t } = useTranslation();
  const {
    filters: currentFilters,
    updateFilters,
    removeFilter,
    getFilter,
  } = useSearchParamsContext<ProductsFilters>();

  const typeOptions = [
    {
      value: 'PRODUCT',
      label: t(`product.types.product`),
    },
    {
      value: 'OPTION',
      label: t(`product.types.option`),
    },
    {
      value: 'PLAN',
      label: t(`product.types.plan`),
    },
  ];

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    updateFilters({ search: e?.target.value });
  };

  const handleTypeChange = (val: ProductType) => {
    updateFilters({ type: val });
  };

  return (
    <Flex marginTop={{ xs: 'space40' }} justify="start">
      <Flex>
        <InputText
          onChange={handleSearchChange}
          label={t('filter.search')}
          labelUppercase={true}
          labelFontStyle="label"
          placeholder={t('filter.search_product')}
          width="288px"
          labelWeight="bold"
          $size="small"
          value={(getFilter('search') as string) || ''}
          icon={{ name: 'Search', primaryColor: theme.colors.salmon1 }}
          debounceDelay={500}
        />
      </Flex>
      <Flex marginLeft={{ xs: 'space32' }}>
        <InputSelect
          onChange={handleTypeChange}
          options={typeOptions}
          label={t('product.type')}
          labelWeight="bold"
          labelUppercase={true}
          placeholder={t('filter.search_by_type')}
          width="256px"
          size="small"
          isClearable
          value={(getFilter('type') as string) || ''}
        />
      </Flex>
    </Flex>
  );
};

export default ProductsListFilter;
